import { AxiosResponse } from 'axios';
import { IOffer } from 'models';
import api from '../http';

export default class OfferService {
    static async getOffers(): Promise<AxiosResponse<IOffer[]>> {
        return api.get(`/offer`);
    }

    static async turnOffOffer(id: string): Promise<AxiosResponse<void>> {
        return api.post(`/offer/${id}/deactivate`);
    }

    static async turnOnOffer(id: string): Promise<AxiosResponse<void>> {
        return api.post(`/offer/${id}/activate`);
    }

    static async offerPost(data: any): Promise<AxiosResponse<void>> {
        return api.post(`/offer`, data);
    }
}
