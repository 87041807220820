import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { IRoute } from 'models';
import { authStore } from 'store';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import navigation from './router';

import 'dayjs/locale/ru';
import './App.scss';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ru');

const App = observer(() => {
    const auth = localStorage.getItem('accessToken') && localStorage.getItem('refreshToken');
    const privateRoutes: IRoute[] = navigation.privateRoutes.filter((route) => {
        let access: boolean = false;
        route.roles.forEach(() => {
            access = true;
        });
        return access;
    });

    const initLocation = '/events/';

    useEffect(() => {
        authStore.setGlobalLoading(false);
    }, []);

    return auth ? (
        <Routes>
            {privateRoutes.map((route) => (
                <Route
                    key={route.key}
                    path={route.path}
                    element={<route.component navigation={privateRoutes} />}
                />
            ))}
            <Route path="*" element={<Navigate to={initLocation} />} />
        </Routes>
    ) : (
        <Routes>
            {navigation.publicRoutes.map((route) => (
                <Route key={route.key} path={route.path} element={<route.component />} />
            ))}
            <Route path="*" element={<Navigate to={navigation.publicRoutes[0].path} />} />
        </Routes>
    );
});

export default App;
