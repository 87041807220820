import React, { FC } from 'react';

import './text-button.scss';

interface Props {
    children: string | React.ReactNode;
    onClick?: Function;
    className?: string;
    disabled?: boolean;
}

const TextButton: FC<Props> = (props: Props) => {
    const { children, onClick, className, disabled } = props;
    const handler = () => onClick && onClick();

    return (
        <button
            type="button"
            className={`text-button ${className || ''}`}
            onClick={handler}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default TextButton;
