import { AxiosResponse } from 'axios';
import { IChangePassPost, IProfileDetails } from 'models';
import api from '../http';

export default class ProfileService {
    static async getProfileDetails(id: string): Promise<AxiosResponse<IProfileDetails>> {
        return api.get(`/profiles/${id}/details`);
    }

    static async postPassword(body: IChangePassPost): Promise<AxiosResponse<any>> {
        return api.post('/user/password-change', body);
    }
}
