import React, { FC, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { observer } from 'mobx-react';
import config from 'config';
import { IRoute } from 'models';
import { ErrorRequest, Layout } from 'components/layout';
import UserInfo from 'components/user/UserInfo/UserInfo';
import userStore from 'store/UserStore';
import { useParams } from 'react-router-dom';
import './user.scss';

interface Props {
    navigation: IRoute[];
}

const User: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const { id } = useParams();
    const { user, isLoadingUser, errorUser, successAdd } = userStore;
    const [isNewUser, setIsNewUser] = useState(false);

    useEffect(() => {
        if (successAdd && user) {
            userStore.setSuccessAdd(false);
            userStore.getUser(user?.id || '');
        }
    }, [successAdd]);

    useEffect(() => {
        userStore.getRoles();
    }, []);

    useEffect(() => {
        if (id && id !== 'new') {
            userStore.getUser(id);
        }
        if (id && id === 'new') {
            setIsNewUser(true);
        }
    }, []);

    useEffect(() => () => userStore.setUser(null), []);

    return (
        <Layout page="users" navigation={navigation}>
            {/* <Header
                title={id === 'new' ? 'Новый пользователь' : 'Карточка Пользователя'}
                loading={false}
                routes={[{ name: 'Пользователи', path: '/users' }]}
            /> */}
            <div className="page-user">
                <Scrollbars {...config.scrolls}>
                    {!errorUser && (
                        <UserInfo
                            title="Информация о пользователе"
                            data={user}
                            loading={isLoadingUser}
                            isNew={isNewUser}
                        />
                    )}
                    {!user && errorUser && id && (
                        <ErrorRequest onClick={() => userStore.getUser(id)} />
                    )}
                </Scrollbars>
            </div>
        </Layout>
    );
});

export default User;
