import React, { ReactNode } from 'react';
import Skeleton from '../Skeleton/Skeleton';

import './inner-block.scss';

interface Props {
    children?: any;
    title?: string;
    description?: string | ReactNode;
    className?: string;
    loading?: boolean;
    button?: ReactNode;
    select?: ReactNode;
    searchInput?: ReactNode;
}

const InnerBlock = (props: Props) => {
    const { children, className, title, loading, button, description, select, searchInput } = props;
    const cls = `app-inner-block ${className || ''}`;
    return (
        <div className={cls}>
            {!loading ? (
                <div className="app-inner-block-title">
                    <div className="app-inner-block-title-text">
                        <div className="app-inner-block-text-title">{title}</div>
                        {description && (
                            <div className="app-inner-block-text-descritption">{description}</div>
                        )}
                    </div>
                    {!select && button && button}
                    {select && select}
                    {searchInput && searchInput}
                </div>
            ) : (
                <Skeleton className="app-inner-block-title" active />
            )}
            <div className="app-inner-block-content">{children}</div>
        </div>
    );
};

export default InnerBlock;
