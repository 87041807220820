import { makeAutoObservable } from 'mobx';
import { message } from 'antd';
import IPromocodes from 'models/promocodes/IPromocodes';
import { IError, IPageable, IPromocodeFilter } from 'models';
import PromocodesService from 'services/PromocodesService';

class PromocodesStore {
    isLoading = false;
    isCommentLoading = false;
    promocodes: IPromocodes | null = null;
    errorPromocode: IError | null = null;
    errorAddPromocode: IError | null = null;
    successAdd: boolean = false;

    setIsLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setIsCommentLoading(bool: boolean) {
        this.isCommentLoading = bool;
    }

    setPromocodes(data: IPromocodes) {
        this.promocodes = data;
    }

    setError(data: IError | null) {
        this.errorPromocode = data;
    }

    setAddError(data: IError | null) {
        this.errorAddPromocode = data;
    }

    setSuccessAdd(bool: boolean) {
        this.successAdd = bool;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getPromocodes(pageable: IPageable, filter?: IPromocodeFilter) {
        this.setIsLoading(true);

        try {
            const response = await PromocodesService.getPromocodes(pageable, filter);
            this.setPromocodes(response.data);
            this.setError(null);
            this.setIsLoading(false);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
            this.setIsLoading(false);
        }
    }

    async deletePromocode(id: string, pageable: IPageable, filter?: IPromocodeFilter) {
        this.setIsLoading(true);
        try {
            this.setError(null);
            await PromocodesService.deletePromocode(id);
            this.getPromocodes(pageable, filter);
            this.setSuccessAdd(true);
            message.success('Промокод успешно удалён!');
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async turnOffPromocode(id: string, pageable: IPageable, filter?: IPromocodeFilter) {
        this.setIsLoading(true);
        try {
            this.setError(null);
            await PromocodesService.turnOffPromocode(id);
            this.getPromocodes(pageable, filter);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async turnOnPromocode(id: string, pageable: IPageable, filter?: IPromocodeFilter) {
        this.setIsLoading(true);
        try {
            this.setError(null);
            await PromocodesService.turnOnPromocode(id);
            this.getPromocodes(pageable, filter);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async editPromocode(
        promoId: string,
        comment: string,
        pageable: IPageable,
        filter?: IPromocodeFilter
    ) {
        this.setIsCommentLoading(true);

        try {
            this.setError(null);
            await PromocodesService.editComment(promoId, comment);
            this.getPromocodes(pageable, filter);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }

        this.setIsCommentLoading(false);
    }
}

const promocodesStore = new PromocodesStore();

export default promocodesStore;
