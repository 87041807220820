/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Col, Row, Button, DatePicker, Select, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Block } from 'components/layout';
import config from 'config';
import { IError, IOffer } from 'models';
import PromocodesService from 'services/PromocodesService';
import { promocodesStore } from 'store';

import './promocode-add.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = 'Europe/Moscow';
dayjs.tz.setDefault(tz);

interface Props {
    title?: string;
    offers: IOffer[];
    errorAddPromocode?: IError;
}

const PromocodeAdd = (props: Props) => {
    const { title, offers, errorAddPromocode } = props;

    const navigate = useNavigate();

    const [form] = useForm(null);

    const onValuesChange = (e, v) => {
        console.log(v);
        console.log(e);
        if (e.offerId) {
            form.setFieldValue('duration', offers.find((offer) => offer.id === e.offerId).duration);
        }
    };

    const onFinishFailed = (error: any) => {
        console.log(error);
        // message.error(JSON.stringify(error));
    };

    const createPromocode = (formData: any) => {
        const newData = {
            ...formData,
            startDate: formData.startDate ? dayjs(formData.startDate).utc().format() : null,
            endDate: formData.endDate ? dayjs(formData.endDate).utc().format() : null,
        };

        PromocodesService.promocodesPost(newData)
            .then(() => {
                message.success('Промокод успешно добавлен!');
                navigate('/promocodes');
            })
            .catch((error) => {
                promocodesStore.setAddError(error);
            });
    };

    useEffect(() => {
        if (errorAddPromocode && errorAddPromocode.detail) {
            message.error(errorAddPromocode.detail);
        }
    }, [errorAddPromocode]);

    return (
        <Block
            className="app-user-info"
            title={title}
            buttons={
                <Row style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                    <Button
                        onClick={() => {
                            form.resetFields();
                            navigate('/promocodes');
                        }}
                    >
                        Отмена
                    </Button>
                    <Button type="primary" onClick={() => form.submit()}>
                        Создать
                    </Button>
                </Row>
            }
        >
            <Form
                form={form}
                size="small"
                name="main"
                layout="vertical"
                wrapperCol={{ span: 20 }}
                initialValues={{
                    promocodeText: '',
                    offerId: '',
                    duration: '',
                    discount: '100',
                    endDate: dayjs().add(1, 'year').endOf('year'),
                    startDate: dayjs(),
                    comment: '',
                }}
                onFinishFailed={onFinishFailed}
                onFinish={createPromocode}
                onValuesChange={onValuesChange}
                autoComplete="off"
            >
                <Row gutter={16} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Col
                        className="gutter-row"
                        span={12}
                        style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                    >
                        <Form.Item
                            label="Промокод"
                            name="promocodeText"
                            rules={[
                                {
                                    max: 16,
                                    message: 'Максимальная длинна 16 символов',
                                },
                                {
                                    required: true,
                                },
                                {
                                    pattern: /^[A-Za-z0-9-_!?]*$/,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Тариф"
                            name="offerId"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                options={offers?.map((floor) => {
                                    return { value: floor.id, label: floor.name };
                                })}
                            />
                        </Form.Item>
                        <Col style={{ maxWidth: 520, width: 220 }}>
                            <Form.Item
                                label="Срок действия"
                                name="duration"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: /^[0-9]*$/,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Form.Item
                            label="Размер скидки"
                            name="discount"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Col style={{ maxWidth: 520, width: 220 }}>
                            <Form.Item label="Дата начала" name="startDate">
                                <DatePicker
                                    locale={config.ru}
                                    showTime={false}
                                    format={config.dates.classic}
                                />
                            </Form.Item>
                            <Form.Item label="Дата окончания" name="endDate">
                                <DatePicker locale={config.ru} format={config.dates.classic} />
                            </Form.Item>
                        </Col>
                        <Form.Item label="Комментарий" name="comment">
                            {/* TODO: вынести число в константу */}
                            <Input.TextArea maxLength={1000} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Block>
    );
};

export default PromocodeAdd;
