import React, { FC, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { message } from 'antd';
import { observer } from 'mobx-react';
import config from 'config';
import { IRoute } from 'models';
import { Layout } from 'components/layout';
import { useParams } from 'react-router-dom';
import { OrderInfo } from 'components/order';
import OrderService from 'services/OrderService';
import { IOrder } from 'models/IOrders';
import './order.scss';

interface Props {
    navigation: IRoute[];
}

const Order: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const { id } = useParams();
    const [orderData, setOrderData] = useState<IOrder>();

    useEffect(() => {
        OrderService.getOrder(id)
            .then((resp) => {
                setOrderData(resp.data);
            })
            .catch((error) => {
                console.log(error);
                message.error('Ошибка получения данных о заказе');
            });
    }, []);

    return (
        <Layout page="orders" navigation={navigation}>
            <div className="page-event">
                <Scrollbars {...config.scrolls}>
                    <OrderInfo title="Подробности заказа" data={orderData} loading={false} />
                </Scrollbars>
            </div>
        </Layout>
    );
});

export default Order;
