import React, { FC, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { observer } from 'mobx-react';
import config from 'config';
import { IRoute } from 'models';
import { Header, Layout } from 'components/layout';
import { PromocodeAddForm } from 'components/promocodes';
import { offerStore, promocodesStore } from 'store';

import './promocodes-add.scss';

interface Props {
    navigation: IRoute[];
}

const PromocodesAdd: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const { offers } = offerStore;
    const { errorAddPromocode } = promocodesStore;

    useEffect(() => {
        offerStore.getOffers();
    }, []);

    return (
        <Layout page="promocodes" navigation={navigation}>
            <div className="page-promocodes">
                <Scrollbars {...config.scrolls}>
                    <Header title="Добавление промокода" />
                    <PromocodeAddForm offers={offers} errorAddPromocode={errorAddPromocode} />
                </Scrollbars>
            </div>
        </Layout>
    );
});

export default PromocodesAdd;
