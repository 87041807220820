import React, { FC, FormEvent, useState } from 'react';
import { observer } from 'mobx-react';
import { Layout as AntLayout } from 'antd';
import Scrollbars from 'react-custom-scrollbars-2';
import config from 'config';
import { authStore } from 'store';
import { Alert, Button, Input } from 'components/layout';

import './login.scss';

const Login: FC = observer(() => {
    const [authIdentifier, setAuthIdentifier] = useState<string>('');
    const [otpCode, setOTPCode] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorPass, setErrorPass] = useState<boolean>(false);
    const [errorLogin, setErrorLogin] = useState<boolean>(false);
    const { isLoading, isRecovery, errorAuth, logged } = authStore;

    const onLogin = (e: FormEvent) => {
        e.preventDefault();
        if (authIdentifier && password) {
            authStore.login({ authIdentifier: authIdentifier.toLowerCase(), password });
        } else {
            setErrorLogin(!authIdentifier);
            setErrorPass(!password);
        }
    };

    const onOTP = (e: FormEvent) => {
        e.preventDefault();
        if (otpCode) {
            authStore.otp({ authIdentifier: authIdentifier.toLowerCase(), otpCode });
        } else {
            setErrorLogin(!authIdentifier);
            setErrorPass(!otpCode);
        }
    };

    // const onRecovery = () => {
    //     if (authIdentifier) {
    //         authStore.recovery({ email: authIdentifier });
    //     } else {
    //         setErrorLogin(true);
    //     }
    // };

    // const recovery = () => {
    //     authStore.toggleRecovery();
    //     setErrorLogin(false);
    //     setErrorPass(false);
    // };

    const changeLogin = (e: string) => {
        setAuthIdentifier(e);
        if (e) {
            setErrorLogin(false);
        } else {
            setErrorLogin(true);
        }
    };

    const changeOTP = (e: string) => {
        setOTPCode(e);
        if (e) {
            setErrorLogin(false);
        } else {
            setErrorLogin(true);
        }
    };

    const changePassword = (e: string) => {
        setPassword(e);
        if (e) {
            setErrorPass(false);
        } else {
            setErrorPass(true);
        }
    };

    const loginForm = () => {
        return (
            <>
                <div className="stack" style={{ '--stacks': 3 } as React.CSSProperties}>
                    <span style={{ '--index': 0 } as React.CSSProperties}>кибердом</span>
                    <span style={{ '--index': 1 } as React.CSSProperties}>кибердом</span>
                    <span style={{ '--index': 2 } as React.CSSProperties}>кибердом</span>
                </div>

                {!logged ? (
                    <form className="form" noValidate action="#" onSubmit={onLogin}>
                        <Input
                            className="form-login"
                            label="Логин"
                            placeholder="name@company.com"
                            value={authIdentifier}
                            onChange={changeLogin}
                            error={errorLogin}
                        />
                        <Input
                            type="password"
                            className="form-password"
                            label="Пароль"
                            placeholder="Введите пароль"
                            value={password}
                            onChange={changePassword}
                            error={errorPass}
                        />
                        <Button
                            className={
                                errorAuth && errorAuth.title
                                    ? 'wide form-button form-button-alert'
                                    : 'wide form-button'
                            }
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                        >
                            Войти
                        </Button>
                        {/* <TextButton
                        className={errorAuth && errorAuth.title ? 'text-button-alert' : ''}
                        onClick={recovery}
                    >
                        Забыли пароль?
                    </TextButton> */}
                        {errorAuth && errorAuth.title && (
                            <Alert
                                className="login-form-alert"
                                type="error"
                                message={
                                    isRecovery
                                        ? 'Такой email не зарегистрирован в системе. Введите другой email или обратитесь к представителю своей организации.'
                                        : errorAuth.title
                                }
                                showIcon
                            />
                        )}
                    </form>
                ) : (
                    <div>OTP</div>
                )}
            </>
        );
    };

    // const recoveryForm = () => {
    //     return (
    //         <div className="form">
    //             {!successRecovery && (
    //                 <p className="form-title form-title-reset">Восстановление доступа</p>
    //             )}
    //             {successRecovery && (
    //                 <p className="form-title form-title-reset">Доступ восстановлен!</p>
    //             )}
    //             {!successRecovery && (
    //                 <p className="form-subtitle">
    //                     Для восстановления доступа введите ваш логин – почту, на которую придет
    //                     новый пароль для входа
    //                 </p>
    //             )}
    //             {successRecovery && (
    //                 <p className="form-subtitle form-subtitle-reseted">
    //                     На указанный email отправлено письмо с новым паролем для входа в систему.
    //                     Для обеспечения безопасности рекомендуем сменить его как можно скорее в
    //                     личном кабинете.
    //                 </p>
    //             )}
    //             {!successRecovery && (
    //                 <Input
    //                     className="form-login"
    //                     label="Логин"
    //                     placeholder="name@company.com"
    //                     value={login}
    //                     onChange={changeLogin}
    //                     error={errorLogin}
    //                 />
    //             )}
    //             {!successRecovery && (
    //                 <Button
    //                     className={
    //                         errorAuth && errorAuth.title
    //                             ? 'wide form-button form-button-alert'
    //                             : 'wide form-button'
    //                     }
    //                     onClick={onRecovery}
    //                     loading={isLoading}
    //                     type="primary"
    //                 >
    //                     Отправить новый пароль
    //                 </Button>
    //             )}
    //             {successRecovery && (
    //                 <Button
    //                     className="wide form-button"
    //                     onClick={() => {
    //                         authStore.toggleRecovery();
    //                     }}
    //                     type="primary"
    //                 >
    //                     На страницу входа
    //                 </Button>
    //             )}
    //             {!successRecovery && (
    //                 <TextButton
    //                     className={errorAuth && errorAuth.title ? 'text-button-alert' : ''}
    //                     onClick={recovery}
    //                 >
    //                     {Icons.smallBack('#157FFB')}Назад
    //                 </TextButton>
    //             )}
    //             {errorAuth && errorAuth.title && (
    //                 <Alert
    //                     className="login-form-alert"
    //                     type="error"
    //                     message={
    //                         isRecovery
    //                             ? 'Такой email не зарегистрирован в системе. Введите другой email или обратитесь к представителю своей организации.'
    //                             : errorAuth.title
    //                     }
    //                     showIcon
    //                 />
    //             )}
    //         </div>
    //     );
    // };

    const otpForm = () => {
        return (
            <form noValidate action="#" onSubmit={onOTP}>
                <div className="stack" style={{ '--stacks': 3 } as React.CSSProperties}>
                    <span style={{ '--index': 0 } as React.CSSProperties}>кибердом</span>
                    <span style={{ '--index': 1 } as React.CSSProperties}>кибердом</span>
                    <span style={{ '--index': 2 } as React.CSSProperties}>кибердом</span>
                </div>
                <div className="form">
                    <Input
                        className="form-login"
                        label="ОТП код"
                        placeholder="Введите ОТП код"
                        value={otpCode}
                        onChange={changeOTP}
                        error={errorLogin}
                    />
                    <Button
                        className={
                            errorAuth && errorAuth.title
                                ? 'wide form-button form-button-alert'
                                : 'wide form-button'
                        }
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                    >
                        Войти
                    </Button>
                </div>
            </form>
        );
    };

    return (
        <Scrollbars {...config.scrolls}>
            <AntLayout>
                <AntLayout.Content className="page-login">
                    <div className="h100 login-form">
                        <div className="login-main">{logged ? otpForm() : loginForm()}</div>
                        <div className="login-copyright">АО «Кибердом», 2023</div>
                    </div>
                </AntLayout.Content>
            </AntLayout>
        </Scrollbars>
    );
});

export default Login;
