import React, { FC, ReactNode } from 'react';
import { Tooltip as AntTooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import './tooltip.scss';

interface Props {
    title: string | ReactNode;
    children: ReactNode;
    placement?: TooltipPlacement;
}

const Tooltip: FC<Props> = (props: Props) => {
    const { children, title, placement } = props;
    return (
        <AntTooltip placement={placement} title={title}>
            {children}
        </AntTooltip>
    );
};

export default Tooltip;
