import { AxiosResponse } from 'axios';
import qs from 'qs';
import { IOrder, IOrders } from 'models/IOrders';
import { IPageable } from 'models';
import api from '../http';

export interface IOrderFilters {
    orderNumber?: string;
    eventName?: string;
    userInformation?: string;
    ticketNumber?: string;
}

export default class OrderService {
    static async getOrder(id: string): Promise<AxiosResponse<IOrder>> {
        return api.get(`/order/${id}`);
    }

    static async getOrders(
        pageable: IPageable,
        filter: IOrderFilters
    ): Promise<AxiosResponse<IOrders>> {
        return api.get(`/order/`, {
            params: {
                ...pageable,
                ...filter,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });
    }

    static async refundOrder(id: string): Promise<AxiosResponse<IOrder>> {
        return api.post(`/order/${id}/refund/`);
    }
}
