import React, { FC, useCallback, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { IRoute } from 'models';
import { Avatar, Layout as AntLayout } from 'antd';
import { Link } from 'react-router-dom';
import config from 'config';
import { profileStore } from 'store';
import { logo, mobileMenu, close, suffixIconRight } from '../Icons/Icons';

import './topbar.scss';

interface Props {
    navigation: IRoute[];
    page: string;
}

const TopBar: FC<Props> = (props: Props) => {
    const { navigation, page } = props;
    const [open, setOpen] = useState(false);
    const { profile } = profileStore;
    const profileRoute: IRoute = navigation[navigation.length - 1];

    const handleClick = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);

    const button = (item: IRoute) => {
        return (
            <Link
                className={`button ${item.key === page ? 'active' : ''}`}
                to={item.path}
                key={item.key}
                onClick={handleClick}
            >
                <div className="button-icon">{item.icon}</div>
                {item.name}
            </Link>
        );
    };

    const buttons = useMemo(() => {
        const btns = navigation.filter((item: IRoute) => {
            return item.chapter;
        });

        return btns.map((btn) => button(btn));
    }, [navigation]);

    return (
        <AntLayout.Header className="app-mobile-header">
            <div className="app-mobile-header-icon-logo">{logo}</div>
            <div
                tabIndex={0}
                role="button"
                className="app-mobile-header-icon-menu"
                onClick={handleClick}
                onKeyDown={() => false}
            >
                {open ? <span className="app-mobile-header-icon-close">{close}</span> : mobileMenu}
            </div>
            {open && (
                <div className="app-mobile-menu">
                    <Scrollbars {...config.scrolls}>{buttons}</Scrollbars>
                    <div className="topbar-profile-wrapper">
                        <Link
                            className={`topbar-user ${
                                profileRoute.key === page ? 'active-profile' : ''
                            }`}
                            to={profileRoute.path}
                            key={profileRoute.key}
                        >
                            <Avatar className="avatar" icon={`${profile?.name[0]}`} />
                            <div className="user-wrapper">
                                <div className="name">{`${profile?.name}`} </div>
                                <div className="job">{profile?.roles[0].name}</div>
                            </div>
                            {suffixIconRight('#7A829E')}
                        </Link>
                    </div>
                </div>
            )}
        </AntLayout.Header>
    );
};

export default TopBar;
