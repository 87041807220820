import React, { FC } from 'react';
import * as Icons from '../Icons/Icons';
import './empty-search.scss';

interface Props {
    message?: string;
    className?: string;
}

const EmptySearch: FC<Props> = (props: Props) => {
    const { message, className } = props;
    const cls = `app-emptySearch ${className || ''}`;
    return (
        <div className={cls}>
            {Icons.noResultBig}
            {message ? (
                <p>{message}</p>
            ) : (
                <>
                    <p>По вашему запросу ничего не найдено</p>
                    <p>Проверьте правильность ввода или попробуйте изменить запрос.</p>
                </>
            )}
        </div>
    );
};

export default EmptySearch;
