/* eslint-disable no-nested-ternary */
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Block, InnerBlock } from 'components/layout';
import { Form, Input, Col, Row, Button, Collapse } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { IOrder } from 'models/IOrders';

import './order-info.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = 'Europe/Moscow';
dayjs.tz.setDefault(tz);

const { Panel } = Collapse;

interface Props {
    title?: string;
    data?: IOrder | null;
    loading?: boolean;
}

const OrderInfo = (props: Props) => {
    const { title, loading, data } = props;
    const [form] = useForm(null);

    return (
        <Block
            className="app-block"
            loading={loading}
            title={title}
            description={`id: ${data?.orderId || ''}`}
        >
            {data && (
                <Form
                    disabled
                    form={form}
                    size="small"
                    layout="vertical"
                    wrapperCol={{ span: 20 }}
                    initialValues={{
                        ...data,
                        orderDateTime: dayjs(data.orderDateTime).tz(tz).format(),
                        eventInfo: {
                            ...data.eventInfo,
                            datetimeStart: dayjs(data.eventInfo.datetimeStart).tz(tz).format(),
                            datetimeEnd: dayjs(data.eventInfo.datetimeEnd).tz(tz).format(),
                        },
                    }}
                >
                    <Row gutter={16} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Col
                            className="gutter-row"
                            span={12}
                            style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                        >
                            <InnerBlock loading={loading} title="Основная информация">
                                <Form.Item
                                    label="Статус заказа"
                                    name={['orderStatus', 'description']}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Номер заказа" name="orderNumber">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Дата создания" name="orderDateTime">
                                    <Input />
                                </Form.Item>
                                <Button
                                    disabled={false}
                                    type="link"
                                    href={`/user/${data.customerId}`}
                                    target={`/user/${data.customerId}`}
                                >
                                    Перейти к владельцу заказа
                                </Button>
                                <Button
                                    disabled={false}
                                    type="link"
                                    href={`${data.paymentLink}`}
                                    target={`${data.paymentLink}`}
                                >
                                    Ссылка на оплату
                                </Button>
                            </InnerBlock>
                            <InnerBlock loading={loading} title="Билеты">
                                <Form.Item label="Итоговая цена" name="totalPrice">
                                    <Input />
                                </Form.Item>

                                <Collapse accordion>
                                    {data.ticketCount > 0 &&
                                        data.tickets.map((ticket) => {
                                            return (
                                                <Panel
                                                    header={`Билет № ${ticket.ticketNumber}`}
                                                    key={ticket.ticketNumber}
                                                >
                                                    <Form.Item label="Владелец билета">
                                                        <Input value={ticket.userInformation} />
                                                    </Form.Item>
                                                    <Form.Item label="Идентификатор владельца">
                                                        <Input value={ticket.authIdentifier} />
                                                    </Form.Item>
                                                    <Form.Item label="Цена">
                                                        <Input value={ticket.ticketPrice} />
                                                    </Form.Item>
                                                    <Button
                                                        disabled={false}
                                                        type="link"
                                                        href={`/user/${ticket.userId}`}
                                                        target={`/user/${ticket.userId}`}
                                                    >
                                                        Перейти к пользователю
                                                    </Button>
                                                </Panel>
                                            );
                                        })}
                                </Collapse>
                            </InnerBlock>
                        </Col>
                        <Col
                            className="gutter-row"
                            span={12}
                            style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                        >
                            <InnerBlock loading={loading} title="Информация о мероприятии">
                                <Form.Item
                                    label="Название мероприятия"
                                    name={['eventInfo', 'name']}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Начало" name={['eventInfo', 'datetimeStart']}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Окончание" name={['eventInfo', 'datetimeEnd']}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Помещение" name={['eventInfo', 'roomName']}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Этаж" name={['eventInfo', 'floor']}>
                                    <Input />
                                </Form.Item>

                                <Button
                                    disabled={false}
                                    type="link"
                                    href={`/event/${data.eventInfo.id}`}
                                    target={`/event/${data.eventInfo.id}`}
                                >
                                    Перейти к мероприятию
                                </Button>
                            </InnerBlock>
                        </Col>
                    </Row>
                </Form>
            )}
        </Block>
    );
};

export default OrderInfo;
