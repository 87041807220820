import { message } from 'antd';
import { makeAutoObservable } from 'mobx';
import {
    IError,
    IPageable,
    IUser,
    IUserFiltres,
    IUserPost,
    IUserParams,
    IUsers,
    IRoles,
} from 'models';
import { UserService } from 'services';

interface User {
    isLoadingUser: boolean;
    isLoadingUserSave: boolean;
    isLoadingActivated: boolean;
    errorUser: IError | null;
    successChangeStatus: boolean;
    successAdd: boolean;
    success: boolean;
    user: IUser | null;
    driverUser: IUserParams | null;
    users: IUsers | null;
    addUser: Function;
    getUser: Function;
    getUsers: Function;
    updateUser: Function;
}

class UserStore implements User {
    isLoadingUser = false;

    isLoadingUserSave = false;

    isLoadingActivated = false;

    errorUser: IError | null = null;

    successChangeStatus = false;

    successAdd = false;

    success = false;

    user: IUser | null = null;

    driverUser: IUserParams | null = null;

    users: IUsers | null = null;

    roles: IRoles[] | null = null;

    setIsLoading(bool: boolean) {
        this.isLoadingUser = bool;
    }

    setIsLoadingSave(bool: boolean) {
        this.isLoadingUserSave = bool;
    }

    setIsLoadingActivated(bool: boolean) {
        this.isLoadingActivated = bool;
    }

    setError(data: IError | null) {
        this.errorUser = data;
    }

    setSuccessChangeStatus(bool: boolean) {
        this.successChangeStatus = bool;
    }

    setSuccessAdd(bool: boolean) {
        this.successAdd = bool;
    }

    setSuccess(bool: boolean) {
        this.successAdd = bool;
    }

    setUser(data: IUser | null) {
        this.user = data;
    }

    setUsers(data: IUsers | null) {
        this.users = data;
    }

    setDriverUser(data: IUser | null) {
        this.driverUser = data;
    }

    setRoles(data: IRoles[] | null) {
        this.roles = data;
    }

    async addUser(data: IUserPost) {
        this.setIsLoadingSave(true);
        try {
            this.setError(null);
            await UserService.postUser(data);
            this.setSuccessAdd(true);
            message.success('Пользователь успешно создан!');
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoadingSave(false);
    }

    async deleteUser(id: string) {
        this.setIsLoading(true);
        try {
            this.setError(null);
            await UserService.deleteUser(id);
            this.setSuccessAdd(true);
            message.success('Пользователь успешно удалён!');
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async blockUser(id: string) {
        this.setIsLoading(true);
        try {
            this.setError(null);
            await UserService.blockUser(id);
            this.setSuccessAdd(true);
            message.success('Пользователь успешно заблокирован!');
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async unblockUser(id: string) {
        this.setIsLoading(true);
        try {
            this.setError(null);
            await UserService.unblockUser(id);
            this.setSuccessAdd(true);
            message.success('Пользователь успешно разблокирован!');
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async editUser(id: string, data: IUserPost) {
        this.setIsLoadingSave(true);
        try {
            this.setError(null);
            await UserService.putUser(id, data);
            this.setSuccessAdd(true);
            message.success('Пользователь успешно отредактирован!');
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
                if (error.response.data.status === 400) {
                    message.error('Неизвестная ошибка. Попробуйте позже');
                }
            }
        }
        this.setIsLoadingSave(false);
    }

    async getUser(id: string) {
        this.setIsLoading(true);
        try {
            const response = await UserService.getUser(id);
            this.setUser(response.data);
            this.setError(null);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async getUsers(pageable: IPageable, filter?: IUserFiltres) {
        this.setIsLoading(true);

        try {
            let response;

            if (filter.firstName || filter.lastName || filter.email) {
                response = await UserService.getUsers(pageable, filter);
            } else {
                response = await UserService.getUsersDetailed(pageable, filter);
            }

            this.setUsers(response.data);
            this.setError(null);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async getUserByParams(params: IUserParams) {
        this.setIsLoading(true);
        try {
            const response = await UserService.getUserByParams(params);
            this.setDriverUser(response.data);
            this.setError(null);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async updateUser(id: string, subdivisionId: string) {
        this.setIsLoadingSave(true);
        try {
            const response = await UserService.updateUser(id, subdivisionId);
            this.setUser(response.data);
            this.setError(null);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
                if (error.response.data.status === 400) {
                    message.error('Неизвестная ошибка. Попробуйте позже');
                }
            }
        }
        this.setIsLoadingSave(false);
    }

    async activateUser(id: string, reason: string) {
        this.setIsLoadingActivated(true);
        try {
            await UserService.activateUser(id, reason);
            this.setSuccessChangeStatus(true);
            this.setError(null);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
                if (error.response.data.status === 400) {
                    message.error('Неизвестная ошибка. Попробуйте позже');
                }
            }
        }
        this.setIsLoadingActivated(false);
    }

    async deactivateUser(id: string, reason: string) {
        this.setIsLoadingActivated(true);
        try {
            await UserService.deactivateUser(id, reason);
            this.setError(null);
            this.setSuccessChangeStatus(true);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
                if (error.response.data.status === 400) {
                    message.error('Неизвестная ошибка. Попробуйте позже');
                }
            }
        }
        this.setIsLoadingActivated(false);
    }

    async getRoles() {
        this.setIsLoading(true);
        try {
            this.setSuccess(false);
            const response = await UserService.getRoles();
            this.setRoles(response.data);
            this.setSuccess(true);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
            this.setSuccess(false);
        }
        this.setIsLoading(false);
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const userStore = new UserStore();

export default userStore;
