import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout as AntLayout } from 'antd';
import { IError, IRoute } from 'models';
import { authStore } from 'store';
import config from 'config';
import SideBar from '../SideBar/SideBar';
import TopBar from '../TopBar/TopBar';
import ScreenLoad from '../ScreenLoad/ScreenLoad';
import ScreenError from '../ScreenError/ScreenError';

import './layout.scss';

interface Props {
    navigation: IRoute[];
    children: React.ReactNode;
    page: string;
    error?: IError | null;
    errorScreen?: boolean;
}

const Layout: FC<Props> = (props: Props) => {
    const { children, navigation, page, error, errorScreen } = props;
    const { globalLoading, authUserId } = authStore;
    const [statusError, setStatusError] = useState<number | string>();
    const location = useLocation();

    useEffect(() => {
        sessionStorage.setItem(`${config.prefix}_lastLocation_${authUserId}`, location.pathname);
    }, []);

    useEffect(() => {
        if (error && error.status && errorScreen) {
            setStatusError(error.status);
        } else {
            setStatusError(0);
        }
    }, [error]);

    const back = () => {
        window.history.back();
    };

    return (
        <>
            {statusError === 500 && <ScreenError onClick={back} type="500" />}
            {statusError === 404 && <ScreenError onClick={back} type="404" />}
            {statusError === 403 && <ScreenError onClick={back} type="403" />}
            {error?.detail === 'ERR_NETWORK' && <ScreenError onClick={back} type="ERR_NETWORK" />}
            {globalLoading && <ScreenLoad />}
            <AntLayout className="app-layout">
                <TopBar navigation={navigation} page={page} />
                <SideBar navigation={navigation} page={page} />
                <AntLayout.Content className="layout-content">{children}</AntLayout.Content>
            </AntLayout>
        </>
    );
};

export default Layout;
