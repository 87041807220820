import React, { ReactNode } from 'react';
import Skeleton from '../Skeleton/Skeleton';

import './block.scss';

interface Props {
    children?: any;
    title?: string;
    description?: string | ReactNode;
    className?: string;
    loading?: boolean;
    button?: ReactNode;
    select?: ReactNode;
    searchInput?: ReactNode;
    buttons?: ReactNode;
}

const Block = (props: Props) => {
    const {
        children,
        className,
        title,
        loading,
        button,
        description,
        select,
        searchInput,
        buttons,
    } = props;
    const cls = `app-block ${className || ''}`;
    return (
        <div className={cls}>
            {!loading ? (
                <div className="app-block-title">
                    <div className="app-block-title-text">
                        <div className="app-block-text-title">{title}</div>
                        {description && (
                            <div className="app-block-text-descritption">{description}</div>
                        )}
                    </div>
                    {!select && button && button}
                    {select && select}
                    {searchInput && searchInput}
                    {buttons && buttons}
                </div>
            ) : (
                <Skeleton className="app-block-title" active />
            )}
            <div className="app-block-content">{children}</div>
        </div>
    );
};

export default Block;
