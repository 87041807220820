import { makeAutoObservable } from 'mobx';
import { IError, IOffer } from 'models';
import OfferService from 'services/OffersServise';

class OfferStore {
    isLoading = false;
    offers: IOffer[] | null = null;
    errorOffer: IError | null = null;
    errorAddOffer: IError | null = null;

    setIsLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setOffers(data: IOffer[]) {
        this.offers = data;
    }

    setError(data: IError | null) {
        this.errorOffer = data;
    }

    setAddError(data: IError | null) {
        this.errorAddOffer = data;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getOffers() {
        this.setIsLoading(true);
        try {
            this.setError(null);
            const response = await OfferService.getOffers();
            this.setOffers(response.data);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async turnOffOffer(id: string) {
        this.setIsLoading(true);
        try {
            this.setError(null);
            await OfferService.turnOffOffer(id);
            this.getOffers();
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async turnOnOffer(id: string) {
        this.setIsLoading(true);
        try {
            this.setError(null);
            await OfferService.turnOnOffer(id);
            this.getOffers();
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }
}

const offerStore = new OfferStore();

export default offerStore;
