/* eslint-disable no-nested-ternary */
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Input as AntInput, InputNumber, Select, Radio, InputRef, DatePicker, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ISelectOptions } from 'models';
import strings from 'tools';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import config from 'config';
import * as Icons from '../Icons/Icons';
import TextButton from '../TextButton/TextButton';

import './input.scss';

const { RangePicker } = DatePicker;
const { useForm } = Form;

interface Props {
    srcKey?: string;
    label?: string;
    value: string | number | undefined;
    onChange: Function;
    changeDateSearch?: Function;
    onFocus?: Function;
    onBlur?: Function;
    className?: string;
    type?:
        | 'password'
        | 'date'
        | 'number'
        | 'textarea'
        | 'search'
        | 'searchWithSelect'
        | 'input'
        | 'radio'
        | 'autocomplete'
        | 'maskedInputTel';
    placeholder?: string;
    minRows?: number;
    maxRows?: number;
    maxLength?: number;
    onlyNumber?: boolean;
    disabled?: boolean;
    min?: number;
    success?: boolean;
    error?: boolean;
    textError?: string;
    onSearch?: Function;
    searchButton?: boolean;
    selectOptions?: ISelectOptions[];
    radioOptions?: ISelectOptions[];
    // eslint-disable-next-line react/no-unused-prop-types
    ref?: any;
}

const Input: FC<Props> = React.forwardRef<InputRef, Props>((props: Props, ref) => {
    const {
        srcKey,
        label,
        value,
        onChange,
        changeDateSearch,
        onFocus,
        onBlur,
        className,
        type,
        placeholder,
        minRows,
        maxRows,
        maxLength,
        onlyNumber,
        disabled,
        min,
        success,
        error,
        textError,
        onSearch,
        searchButton,
        selectOptions,
        radioOptions,
    } = props;
    const [selectInSearch, setSelectInSearch] = useState<string>(
        selectOptions && selectOptions[0] && selectOptions[0].value ? selectOptions[0].value : ''
    );
    const inputLabel = label ? <span className="label">{label}</span> : null;
    const inputError = error ? (
        <span className="text-error">{textError || 'Обязательное поле'}</span>
    ) : null;
    let cls = `input ${error ? 'input-error' : ''}`;
    cls = !error && success ? `${cls} input-success` : cls;

    const [form] = useForm();
    const [rangeValue, setRangeValue] = useState<[Dayjs, Dayjs]>([null, null]);

    const handlerFocus = (e: any) => {
        if (onFocus) {
            const target = e.target as HTMLInputElement;
            onFocus(target.value, srcKey);
        }
    };

    const handlerBlur = (e: any) => {
        if (onBlur) {
            const target = e.target as HTMLInputElement;
            onBlur(target.value, srcKey);
        }
    };

    const password = () => {
        return (
            <>
                {inputLabel}
                <AntInput.Password
                    className={cls}
                    placeholder={placeholder || 'Введите пароль'}
                    maxLength={maxLength || 32}
                    disabled={disabled}
                    value={value}
                    onChange={(e) => onChange(e.target.value, srcKey)}
                    onBlur={handlerBlur}
                    onFocus={handlerFocus}
                />
                {inputError}
            </>
        );
    };

    const date = () => {
        return (
            <>
                {inputLabel}
                <DatePicker
                    bordered={false}
                    allowClear
                    format={config.dates.calendar}
                    className={cls}
                    placeholder={placeholder || 'Выберите дату в календаре'}
                    disabled={disabled}
                    onChange={(e?: any) => {
                        if (e) {
                            onChange(
                                selectInSearch,
                                dayjs(e).format(config.dates.calendar),
                                srcKey
                            );
                        } else {
                            onChange(selectInSearch, '', srcKey);
                        }
                    }}
                />
                {inputError}
            </>
        );
    };

    const input = () => {
        return (
            <>
                {inputLabel}
                <AntInput
                    className={cls}
                    placeholder={placeholder || 'Введите текст'}
                    maxLength={maxLength || 50}
                    disabled={disabled}
                    value={onlyNumber ? strings.onlynumbers(value) : value}
                    onChange={(e) => {
                        if (e.target.value.match(/^[ ]+$/)) {
                            onChange('', srcKey);
                        } else {
                            onChange(
                                onlyNumber ? strings.onlynumbers(e.target.value) : e.target.value,
                                srcKey
                            );
                        }
                    }}
                    onBlur={handlerBlur}
                    onFocus={handlerFocus}
                />
                {inputError}
            </>
        );
    };

    const maskedInputTel = () => {
        return (
            <>
                {inputLabel}
                <AntInput
                    className={cls}
                    placeholder={placeholder || 'Введите текст'}
                    maxLength={18}
                    disabled={disabled}
                    value={strings.onMaskTel(value)}
                    onChange={(e) => {
                        let valueTel = e.target.value.replace(/\D/g, '');
                        valueTel = valueTel[0] === '9' ? `7${valueTel}` : valueTel;
                        valueTel = valueTel[0] === '8' ? `7${valueTel.substring(1)}` : valueTel;
                        onChange(valueTel.length > 11 ? valueTel.slice(0, -1) : valueTel, srcKey);
                    }}
                    onBlur={(e) => {
                        if (onBlur) onBlur(e.target.value.replace(/\D/g, ''), srcKey);
                    }}
                    onFocus={handlerFocus}
                />
                {inputError}
            </>
        );
    };

    const number = () => {
        return (
            <>
                {inputLabel}
                <InputNumber
                    className={cls}
                    placeholder={placeholder || 'Введите число'}
                    min={min || 0}
                    disabled={disabled}
                    value={value}
                    onChange={(e) => onChange(e, srcKey)}
                    onBlur={handlerBlur}
                    onFocus={handlerFocus}
                />
                {inputError}
            </>
        );
    };

    const textarea = () => {
        return (
            <>
                {inputLabel}
                <AntInput.TextArea
                    className={cls}
                    placeholder={placeholder || 'Введите текст'}
                    autoSize={{
                        minRows: minRows || 2,
                        maxRows: maxRows || 6,
                    }}
                    maxLength={maxLength || 100}
                    value={value}
                    onChange={(e) => onChange(e.target.value, srcKey)}
                    onBlur={handlerBlur}
                    onFocus={handlerFocus}
                />
                <div className="input-textarea-bottom">
                    <div>{inputError}</div>
                    <div className="input-textarea-countLength">{`${
                        typeof value === 'string' ? value?.length || 0 : 0
                    }/${maxLength || 100}`}</div>
                </div>
            </>
        );
    };

    const checkSelectOptions = () => {
        if (!selectOptions) {
            return [];
        }
        const options: ReactNode[] = [];
        selectOptions?.forEach((item) => {
            options.push(
                <Select.Option key={item.value} value={item.value}>
                    {item.label}
                </Select.Option>
            );
        });
        return options;
    };

    const changeSelectInSearch = (e: string) => {
        setSelectInSearch(e);
        onChange(e, '', srcKey);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if ((!searchButton && typeof value === 'string' && value.length > 2) || value === '') {
                if (onSearch) {
                    onSearch();
                }
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [value]);

    useEffect(() => {
        if (selectInSearch === 'datetimeStartRange' || selectInSearch === 'datetimeEndRange') {
            setRangeValue([null, null]);
            changeDateSearch(selectInSearch, [null, null]);
        }
    }, [selectInSearch]);

    const searchWithSelect = () => {
        return (
            <div className="input-search">
                <Select
                    className="input-search-select"
                    onChange={changeSelectInSearch}
                    value={selectInSearch}
                >
                    {checkSelectOptions()}
                </Select>
                {selectInSearch === 'datetimeStartRange' ||
                selectInSearch === 'datetimeEndRange' ? (
                    <Form form={form} className="form">
                        <RangePicker
                            value={rangeValue}
                            name="range"
                            bordered={false}
                            allowClear
                            format={config.dates.calendar}
                            className={cls}
                            disabled={disabled}
                            onChange={(e?: any) => {
                                setRangeValue(e);
                                if (e) {
                                    changeDateSearch(selectInSearch, e, srcKey);
                                } else {
                                    changeDateSearch(selectInSearch, '', srcKey);
                                }
                            }}
                        />
                    </Form>
                ) : selectInSearch === 'datetime' ? (
                    <DatePicker
                        bordered={false}
                        allowClear
                        format={config.dates.calendar}
                        className={cls}
                        placeholder={placeholder || 'Выберите дату в календаре'}
                        disabled={disabled}
                        onChange={(e?: any) => {
                            if (e) {
                                onChange(
                                    selectInSearch,
                                    dayjs(e).format(config.dates.calendar),
                                    srcKey
                                );
                            } else {
                                onChange(selectInSearch, '', srcKey);
                            }
                        }}
                    />
                ) : (
                    <AntInput
                        ref={ref}
                        allowClear
                        className={cls}
                        placeholder={placeholder || 'Поиск...'}
                        maxLength={maxLength || 100}
                        disabled={disabled}
                        value={value}
                        onChange={(e) => onChange(selectInSearch, e.target.value, srcKey)}
                        autoFocus
                    />
                )}
                {searchButton && (
                    <TextButton onClick={onSearch} className="search-button">
                        {Icons.search}
                    </TextButton>
                )}
            </div>
        );
    };

    const search = () => {
        return (
            <AntInput
                allowClear
                className={cls}
                prefix={
                    <SearchOutlined
                        style={{
                            color: '#D4D9E0',
                        }}
                    />
                }
                style={{
                    maxWidth: '380px',
                }}
                placeholder={placeholder || 'Поиск...'}
                maxLength={maxLength || 50}
                disabled={disabled}
                value={value}
                onChange={(e) => onChange(e.target.value, srcKey)}
                onBlur={handlerBlur}
                onFocus={handlerFocus}
            />
        );
    };

    const checkRadioOptions = () => {
        if (!radioOptions) {
            return [];
        }
        const options: ReactNode[] = [];
        radioOptions?.forEach((item) => {
            options.push(
                <Radio key={item.value} value={item.value}>
                    {item.label}
                </Radio>
            );
        });
        return options;
    };

    const radio = () => {
        return (
            <>
                {inputLabel}
                <Radio.Group value={value} onChange={(e) => onChange(e.target.value, srcKey)}>
                    {checkRadioOptions()}
                </Radio.Group>
                {inputError}
            </>
        );
    };

    const field = () => {
        switch (type) {
            case 'password':
                return password();
            case 'date':
                return date();
            case 'number':
                return number();
            case 'textarea':
                return textarea();
            case 'search':
                return search();
            case 'searchWithSelect':
                return searchWithSelect();
            case 'radio':
                return radio();
            case 'input':
                return input();
            case 'maskedInputTel':
                return maskedInputTel();
            default:
                return input();
        }
    };
    return <div className={`app-input ${className || ''}`}>{field()}</div>;
});

export default Input;
