import React, { FC } from 'react';
import Button from '../Button/Button';
import * as Icons from '../Icons/Icons';

import './screen-error.scss';

interface Props {
    type?: '404' | '500' | '403' | 'ERR_NETWORK';
    onClick?: Function;
    textButton?: string;
}

const ScreenError: FC<Props> = (props: Props) => {
    const { type, onClick, textButton } = props;

    const handler = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className="screen-error">
            <div className="screen-error-logo">
                {type === '500' && (
                    <>
                        <div className="error-logo">{Icons.serverError}</div>
                        <div className="error-logo-text">Нет соединения с сервером</div>
                    </>
                )}
                {type === '404' && (
                    <>
                        <div className="error-logo">{Icons.notFound}</div>
                        <div className="error-logo-text">Такая страница не найдена</div>
                    </>
                )}
                {type === '403' && (
                    <>
                        <div className="error-logo">{Icons.forbidden}</div>
                        <div className="error-logo-text">Необходимы права доступа</div>
                    </>
                )}
                {type === 'ERR_NETWORK' && (
                    <>
                        <div className="error-logo">{Icons.noConnection}</div>
                        <div className="error-logo-text">Нет соединения с сетью</div>
                    </>
                )}
                {!type && (
                    <>
                        <div className="error-logo">{Icons.universalError}</div>
                        <div className="error-logo-text">Что-то пошло не так...</div>
                    </>
                )}
            </div>
            {type === '500' && (
                <div className="screen-error-text">
                    <p>Ошибка 500: отсутствует соединение с сервером.</p>
                    <p>Попробуйте еще раз через пару минут или вернитесь на главную.</p>
                </div>
            )}
            {type === '404' && (
                <div className="screen-error-text">
                    <p>Ошибка 404: страница, которую вы ищите была перемещена, удалена,</p>
                    <p>переименована или никогда не существовала.</p>
                </div>
            )}
            {type === '403' && (
                <div className="screen-error-text">
                    <p>Ошибка 403: недостаточно прав для доступа.</p>
                    <p>Свяжитесь со своим руководителем или менеджером платформы.</p>
                </div>
            )}
            {type === 'ERR_NETWORK' && (
                <div className="screen-error-text">
                    <p>
                        Проверьте соединение с сетью, перезагрузите страницу или попробуйте
                        подключиться позже.
                    </p>
                </div>
            )}
            {!type && (
                <div className="screen-error-text">
                    <p>Пожалуйста, попробуйте ещё раз.</p>
                </div>
            )}
            {onClick && (
                <Button type="primary" onClick={handler}>
                    {textButton || (type === '404' ? 'Перейти на главную' : 'Вернуться назад')}
                </Button>
            )}
        </div>
    );
};

export default ScreenError;
