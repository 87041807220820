import { makeAutoObservable } from 'mobx';
import { IError, ILoginRequest } from 'models';
import { AuthService } from 'services';
import IOTPRequest from 'models/login/IOTPRequest';

interface Auth {
    auth: boolean;
    logged: boolean;
    authUserId: string;
    isLoading: boolean;
    isRecovery: boolean;
    errorAuth: IError | null;
    login: Function;
    accessToken: string;
    refreshToken: string;
    resendDelay: number;
    logout: Function;
}
class AuthStore implements Auth {
    auth = false;
    logged = false;
    accessToken = '';
    refreshToken = '';
    authUserId = '';
    isLoading = false;
    resendDelay = 60;
    globalLoading = true;
    isRecovery = false;
    errorAuth: IError | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setResendDelay(resendDelay: number) {
        this.resendDelay = resendDelay;
    }

    setAuth(bool: boolean) {
        this.auth = bool;
    }

    setLogged(logged: boolean) {
        this.logged = logged;
    }

    setAuthUserId(text: string) {
        this.authUserId = text;
    }

    setIsLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setGlobalLoading(bool: boolean) {
        this.globalLoading = bool;
    }

    setError(data: IError | null) {
        this.errorAuth = data;
    }

    async login(body: ILoginRequest) {
        try {
            this.setIsLoading(true);
            this.setError(null);
            const trimedBody: ILoginRequest = {
                authIdentifier: body.authIdentifier.trim(),
                password: body.password.trim(),
            };
            const response = await AuthService.login(trimedBody);
            this.setResendDelay(response.data.resendDelay);
            this.setLogged(true);
            this.setIsLoading(false);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
            this.setIsLoading(false);
        }
    }

    async otp(body: IOTPRequest) {
        this.setIsLoading(true);
        this.setError(null);
        const trimedBody: IOTPRequest = {
            authIdentifier: body.authIdentifier.trim(),
            otpCode: body.otpCode.trim(),
        };
        AuthService.otp(trimedBody)
            .then((resp) => {
                this.accessToken = resp.data.accessToken;
                this.refreshToken = resp.data.refreshToken;
                localStorage.setItem('accessToken', resp.data.accessToken);
                localStorage.setItem('refreshToken', resp.data.refreshToken);
                this.setAuth(true);
                this.setIsLoading(false);
                window.location.href = '/events';
            })
            .catch((error) => {
                if (error && error?.response && error?.response?.data) {
                    this.setError(error.response.data);
                }
                this.setIsLoading(false);
            });
    }

    async logout() {
        this.setGlobalLoading(true);
        this.setError(null);
        AuthService.logout({ value: localStorage.getItem('refreshToken') })
            .then(() => {})
            .catch(() => {})
            .finally(() => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                this.setAuth(false);
                this.setLogged(false);
                this.setGlobalLoading(false);
                window.location.href = '/login';
            });
    }
}

const authStore = new AuthStore();

export default authStore;
