import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { IRoute, IPageable, IEventFilters } from 'models';
import { eventStore, userStore } from 'store';
import { Button, ErrorRequest, Header, Icons, Input, Layout } from 'components/layout';
import { EventsTable } from 'components/event';

import './events.scss';
import { useNavigate } from 'react-router-dom';
import strings from 'tools';
import config from 'config';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
    navigation: IRoute[];
}

const Events: FC<Props> = observer((props: Props) => {
    const { events, isLoadingEvent, errorEvent, successAdd } = eventStore;
    const { navigation } = props;
    const [filter, setFilter] = useState<IEventFilters>({});
    const [isAddEvent, setIsAddEvent] = useState<boolean>(false);
    const [pageable, setPageable] = useState<IPageable>({ page: 0, size: 10 });
    const [search, setSearch] = useState<string>('');
    const [searchColumn, setSearchColumn] = useState<string>('');
    const [statusFilter, setStatusFilter] = useState<string[] | string>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        eventStore.getEvents(pageable, filter);
    }, []);

    useEffect(() => {
        userStore.getRoles();
    }, []);

    useEffect(() => {
        if (successAdd) {
            setIsAddEvent(false);
            setPageable({ page: 0, size: 10 });
            eventStore.setSuccessAdd(false);
        }
    }, [successAdd]);

    useEffect(() => {
        eventStore.getEvents(pageable, filter);
    }, [pageable]);

    useEffect(() => {
        setPageable({ page: 0, size: 10 });
    }, [filter]);

    useEffect(() => {
        if (isAddEvent) {
            navigate('/event/new/');
        }
    }, [isAddEvent]);

    const changeFilter = (column: string, searchData: string) => {
        if (column === 'status') {
            setStatusFilter(searchData);
            setFilter({ ...filter, status: searchData });
        }
    };

    const onSearch = () => {
        setFilter({ ...filter, [searchColumn]: search });
    };

    const changeSearch = (column: string, searchText: string) => {
        if (searchText) {
            setSearchColumn(column);
            setSearch(searchText);
            setFilter({});
        } else {
            setSearch('');
        }
    };

    const changeDateSearch = (column: string, dateSearch: Dayjs[]) => {
        const dateType = column === 'datetimeStartRange' ? 'Start' : 'End';
        if (!dateSearch) {
            const newFilter = { ...filter };
            delete newFilter.dateStartFrom;
            delete newFilter.dateStartTo;
            delete newFilter.dateEndFrom;
            delete newFilter.dateEndTo;
            setFilter(newFilter);
        } else if (Array.isArray(dateSearch) && dateSearch[0] === null && dateSearch[1] === null) {
            setFilter({});
        } else {
            const dateFrom = dayjs(dateSearch[0]).format(config.dates.calendar);
            const dateTo = dayjs(dateSearch[1]).format(config.dates.calendar);

            setFilter({
                ...filter,
                [`date${dateType}From`]: dateFrom,
                [`date${dateType}To`]: dateTo,
            });
        }
    };

    const changePage = (pageNum: number) => {
        const newPageable = { ...pageable };
        newPageable.page = pageNum;
        setPageable(newPageable);
    };

    return (
        <Layout page="events" navigation={navigation} error={errorEvent} errorScreen>
            <Header
                title="Мероприятия"
                description={
                    events
                        ? `Всего: ${events.totalElements} 
                        ${strings.ending(events.totalElements, [
                            'мероприятие',
                            'мероприятия',
                            'мероприятий',
                        ])}
                        `
                        : ''
                }
            />
            <div className="page-events">
                <div className="events-top">
                    <Input
                        ref={inputRef}
                        className="events-search"
                        onChange={changeSearch}
                        value={search}
                        type="searchWithSelect"
                        selectOptions={config.search.events}
                        onSearch={onSearch}
                        changeDateSearch={changeDateSearch}
                    />

                    <Button onClick={() => setIsAddEvent(true)} type="primary">
                        {Icons.plus()}Создать мероприятие
                    </Button>
                </div>

                <EventsTable
                    statusFilter={statusFilter}
                    data={events?.content}
                    page={pageable.page}
                    pageSize={pageable.size}
                    onChangePage={changePage}
                    onChangeFilter={changeFilter}
                    total={events?.totalElements}
                    loading={isLoadingEvent}
                />

                {!events && errorEvent && (
                    <ErrorRequest onClick={() => eventStore.getEvents(pageable)} />
                )}
            </div>
        </Layout>
    );
});

export default Events;
