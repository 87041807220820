import React, { FC } from 'react';
import { Skeleton as AntSkeleton } from 'antd';

import './skeleton.scss';

interface Props {
    className?: string;
    active?: boolean;
    avatar?: boolean;
}

const Skeleton: FC<Props> = (props: Props) => {
    const { className, active, avatar } = props;
    return (
        <AntSkeleton
            avatar={avatar}
            className={`app-skeleton ${className || ''}`}
            active={active || false}
        />
    );
};

export default Skeleton;
