import React, { FC, ReactNode } from 'react';
import { Alert as AntAlert } from 'antd';

import './alert.scss';

interface Props {
    type: 'success' | 'error' | 'warning' | 'info';
    message: string | ReactNode;
    description?: string;
    showIcon?: boolean;
    className?: string;
}

const Alert: FC<Props> = (props: Props) => {
    const { type, message, description, showIcon, className } = props;
    const cls = `alert-${type} ${className || ''}`;
    return (
        <div className="app-alert">
            <AntAlert
                className={cls}
                type={type}
                message={message}
                description={description}
                showIcon={showIcon}
            />
        </div>
    );
};

export default Alert;
