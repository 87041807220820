import { message } from 'antd';
import { makeAutoObservable } from 'mobx';
import { IError, IEvent, IEventFilters, IEventPost, IEvents, IPageable } from 'models';
import IEventStatus from 'models/event/IEventStatus';
import { EventService } from 'services';

interface Event {
    isLoadingEvent: boolean;
    isLoadingEventSave: boolean;
    errorEvent: IError | null;
    successAdd: boolean;
    event: IEvent | null;
    events: IEvents | null;
    addEvent: Function;
    getEvent: Function;
    getEvents: Function;
    updateEvent: Function;
    deleteEvent: Function;
}

class EventStore implements Event {
    isLoadingEvent = false;

    isLoadingEventSave = false;

    errorEvent: IError | null = null;

    successAdd = false;

    event: IEvent | null = null;

    events: IEvents | null = null;

    statuses: IEventStatus;

    setIsLoading(bool: boolean) {
        this.isLoadingEvent = bool;
    }

    setIsLoadingSave(bool: boolean) {
        this.isLoadingEventSave = bool;
    }

    setError(data: IError | null) {
        this.errorEvent = data;
    }

    setSuccessAdd(bool: boolean) {
        this.successAdd = bool;
    }

    setEvent(data: IEvent | null) {
        this.event = data;
    }

    setEvents(data: IEvents | null) {
        this.events = data;
    }

    setEventStatus(data: IEvents | null) {
        this.events = data;
    }

    async addEvent(data: IEventPost) {
        this.setIsLoadingSave(true);
        try {
            this.setError(null);
            await EventService.postEvent(data);
            this.setSuccessAdd(true);
            message.success('Пользователь успешно зарегистрирован!');
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoadingSave(false);
    }

    async updateEvent(id: string, data: IEventPost) {
        this.setIsLoadingSave(true);
        try {
            this.setError(null);
            await EventService.putEvent(id, data);
            this.setSuccessAdd(true);
            message.success('Пользователь успешно отредактирован!');
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
                if (error.response.data.status === 400) {
                    message.error('Неизвестная ошибка. Попробуйте позже');
                }
            }
        }
        this.setIsLoadingSave(false);
    }

    async getEvent(id: string) {
        this.setIsLoading(true);
        try {
            const response = await EventService.getEvent(id);
            this.setEvent(response.data);
            this.setError(null);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async getEvents(pageable: IPageable, filter?: IEventFilters) {
        this.setIsLoading(true);
        try {
            const response = await EventService.getEvents(pageable, filter);
            this.setEvents(response.data);
            this.setError(null);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async deleteEvent(id: string) {
        try {
            const response = await EventService.deleteEvent(id);
            const deletedEvent = this.events.content?.find((event) => event.id === id);
            deletedEvent.status = response.data?.status;
            this.setEvents({
                ...this.events,
                content: this.events.content.map((event) => {
                    return event.id === id ? deletedEvent : event;
                }),
            });
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
                if (error.response.data.status === 400) {
                    message.error('Неизвестная ошибка. Попробуйте позже');
                }
            }
        }
    }

    async activateEvent(id: string) {
        try {
            const response = await EventService.activateEvent(id);
            const activatedEvent = this.events.content?.find((event) => event.id === id);
            activatedEvent.status = response.data?.status;
            this.setEvents({
                ...this.events,
                content: this.events.content.map((event) => {
                    return event.id === id ? activatedEvent : event;
                }),
            });
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
                if (error.response.data.status === 400) {
                    message.error('Неизвестная ошибка. Попробуйте позже');
                }
            }
        }
    }
    async cancelEvent(id: string) {
        try {
            const response = await EventService.cancelEvent(id);
            const cancelledEvent = this.events.content?.find((event) => event.id === id);
            cancelledEvent.status = response.data?.status;
            this.setEvents({
                ...this.events,
                content: this.events.content.map((event) => {
                    return event.id === id ? cancelledEvent : event;
                }),
            });
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
                if (error.response.data.status === 400) {
                    message.error('Неизвестная ошибка. Попробуйте позже');
                }
            }
        }
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const eventStore = new EventStore();

export default eventStore;
