import React from 'react';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ru_RU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import config from 'config';
import App from './App';

import 'dayjs/locale/ru';

dayjs.locale('ru');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ConfigProvider
        locale={ru_RU}
        theme={{
            token: {
                colorPrimary: config.colors.platinate_purple,
            },
        }}
    >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ConfigProvider>
);
