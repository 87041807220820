import { ISelectMarkdownOptions } from 'models/ISelectMarkdownOptions';
import { BOLD_REGEX, ITALIC_BOLD_REGEX, ITALIC_REGEX } from './markdownRegex';

const makeMarkdownLink = (text?: string) => {
    return `[${text || 'Текст ссылки'}](Адрес)`;
};

const makeMarkdownBold = (text?: string) => {
    return `**${text || 'Ваш текст'}**`;
};

const makeMarkdownItalic = (text?: string) => {
    return `*${text || 'Ваш текст'}*`;
};

const markdownChecker = (markdown: string) => {
    return {
        bold: BOLD_REGEX.test(markdown),
        italicBold: ITALIC_BOLD_REGEX.test(markdown),
        italic: ITALIC_REGEX.test(markdown),
    } as ISelectMarkdownOptions;
};

const removeBoldFormatting = (markdown: string) => {
    return markdown.replace(BOLD_REGEX, '$1$2'); // Убираем только жирное форматирование
};

const removeItalicFormatting = (markdown: string) => {
    if (markdown.includes('***') || markdown.includes('___')) {
        return markdown.replace(ITALIC_BOLD_REGEX, '**$1$2**');
    }
    if (markdown.includes('**') || markdown.includes('__')) {
        return markdown.replace(BOLD_REGEX, '***$1$2***');
    }
    return markdown.replace(ITALIC_REGEX, '$1$2');
};

const removeFormatting = (markdown: string) => {
    let formatedText = markdown.replace(ITALIC_BOLD_REGEX, '$1$2'); // Убираем жирное и курсивное форматирование
    formatedText = formatedText.replace(BOLD_REGEX, '$1$2'); // Убираем только жирное форматирование
    formatedText = formatedText.replace(ITALIC_REGEX, '$1$2'); // Убираем только курсивное форматирование

    return formatedText;
};

export {
    makeMarkdownLink,
    makeMarkdownBold,
    makeMarkdownItalic,
    markdownChecker,
    removeFormatting,
    removeBoldFormatting,
    removeItalicFormatting,
};
