import { Icons } from 'components/layout';
import {
    Login,
    Event,
    Events,
    Users,
    User,
    Orders,
    Order,
    Offers,
    OffersAdd,
    Promocodes,
    PromocodeAdd,
    Requests,
} from 'pages';

const privateRoutes = [
    {
        key: 'events',
        chapter: 'ПЛАТФОРМА',
        name: 'Мероприятия',
        icon: Icons.events,
        path: '/events',
        component: Events,
        roles: ['PLATFORM_ADMIN', 'PLATFORM_MANAGER', 'CALL_CENTER_MANAGER'],
    },
    {
        key: 'event',
        name: 'Мероприятия',
        icon: Icons.events,
        path: '/event/:id',
        component: Event,
        roles: [
            'PLATFORM_ADMIN',
            'PLATFORM_MANAGER',
            'CALL_CENTER_MANAGER',
            'BRANCH_MANAGER',
            'BRANCH_ADMIN',
        ],
    },
    {
        key: 'users',
        chapter: 'ПЛАТФОРМА',
        name: 'Пользователи',
        icon: Icons.users,
        path: '/users',
        component: Users,
        roles: ['PLATFORM_ADMIN', 'PLATFORM_MANAGER', 'CALL_CENTER_MANAGER'],
    },
    {
        key: 'user',
        name: 'Пользователи',
        icon: Icons.events,
        path: '/user/:id',
        component: User,
        roles: [
            'PLATFORM_ADMIN',
            'PLATFORM_MANAGER',
            'CALL_CENTER_MANAGER',
            'BRANCH_MANAGER',
            'BRANCH_ADMIN',
        ],
    },
    {
        key: 'orders',
        chapter: 'ПЛАТФОРМА',
        name: 'Заказы',
        icon: Icons.tickets,
        path: '/orders',
        component: Orders,
        roles: ['PLATFORM_ADMIN', 'PLATFORM_MANAGER', 'CALL_CENTER_MANAGER'],
    },
    {
        key: 'order',
        name: 'Заказ',
        icon: Icons.tickets,
        path: '/order/:id',
        component: Order,
        roles: [
            'PLATFORM_ADMIN',
            'PLATFORM_MANAGER',
            'CALL_CENTER_MANAGER',
            'BRANCH_MANAGER',
            'BRANCH_ADMIN',
        ],
    },
    {
        key: 'offers',
        chapter: 'ПЛАТФОРМА',
        name: 'Тарифы',
        path: '/offers',
        component: Offers,
        roles: ['PLATFORM_ADMIN', 'PLATFORM_MANAGER', 'CALL_CENTER_MANAGER'],
    },
    {
        key: 'offers',
        name: 'Тариф',
        path: '/offers/new',
        component: OffersAdd,
        roles: ['PLATFORM_ADMIN', 'PLATFORM_MANAGER', 'CALL_CENTER_MANAGER'],
    },
    {
        key: 'promocodes',
        chapter: 'ПЛАТФОРМА',
        name: 'Промокоды',
        path: '/promocodes',
        component: Promocodes,
        roles: ['PLATFORM_ADMIN', 'PLATFORM_MANAGER', 'CALL_CENTER_MANAGER'],
    },
    {
        key: 'promocode',
        name: 'Промокод',
        path: '/promocodes/new',
        component: PromocodeAdd,
        roles: ['PLATFORM_ADMIN', 'PLATFORM_MANAGER', 'CALL_CENTER_MANAGER'],
    },
    {
        key: 'requests',
        chapter: 'ПЛАТФОРМА',
        name: 'Заявки',
        path: '/requests',
        component: Requests,
        roles: ['PLATFORM_ADMIN', 'PLATFORM_MANAGER', 'CALL_CENTER_MANAGER'],
    },
];

const publicRoutes = [
    {
        key: 'login',
        name: 'Логин',
        path: '/login',
        component: Login,
    },
];

const navigation = {
    privateRoutes,
    publicRoutes,
};

export default navigation;
