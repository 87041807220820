import React from 'react';

export const link = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.9987 5.33203H3.9987C2.52594 5.33203 1.33203 6.52594 1.33203 7.9987V7.9987C1.33203 9.47146 2.52594 10.6654 3.9987 10.6654H5.9987M9.9987 5.33203H11.9987C13.4715 5.33203 14.6654 6.52594 14.6654 7.9987V7.9987C14.6654 9.47146 13.4715 10.6654 11.9987 10.6654H9.9987"
            stroke="#171717"
            strokeWidth="1.33333"
            strokeLinecap="round"
        />
        <path d="M5.33203 8H10.6654" stroke="#171717" strokeWidth="1.33333" strokeLinecap="round" />
    </svg>
);

export const camera = (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.0723 2.69922H13.3401L12.6892 0.875112C12.6443 0.750293 12.5619 0.642401 12.4533 0.566174C12.3447 0.489947 12.2152 0.449102 12.0825 0.449219H5.91914C5.64794 0.449219 5.40485 0.619978 5.31445 0.875112L4.66155 2.69922H1.92941C1.04146 2.69922 0.322266 3.41842 0.322266 4.30636V13.4671C0.322266 14.355 1.04146 15.0742 1.92941 15.0742H16.0723C16.9602 15.0742 17.6794 14.355 17.6794 13.4671V4.30636C17.6794 3.41842 16.9602 2.69922 16.0723 2.69922ZM16.233 13.4671C16.233 13.5555 16.1607 13.6278 16.0723 13.6278H1.92941C1.84102 13.6278 1.76869 13.5555 1.76869 13.4671V4.30636C1.76869 4.21797 1.84102 4.14565 1.92941 4.14565H5.68008L6.0236 3.18538L6.48365 1.89565H11.516L11.9761 3.18538L12.3196 4.14565H16.0723C16.1607 4.14565 16.233 4.21797 16.233 4.30636V13.4671ZM9.00084 5.43136C7.22494 5.43136 5.78655 6.86976 5.78655 8.64565C5.78655 10.4215 7.22494 11.8599 9.00084 11.8599C10.7767 11.8599 12.2151 10.4215 12.2151 8.64565C12.2151 6.86976 10.7767 5.43136 9.00084 5.43136ZM9.00084 10.5742C7.9361 10.5742 7.07227 9.71038 7.07227 8.64565C7.07227 7.58092 7.9361 6.71708 9.00084 6.71708C10.0656 6.71708 10.9294 7.58092 10.9294 8.64565C10.9294 9.71038 10.0656 10.5742 9.00084 10.5742Z"
            fill="#157FFB"
        />
    </svg>
);

export const mobileMenu = (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.4219 0.4375H0.578125C0.483594 0.4375 0.40625 0.514844 0.40625 0.609375V1.98438C0.40625 2.07891 0.483594 2.15625 0.578125 2.15625H17.4219C17.5164 2.15625 17.5938 2.07891 17.5938 1.98438V0.609375C17.5938 0.514844 17.5164 0.4375 17.4219 0.4375ZM17.4219 13.8438H0.578125C0.483594 13.8438 0.40625 13.9211 0.40625 14.0156V15.3906C0.40625 15.4852 0.483594 15.5625 0.578125 15.5625H17.4219C17.5164 15.5625 17.5938 15.4852 17.5938 15.3906V14.0156C17.5938 13.9211 17.5164 13.8438 17.4219 13.8438ZM17.4219 7.14062H0.578125C0.483594 7.14062 0.40625 7.21797 0.40625 7.3125V8.6875C0.40625 8.78203 0.483594 8.85938 0.578125 8.85938H17.4219C17.5164 8.85938 17.5938 8.78203 17.5938 8.6875V7.3125C17.5938 7.21797 17.5164 7.14062 17.4219 7.14062Z"
            fill="#4B5167"
        />
    </svg>
);

export const suffixIconDown = (color?: string) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.81461 8.6719L3.57711 5.52774C3.4743 5.42708 3.4743 5.2643 3.57711 5.16364L4.01023 4.73956C4.11305 4.6389 4.2793 4.6389 4.38211 4.73956L7.00055 7.27759L9.61898 4.73956C9.7218 4.6389 9.88805 4.6389 9.99086 4.73956L10.424 5.16364C10.5268 5.2643 10.5268 5.42708 10.424 5.52774L7.18648 8.6719C7.08367 8.77256 6.91742 8.77256 6.81461 8.6719Z"
            fill={color || '#B8BDCC'}
        />
    </svg>
);

export const suffixIconUp = (color?: string) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.18539 4.74217L10.4229 7.88632C10.5257 7.98699 10.5257 8.14976 10.4229 8.25043L9.98977 8.6745C9.88695 8.77517 9.7207 8.77517 9.61789 8.6745L6.99945 6.13647L4.38102 8.6745C4.2782 8.77517 4.11195 8.77517 4.00914 8.6745L3.57602 8.25043C3.4732 8.14976 3.4732 7.98699 3.57602 7.88632L6.81352 4.74217C6.91633 4.6415 7.08258 4.6415 7.18539 4.74217Z"
            fill={color || '#157FFB'}
        />
    </svg>
);

export const suffixIconRight = (color?: string) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none">
        <path
            d="M10.0967 7.49056L1.0404 0.417121C1.01674 0.398491 0.9883 0.386913 0.958352 0.383717C0.928404 0.380521 0.898162 0.385836 0.871099 0.399053C0.844036 0.41227 0.82125 0.432852 0.805357 0.458435C0.789464 0.484019 0.781109 0.513566 0.781252 0.543684V2.09659C0.781252 2.19502 0.827457 2.28944 0.903797 2.34971L8.13594 7.99681L0.903797 13.6439C0.825448 13.7042 0.781252 13.7986 0.781252 13.897V15.4499C0.781252 15.5845 0.935939 15.6589 1.0404 15.5765L10.0967 8.50306C10.1736 8.44302 10.2359 8.36621 10.2787 8.27849C10.3216 8.19076 10.3438 8.09443 10.3438 7.99681C10.3438 7.89919 10.3216 7.80285 10.2787 7.71513C10.2359 7.6274 10.1736 7.5506 10.0967 7.49056Z"
            fill={color || '#B8BDCC'}
        />
    </svg>
);

export const events = (
    <svg width="20" height="20" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 27V63H27V46H45V63H63V27H54V18H45V9H27V18H18V27H9Z"
            stroke="#e9e8dd"
            strokeWidth="6"
        />
    </svg>
);
export const tickets = (
    <svg width="20" height="20" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 33L9 60H63V33" stroke="#e9e8dd" strokeWidth="6" />
        <path d="M57 33L57 12L15 12L15 33" stroke="#e9e8dd" strokeWidth="6" />
        <path d="M51 33V48L21 48L21 33" stroke="#e9e8dd" strokeWidth="6" />
        <path d="M30 36H42" stroke="#e9e8dd" strokeWidth="6" />
    </svg>
);

export const prev = (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.82067 4.4933H1.9845L6.67468 0.421875C6.74968 0.35625 6.70415 0.234375 6.60504 0.234375H5.41977C5.36754 0.234375 5.31799 0.253125 5.27915 0.286607L0.217986 4.67813C0.171632 4.71831 0.134456 4.76798 0.108977 4.82379C0.0834987 4.87959 0.0703125 4.94022 0.0703125 5.00156C0.0703125 5.06291 0.0834987 5.12354 0.108977 5.17934C0.134456 5.23514 0.171632 5.28482 0.217986 5.325L5.30861 9.7433C5.3287 9.76072 5.35281 9.77009 5.37825 9.77009H6.6037C6.70281 9.77009 6.74834 9.64688 6.67334 9.58259L1.9845 5.51116H9.82067C9.87959 5.51116 9.92781 5.46295 9.92781 5.40402V4.60045C9.92781 4.54152 9.87959 4.4933 9.82067 4.4933Z"
            fill="#7A829E"
        />
    </svg>
);

export const next = (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.78014 4.67813L4.72031 0.286607C4.68147 0.253125 4.63192 0.234375 4.57969 0.234375H3.39442C3.29531 0.234375 3.24978 0.357589 3.32478 0.421875L8.01496 4.4933H0.177455C0.118527 4.4933 0.0703125 4.54152 0.0703125 4.60045V5.40402C0.0703125 5.46295 0.118527 5.51116 0.177455 5.51116H8.01362L3.32344 9.58259C3.24844 9.64822 3.29397 9.77009 3.39308 9.77009H4.61853C4.64397 9.77009 4.66942 9.76072 4.68817 9.7433L9.78014 5.32634C9.8265 5.28602 9.86367 5.23623 9.88915 5.18031C9.91462 5.1244 9.92781 5.06367 9.92781 5.00223C9.92781 4.94079 9.91462 4.88006 9.88915 4.82415C9.86367 4.76824 9.8265 4.71844 9.78014 4.67813Z"
            fill="#7A829E"
        />
    </svg>
);

export const search = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.2125 12.3516L9.15469 8.29375C9.78438 7.47969 10.125 6.48438 10.125 5.4375C10.125 4.18438 9.63594 3.00937 8.75156 2.12344C7.86719 1.2375 6.68906 0.75 5.4375 0.75C4.18594 0.75 3.00781 1.23906 2.12344 2.12344C1.2375 3.00781 0.75 4.18438 0.75 5.4375C0.75 6.68906 1.23906 7.86719 2.12344 8.75156C3.00781 9.6375 4.18438 10.125 5.4375 10.125C6.48438 10.125 7.47813 9.78438 8.29219 9.15625L12.35 13.2125C12.3619 13.2244 12.376 13.2338 12.3916 13.2403C12.4071 13.2467 12.4238 13.2501 12.4406 13.2501C12.4575 13.2501 12.4741 13.2467 12.4897 13.2403C12.5052 13.2338 12.5194 13.2244 12.5312 13.2125L13.2125 12.5328C13.2244 12.5209 13.2338 12.5068 13.2403 12.4912C13.2467 12.4757 13.2501 12.459 13.2501 12.4422C13.2501 12.4254 13.2467 12.4087 13.2403 12.3931C13.2338 12.3776 13.2244 12.3635 13.2125 12.3516ZM7.9125 7.9125C7.25 8.57344 6.37187 8.9375 5.4375 8.9375C4.50312 8.9375 3.625 8.57344 2.9625 7.9125C2.30156 7.25 1.9375 6.37187 1.9375 5.4375C1.9375 4.50312 2.30156 3.62344 2.9625 2.9625C3.625 2.30156 4.50312 1.9375 5.4375 1.9375C6.37187 1.9375 7.25156 2.3 7.9125 2.9625C8.57344 3.625 8.9375 4.50312 8.9375 5.4375C8.9375 6.37187 8.57344 7.25156 7.9125 7.9125Z"
            fill="#157FFB"
        />
    </svg>
);

export const email = (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5 0.49715H0.5C0.223437 0.49715 0 0.720588 0 0.99715V10.9972C0 11.2737 0.223437 11.4972 0.5 11.4972H13.5C13.7766 11.4972 14 11.2737 14 10.9972V0.99715C14 0.720588 13.7766 0.49715 13.5 0.49715ZM12.875 2.2284V10.3722H1.125V2.2284L0.69375 1.89246L1.30781 1.1034L1.97656 1.62371H12.025L12.6938 1.1034L13.3078 1.89246L12.875 2.2284ZM12.025 1.62215L7 5.5284L1.975 1.62215L1.30625 1.10184L0.692188 1.8909L1.12344 2.22684L6.46094 6.37684C6.61444 6.49609 6.80328 6.56082 6.99766 6.56082C7.19203 6.56082 7.38088 6.49609 7.53438 6.37684L12.875 2.2284L13.3062 1.89246L12.6922 1.1034L12.025 1.62215Z"
            fill="#B8BDCC"
        />
    </svg>
);

export const phone = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.7049 2.73159L11.0409 1.06909C10.8377 0.865962 10.5659 0.7519 10.2784 0.7519C9.99086 0.7519 9.71899 0.8644 9.51586 1.06909L7.72367 2.85815C7.52055 3.06128 7.40649 3.33471 7.40649 3.62221C7.40649 3.91128 7.51899 4.18159 7.72367 4.38628L9.12368 5.78784C8.80474 6.53094 8.3465 7.20611 7.77367 7.7769C7.20024 8.35346 6.52992 8.80815 5.78617 9.13003L4.38617 7.72846C4.18305 7.52534 3.91117 7.41128 3.62367 7.41128C3.48187 7.41075 3.34138 7.43854 3.21045 7.493C3.07952 7.54747 2.96077 7.62752 2.86117 7.72846L1.06742 9.51753C0.864299 9.72065 0.750237 9.99409 0.750237 10.2816C0.750237 10.5707 0.862737 10.841 1.06742 11.0457L2.72992 12.7082C3.0768 13.055 3.55492 13.2535 4.04555 13.2535C4.14711 13.2535 4.24555 13.2457 4.34555 13.2285C6.4143 12.8878 8.46742 11.7863 10.1252 10.13C11.7815 8.47065 12.8815 6.41753 13.2252 4.34721C13.3237 3.75971 13.1268 3.15503 12.7049 2.73159ZM12.1174 4.16128C11.8127 6.00346 10.8221 7.84096 9.32992 9.33315C7.83774 10.8253 6.0018 11.816 4.15961 12.1207C3.92836 12.1597 3.69086 12.0816 3.52211 11.9144L1.8893 10.2816L3.62055 8.54878L5.49242 10.4238L5.50649 10.4378L5.84399 10.3128C6.86731 9.9366 7.79659 9.34234 8.56741 8.57125C9.33823 7.80015 9.93217 6.87067 10.3081 5.84721L10.4331 5.50971L8.54555 3.62377L10.2768 1.89096L11.9096 3.52377C12.0784 3.69252 12.1565 3.93003 12.1174 4.16128Z"
            fill="#B8BDCC"
        />
    </svg>
);

export const exit = (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.8797 9.83438H11.0391C10.9734 9.83438 10.9188 9.88906 10.9188 9.95469V10.9203H1.07969V1.07969H10.9203V2.04531C10.9203 2.11094 10.975 2.16563 11.0406 2.16563H11.8813C11.9469 2.16563 12.0016 2.1125 12.0016 2.04531V0.479688C12.0016 0.214062 11.7875 0 11.5219 0H0.479688C0.214062 0 0 0.214062 0 0.479688V11.5203C0 11.7859 0.214062 12 0.479688 12H11.5203C11.7859 12 12 11.7859 12 11.5203V9.95469C12 9.8875 11.9453 9.83438 11.8797 9.83438ZM12.1703 5.90156L9.95313 4.15156C9.87031 4.08594 9.75 4.14531 9.75 4.25V5.4375H4.84375C4.775 5.4375 4.71875 5.49375 4.71875 5.5625V6.4375C4.71875 6.50625 4.775 6.5625 4.84375 6.5625H9.75V7.75C9.75 7.85469 9.87188 7.91406 9.95313 7.84844L12.1703 6.09844C12.1853 6.08675 12.1973 6.07181 12.2056 6.05475C12.214 6.03769 12.2183 6.01897 12.2183 6C12.2183 5.98103 12.214 5.96231 12.2056 5.94525C12.1973 5.9282 12.1853 5.91326 12.1703 5.90156Z"
            fill="#4B5167"
        />
    </svg>
);

export const users = (
    <svg width="20" height="20" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27" cy="16.5" r="9" stroke="#e9e8dd" strokeWidth="6" />
        <rect x="6" y="34.5" width="42" height="30" stroke="#e9e8dd" strokeWidth="6" />
        <path d="M54 33.75H63V65.25H54" stroke="#e9e8dd" strokeWidth="6" />
        <path
            d="M42 22.5C45.3137 22.5 48 19.8137 48 16.5C48 13.1863 45.3137 10.5 42 10.5V4.5C48.6274 4.5 54 9.87258 54 16.5C54 23.1274 48.6274 28.5 42 28.5V22.5Z"
            fill="#e9e8dd"
        />
    </svg>
);

export const smallBack = (color?: string) => (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.1254 6.40625H3.98322L9.4551 1.65625C9.5426 1.57969 9.48947 1.4375 9.37385 1.4375H7.99104C7.9301 1.4375 7.87229 1.45937 7.82697 1.49844L1.92229 6.62188C1.86821 6.66875 1.82483 6.72671 1.79511 6.79181C1.76538 6.85692 1.75 6.92765 1.75 6.99922C1.75 7.07079 1.76538 7.14152 1.79511 7.20663C1.82483 7.27173 1.86821 7.32969 1.92229 7.37656L7.86135 12.5313C7.88479 12.5516 7.91291 12.5625 7.9426 12.5625H9.37229C9.48791 12.5625 9.54104 12.4188 9.45354 12.3438L3.98322 7.59375H13.1254C13.1942 7.59375 13.2504 7.5375 13.2504 7.46875V6.53125C13.2504 6.4625 13.1942 6.40625 13.1254 6.40625Z"
            fill={color || '#FFF'}
        />
    </svg>
);

export const plus = (color?: string) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.46838 1.375H6.53088C6.44755 1.375 6.40588 1.41667 6.40588 1.5V6.40625H1.75C1.66667 6.40625 1.625 6.44792 1.625 6.53125V7.46875C1.625 7.55208 1.66667 7.59375 1.75 7.59375H6.40588V12.5C6.40588 12.5833 6.44755 12.625 6.53088 12.625H7.46838C7.55172 12.625 7.59338 12.5833 7.59338 12.5V7.59375H12.25C12.3333 7.59375 12.375 7.55208 12.375 7.46875V6.53125C12.375 6.44792 12.3333 6.40625 12.25 6.40625H7.59338V1.5C7.59338 1.41667 7.55172 1.375 7.46838 1.375Z"
            fill={color || '#FFF'}
        />
    </svg>
);

export const back = (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.0357 7.1529H3.97548L11.7924 0.367188C11.9174 0.257813 11.8415 0.0546875 11.6764 0.0546875H9.70092C9.61387 0.0546875 9.53128 0.0859374 9.46655 0.141741L1.03128 7.46094C0.954022 7.52791 0.892062 7.6107 0.849598 7.7037C0.807133 7.79671 0.785156 7.89776 0.785156 8C0.785156 8.10224 0.807133 8.20329 0.849598 8.2963C0.892062 8.3893 0.954022 8.47209 1.03128 8.53906L9.51565 15.9029C9.54914 15.9319 9.58931 15.9475 9.63173 15.9475H11.6741C11.8393 15.9475 11.9152 15.7422 11.7902 15.635L3.97548 8.84933H17.0357C17.134 8.84933 17.2143 8.76897 17.2143 8.67076V7.33147C17.2143 7.23326 17.134 7.1529 17.0357 7.1529Z"
            fill="#157FFB"
        />
    </svg>
);

export const remove = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.60826 1.67746H5.42969C5.5279 1.67746 5.60826 1.5971 5.60826 1.49888V1.67746H12.394V1.49888C12.394 1.5971 12.4743 1.67746 12.5725 1.67746H12.394V3.2846H14.0011V1.49888C14.0011 0.710938 13.3605 0.0703125 12.5725 0.0703125H5.42969C4.64174 0.0703125 4.00112 0.710938 4.00112 1.49888V3.2846H5.60826V1.67746ZM16.8583 3.2846H1.14397C0.748884 3.2846 0.429688 3.60379 0.429688 3.99888V4.71317C0.429688 4.81138 0.510045 4.89174 0.608259 4.89174H1.95647L2.50781 16.5658C2.54353 17.327 3.17299 17.9275 3.93415 17.9275H14.0681C14.8315 17.9275 15.4587 17.3292 15.4944 16.5658L16.0458 4.89174H17.394C17.4922 4.89174 17.5725 4.81138 17.5725 4.71317V3.99888C17.5725 3.60379 17.2534 3.2846 16.8583 3.2846ZM13.8962 16.3203H4.10603L3.56585 4.89174H14.4364L13.8962 16.3203Z"
            fill="#B8BDCC"
        />
    </svg>
);

export const logout = (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 18V21H3L3 3H14V6" stroke="#e9e8dd" strokeWidth="2" />
        <path d="M8 12H22M22 12L18 7M22 12L18 17" stroke="#e9e8dd" strokeWidth="2" />
    </svg>
);

export const logo = (
    <svg
        width="186"
        height="32"
        viewBox="0 0 186 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_4228_2107)">
            <path
                d="M172.306 8.24316L176.508 17.4389H177.13L181.332 8.24316H186.001V23.6182H182.734V12.7643L182.765 12.4628H182.609L178.686 20.9047H174.952L171.03 12.4628H170.874L170.905 12.7643V23.6182H167.637V8.24316H172.306Z"
                fill="white"
                fillOpacity="0.9"
            />
            <path
                d="M62.7449 8.24316V18.4928L62.7137 18.7956H63.0253L70.2147 8.24316H73.7946V23.6182H70.5263V13.3686L70.5574 13.0658H70.2459L63.0564 23.6182H59.4766V8.24316H62.7449Z"
                fill="white"
                fillOpacity="0.9"
            />
            <path
                d="M46.2474 8.24316V14.2718H48.5825L53.4071 8.24316H57.1413L51.3835 15.327L57.6086 23.6182H53.5629L48.7383 17.1374H46.2474V23.6182H42.9805V8.24316H46.2474Z"
                fill="white"
                fillOpacity="0.9"
            />
            <path
                d="M162.455 10.2483C160.868 8.71527 158.912 7.94873 156.587 7.94873C154.262 7.94873 152.306 8.71755 150.72 10.2552C149.133 11.7928 148.34 13.6873 148.34 15.9385C148.34 18.1896 149.128 20.0891 150.705 21.6368C152.302 23.1635 154.263 23.9273 156.587 23.9282C158.911 23.9291 160.867 23.1603 162.455 21.6217C164.04 20.0795 164.833 18.1828 164.833 15.9316C164.833 13.6804 164.04 11.786 162.455 10.2483ZM160.074 19.6551C159.181 20.5898 158.019 21.0571 156.587 21.0571C155.155 21.0571 153.993 20.5898 153.102 19.6551C152.21 18.7205 151.763 17.4793 151.763 15.9316C151.762 14.3839 152.208 13.1478 153.102 12.2232C153.994 11.2776 155.156 10.8052 156.587 10.8061C158.018 10.807 159.18 11.2744 160.074 12.2081C160.966 13.1427 161.412 14.3839 161.412 15.9316C161.412 17.4793 160.966 18.7205 160.074 19.6551Z"
                fill="white"
                fillOpacity="0.9"
            />
            <path
                d="M110.209 15.9316C110.209 13.6795 109.426 11.7851 107.86 10.2484C106.293 8.71163 104.379 7.94509 102.116 7.94874C99.8503 7.94874 97.9362 8.71757 96.3738 10.2552C94.8114 11.7929 94.0292 13.685 94.0273 15.9316C94.0273 18.1819 94.8052 20.0813 96.3611 21.6299C97.9386 23.1566 99.8578 23.9204 102.119 23.9213C104.61 23.9213 106.706 23.0991 108.406 21.4545C109.089 20.7346 109.639 19.9056 110.029 19.0042L110.055 18.9466H106.631C106.354 19.3536 106.006 19.7107 105.602 20.0018C104.732 20.7053 103.57 21.0571 102.116 21.0571C100.976 21.0727 99.8688 20.687 99.0006 19.9717C98.1198 19.2563 97.5534 18.2755 97.3013 17.0293L97.2928 16.9869H110.209V15.9316ZM97.4472 14.4241C97.6583 13.3772 98.2545 12.4399 99.1253 11.786C99.9767 11.1392 101.031 10.7935 102.113 10.8061C103.441 10.8061 104.505 11.1579 105.304 11.8614C106.083 12.5283 106.604 13.4321 106.782 14.4241H97.4472Z"
                fill="white"
                fillOpacity="0.9"
            />
            <path
                d="M90.2742 11.4993C88.9147 10.1289 87.2079 9.44364 85.1536 9.44364C83.801 9.43408 82.474 9.80032 81.3302 10.4989C80.899 10.7838 80.5167 11.1325 80.1973 11.5322L80.1831 11.5541H80.0274C80.1661 9.60718 80.868 8.09146 82.1331 7.00698C83.3981 5.9225 85.2107 5.3798 87.5709 5.37889H91.0686V2.51465H87.3329C83.8267 2.51465 81.1862 3.51462 79.4114 5.51456C77.6365 7.51451 76.7501 10.6853 76.752 15.0269C76.752 17.8198 77.4675 20.0007 78.8987 21.5694C80.3299 23.1381 82.2081 23.922 84.5333 23.9211C86.8359 23.9211 88.7084 23.2477 90.151 21.901C91.5935 20.5543 92.3147 18.8157 92.3147 16.6851C92.3138 14.5938 91.6336 12.8652 90.2742 11.4993ZM87.6445 19.8207C86.815 20.6144 85.6956 21.0596 84.5291 21.0596C83.3626 21.0596 82.2432 20.6144 81.4137 19.8207C80.5839 18.9984 80.169 17.9532 80.169 16.6851C80.169 15.417 80.5877 14.3718 81.425 13.5495C82.2549 12.7272 83.2933 12.3161 84.5404 12.3161C85.7875 12.3161 86.826 12.7272 87.6558 13.5495C88.4856 14.3718 88.9005 15.417 88.9005 16.6851C88.9005 17.9532 88.4819 18.9984 87.6445 19.8207Z"
                fill="white"
                fillOpacity="0.9"
            />
            <path
                d="M145.693 20.7554L142.115 8.24316H135.111L131.532 20.7554H129.975V26.9361H132.931V23.6196H144.3V26.9361H147.257V20.7554H145.693ZM134.955 20.7554L137.602 11.1074H139.627L142.273 20.7554H134.955Z"
                fill="white"
                fillOpacity="0.9"
            />
            <path
                d="M126.753 10.158C125.327 8.68519 123.548 7.94881 121.414 7.94881C120.085 7.94242 118.789 8.34349 117.71 9.0945C117.254 9.40015 116.839 9.7597 116.475 10.1648L116.434 10.21H116.278V8.24345H113.01V29.3484H116.278V21.6602H116.434C116.803 22.0964 117.232 22.4819 117.708 22.8059C118.792 23.544 120.088 23.9342 121.413 23.9214C123.55 23.9214 125.33 23.1827 126.751 21.7054C128.173 20.228 128.881 18.3035 128.876 15.9317C128.881 13.5599 128.174 11.6353 126.753 10.158ZM124.215 19.7347C123.385 20.6182 122.244 21.0604 120.791 21.0613C119.422 21.0613 118.328 20.614 117.509 19.7196C116.689 18.8252 116.279 17.5639 116.278 15.9358C116.278 14.3077 116.683 13.0514 117.493 12.167C118.323 11.2626 119.422 10.8103 120.791 10.8103C122.244 10.8103 123.385 11.2525 124.215 12.1369C125.045 13.0213 125.461 14.2862 125.464 15.9317C125.464 17.579 125.048 18.8466 124.215 19.7347Z"
                fill="white"
                fillOpacity="0.9"
            />
            <path
                d="M13.1809 9.61096H19.7785V16.0343H19.7884V22.4H13.2022V19.2H16.4917V15.989H16.4846V12.8151H13.1809V15.989H13.1781V19.1959H9.89844V15.9781H9.89136V12.8123H13.205V9.61233H6.59471V16.0219H6.60462V22.4H13.1781V25.6H23.0864V15.989H23.0709V6.40822H13.205V3.21233H0V16.0219H0.0184091V28.8082H13.1781V32H29.6797V15.989H29.6585V0H13.1809V3.20411H26.3647V16.0343H26.3831V28.8H13.2022V25.6041H3.31222V15.9781H3.29665V6.41233H13.1809V9.61096Z"
                fill="white"
                fillOpacity="0.9"
            />
        </g>
        <defs>
            <clipPath id="clip0_4228_2107">
                <rect width="186" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const cube = (
    <svg
        width="154"
        height="148"
        viewBox="0 0 154 148"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_4228_2118)">
            <path
                d="M141.5 3.5L26.5 26.5L48 147L153.5 115.5L141.5 3.5Z"
                fill="url(#paint0_linear_4228_2118)"
            />
            <path d="M95 0L142 3.5L26.5 26.5L0.5 17L95 0Z" fill="url(#paint1_linear_4228_2118)" />
            <path
                d="M48 147.5L26.5 26.5L0.5 17L19 111L48 147.5Z"
                fill="url(#paint2_linear_4228_2118)"
            />
            <path
                d="M94.0723 67.996L99.6812 66.8038L100.31 72.2135L100.318 72.2117L100.942 77.5729L95.3423 78.7631L95.0291 76.068L97.8257 75.4736L97.5114 72.7693L97.5053 72.7706L97.1946 70.0975L94.386 70.6945L94.6967 73.3676L94.6943 73.3681L95.0082 76.0689L92.22 76.6616L91.905 73.9515L91.899 73.9528L91.5891 71.2867L94.4062 70.6879L94.0929 67.9928L88.4732 69.1873L89.1007 74.5855L89.1091 74.5837L89.7335 79.9553L95.3219 78.7674L95.6351 81.4624L104.059 79.672L103.118 71.5777L103.105 71.5805L102.167 63.5115L93.7793 65.2943L93.4664 62.6028L82.2402 64.989L83.4942 75.7772L83.5099 75.7738L84.7615 86.5424L95.9492 84.1644L96.2616 86.8525L110.29 83.8706L108.723 70.3862L108.705 70.3901L107.14 56.9242L93.1315 59.9017L93.4451 62.6002L104.653 60.2179L105.909 71.0233L105.925 71.02L107.175 81.7713L95.9688 84.1531L95.656 81.4616L87.2481 83.2487L86.3058 75.1417L86.2925 75.1445L85.3561 67.0883L93.7592 65.3022L94.0723 67.996Z"
                fill="#BFFD4F"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_4228_2118"
                x1="148.5"
                y1="109"
                x2="36"
                y2="31"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2B041A" />
                <stop offset="1" stopColor="#50092E" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_4228_2118"
                x1="136.429"
                y1="19.4826"
                x2="126.396"
                y2="-22.4874"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2B041A" />
                <stop offset="1" stopColor="#50092E" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_4228_2118"
                x1="46.1299"
                y1="112.943"
                x2="-11.4886"
                y2="96.5126"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2B041A" />
                <stop offset="1" stopColor="#50092E" />
            </linearGradient>
            <clipPath id="clip0_4228_2118">
                <rect width="153" height="148" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
);

export const emptyRequest = (
    <svg
        width="201"
        height="171"
        viewBox="0 0 201 171"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M100.288 147.578C129.152 147.578 152.55 143.205 152.55 137.812C152.55 132.419 129.152 128.047 100.288 128.047C71.4241 128.047 48.0254 132.419 48.0254 137.812C48.0254 143.205 71.4241 147.578 100.288 147.578Z"
            fill="#F1F2F7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M142.097 109.124L123.655 86.4261C122.77 85.3577 121.476 84.7109 120.114 84.7109H80.4605C79.0992 84.7109 77.8057 85.3577 76.9207 86.4261L58.4785 109.124V120.983H142.098V109.124H142.097Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.104 55.4141H126.471C127.289 55.4141 128.073 55.7389 128.652 56.3172C129.23 56.8955 129.555 57.6797 129.555 58.4975V130.453C129.555 131.271 129.23 132.055 128.652 132.634C128.073 133.212 127.289 133.537 126.471 133.537H74.104C73.2862 133.537 72.5019 133.212 71.9236 132.634C71.3454 132.055 71.0205 131.271 71.0205 130.453V58.4975C71.0205 57.6797 71.3454 56.8955 71.9236 56.3172C72.5019 55.7389 73.2862 55.4141 74.104 55.4141V55.4141Z"
            fill="#F1F2F7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M80.9244 63.0859H119.65C120.059 63.0859 120.451 63.2484 120.741 63.5375C121.03 63.8266 121.192 64.2188 121.192 64.6277V83.8662C121.192 84.2751 121.03 84.6672 120.741 84.9564C120.451 85.2455 120.059 85.4079 119.65 85.4079H80.9244C80.5155 85.4079 80.1234 85.2455 79.8342 84.9564C79.5451 84.6672 79.3827 84.2751 79.3827 83.8662V64.6277C79.3827 64.2188 79.5451 63.8266 79.8342 63.5375C80.1234 63.2484 80.5155 63.0859 80.9244 63.0859ZM81.1264 93.7772H119.448C119.911 93.7772 120.354 93.9609 120.681 94.2879C121.008 94.6149 121.192 95.0585 121.192 95.5209C121.192 95.9834 121.008 96.4269 120.681 96.7539C120.354 97.0809 119.911 97.2646 119.448 97.2646H81.1264C80.6639 97.2646 80.2204 97.0809 79.8934 96.7539C79.5664 96.4269 79.3827 95.9834 79.3827 95.5209C79.3827 95.0585 79.5664 94.6149 79.8934 94.2879C80.2204 93.9609 80.6639 93.7772 81.1264 93.7772ZM81.1264 102.845H119.448C119.911 102.845 120.355 103.029 120.682 103.356C121.009 103.683 121.193 104.126 121.193 104.589C121.193 105.052 121.009 105.495 120.682 105.822C120.355 106.149 119.911 106.333 119.448 106.333H81.1264C80.6638 106.333 80.2202 106.149 79.8931 105.822C79.566 105.495 79.3823 105.052 79.3823 104.589C79.3823 104.126 79.566 103.683 79.8931 103.356C80.2202 103.029 80.6638 102.845 81.1264 102.845ZM141.927 136.379C141.33 138.746 139.231 140.511 136.735 140.511H63.8397C61.3436 140.511 59.2453 138.746 58.6487 136.379C58.5349 135.928 58.4774 135.465 58.4775 134.999V109.124H78.7652C81.0061 109.124 82.8123 111.011 82.8123 113.302V113.333C82.8123 115.623 84.6392 117.472 86.8801 117.472H113.695C115.936 117.472 117.763 115.606 117.763 113.315V113.306C117.763 111.015 119.569 109.123 121.81 109.123H142.097V135C142.097 135.476 142.038 135.937 141.927 136.379Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.23 56.6637L144.965 58.7065C144.83 58.7591 144.682 58.7724 144.54 58.745C144.397 58.7176 144.265 58.6505 144.159 58.5514C144.053 58.4523 143.977 58.3254 143.94 58.1851C143.903 58.0448 143.906 57.8968 143.95 57.7583L145.443 52.9735C143.447 50.7041 142.275 47.9367 142.275 44.9504C142.275 37.2456 150.075 31 159.697 31C169.317 31 177.117 37.2456 177.117 44.9504C177.117 52.6552 169.317 58.9007 159.696 58.9007C156.206 58.9007 152.956 58.079 150.23 56.6637Z"
            fill="#E8EBF2"
        />
        <path
            d="M166.558 47.4728C167.77 47.4728 168.754 46.5013 168.754 45.3028C168.754 44.1043 167.77 43.1328 166.558 43.1328C165.345 43.1328 164.361 44.1043 164.361 45.3028C164.361 46.5013 165.345 47.4728 166.558 47.4728Z"
            fill="white"
        />
        <path
            d="M160.474 47.34C161.687 47.34 162.67 46.3684 162.67 45.17C162.67 43.9715 161.687 43 160.474 43C159.261 43 158.278 43.9715 158.278 45.17C158.278 46.3684 159.261 47.34 160.474 47.34Z"
            fill="white"
        />
        <path
            d="M153.474 47.34C154.687 47.34 155.67 46.3684 155.67 45.17C155.67 43.9715 154.687 43 153.474 43C152.261 43 151.278 43.9715 151.278 45.17C151.278 46.3684 152.261 47.34 153.474 47.34Z"
            fill="white"
        />
        <path d="M116.653 2V3.42853" stroke="#F1F2F7" strokeWidth="2.921" strokeLinecap="round" />
        <path
            d="M112.368 6.28906H113.796"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path
            d="M116.653 10.5692V9.14062"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path
            d="M120.224 6.28906H118.795"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path d="M33.7974 62V64.1428" stroke="#F1F2F7" strokeWidth="2.921" strokeLinecap="round" />
        <path
            d="M27.3696 68.4297H29.5124"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path d="M33.7974 74.1428V72" stroke="#F1F2F7" strokeWidth="2.921" strokeLinecap="round" />
        <path
            d="M39.5124 68.4297H37.3696"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.2778 47.616C56.1959 47.3297 57.6021 46.2076 57.9277 45C58.223 46.0669 59.4606 47.3297 60.5776 47.4255C59.3206 47.8701 58.1187 49.193 57.9277 50.232C57.7979 49.173 56.1353 47.7701 55.2778 47.616Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.2778 47.616C56.1959 47.3297 57.6021 46.2076 57.9277 45C58.223 46.0669 59.4606 47.3297 60.5776 47.4255C59.3206 47.8701 58.1187 49.193 57.9277 50.232C57.7979 49.173 56.1353 47.7701 55.2778 47.616Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.6333 121.179C45.6333 122.935 44.2107 124.358 42.4559 124.358C40.7004 124.358 39.2778 122.935 39.2778 121.179C39.2778 119.423 40.7004 118 42.4559 118C44.2107 118 45.6333 119.423 45.6333 121.179Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M142.278 87.2326C144.113 86.66 146.927 84.4152 147.578 82C148.169 84.135 150.645 86.66 152.879 86.8511C150.364 87.7415 147.96 90.3866 147.578 92.4646C147.319 90.3466 143.993 87.5413 142.278 87.2326Z"
            fill="#F1F2F7"
        />
    </svg>
);

export const noResult = (
    <svg width="74" height="62" viewBox="0 0 74 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M38.3984 1.5V2.01835"
            stroke="#F1F2F7"
            strokeWidth="1.05991"
            strokeLinecap="round"
        />
        <path
            d="M36.8438 3.05469H37.3621"
            stroke="#F1F2F7"
            strokeWidth="1.05991"
            strokeLinecap="round"
        />
        <path
            d="M38.3984 4.6121V4.09375"
            stroke="#F1F2F7"
            strokeWidth="1.05991"
            strokeLinecap="round"
        />
        <path
            d="M39.698 3.05469H39.1797"
            stroke="#F1F2F7"
            strokeWidth="1.05991"
            strokeLinecap="round"
        />
        <path
            d="M8.33594 23.2734V24.051"
            stroke="#F1F2F7"
            strokeWidth="1.05991"
            strokeLinecap="round"
        />
        <path
            d="M6.00781 25.6016H6.78534"
            stroke="#F1F2F7"
            strokeWidth="1.05991"
            strokeLinecap="round"
        />
        <path
            d="M8.33594 27.676V26.8984"
            stroke="#F1F2F7"
            strokeWidth="1.05991"
            strokeLinecap="round"
        />
        <path
            d="M10.4103 25.6016H9.63281"
            stroke="#F1F2F7"
            strokeWidth="1.05991"
            strokeLinecap="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.2578 41.3518C69.9238 41.1441 70.9448 40.3295 71.1811 39.4531C71.3954 40.2278 72.2938 41.1441 73.1044 41.2134C72.1919 41.5365 71.3195 42.4963 71.1811 43.2503C71.0869 42.4817 69.8803 41.4638 69.2578 41.3518Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.890625 51.1836C1.22375 51.0797 1.734 50.6726 1.85216 50.2344C1.95931 50.6215 2.40839 51.0797 2.81369 51.1145C2.35756 51.2758 1.92146 51.7558 1.85216 52.1328C1.80507 51.7486 1.20175 51.2395 0.890625 51.1836Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.07549 15.1278C7.07549 15.6085 6.68604 15.9978 6.20572 15.9978C5.72517 15.9978 5.33594 15.6085 5.33594 15.1278C5.33594 14.6474 5.72517 14.2578 6.20572 14.2578C6.68604 14.2578 7.07549 14.6474 7.07549 15.1278Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.1499 17.4619C65.1499 18.265 64.4988 18.916 63.6961 18.916C62.893 18.916 62.2422 18.265 62.2422 17.4619C62.2422 16.6588 62.893 16.0078 63.6961 16.0078C64.4988 16.0078 65.1499 16.6588 65.1499 17.4619Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2514 60.341C20.2514 60.9781 19.7353 61.4945 19.0985 61.4945C18.4615 61.4945 17.9453 60.9781 17.9453 60.341C17.9453 59.7038 18.4615 59.1875 19.0985 59.1875C19.7353 59.1875 20.2514 59.7038 20.2514 60.341Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.6303 45.9772H25.3767C23.8808 45.9772 22.6562 44.7491 22.6562 43.2482V12.9394C22.6562 11.4384 23.8808 10.2109 25.3767 10.2109H46.6303C48.1269 10.2109 49.3514 11.4384 49.3514 12.9394V43.2482C49.3514 44.7491 48.1269 45.9772 46.6303 45.9772Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.8178 47.2741H23.5642C22.0683 47.2741 20.8438 46.046 20.8438 44.545V14.2363C20.8438 12.7353 22.0683 11.5078 23.5642 11.5078H44.8178C46.3144 11.5078 47.5389 12.7353 47.5389 14.2363V44.545C47.5389 46.046 46.3144 47.2741 44.8178 47.2741Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.0549 48.2979H21.7573C20.2583 48.2979 19.0312 47.0749 19.0312 45.5801V15.3969C19.0312 13.9021 20.2583 12.6797 21.7573 12.6797H43.0549C44.5545 12.6797 45.7816 13.9021 45.7816 15.3969V45.5801C45.7816 47.0749 44.5545 48.2979 43.0549 48.2979Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.0549 48.2979H21.7573C20.2583 48.2979 19.0312 47.0749 19.0312 45.5801V15.3969C19.0312 13.9021 20.2583 12.6797 21.7573 12.6797H43.0549C44.5545 12.6797 45.7816 13.9021 45.7816 15.3969V45.5801C45.7816 47.0749 44.5545 48.2979 43.0549 48.2979Z"
            fill="#F1F2F7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9853 21.8754H24.2545C23.8052 21.8754 23.4375 21.5255 23.4375 21.0981C23.4375 20.6702 23.8052 20.3203 24.2545 20.3203H39.9853C40.4346 20.3203 40.8023 20.6702 40.8023 21.0981C40.8023 21.5255 40.4346 21.8754 39.9853 21.8754Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9853 26.5394H24.2545C23.8052 26.5394 23.4375 26.1896 23.4375 25.7622C23.4375 25.3342 23.8052 24.9844 24.2545 24.9844H39.9853C40.4346 24.9844 40.8023 25.3342 40.8023 25.7622C40.8023 26.1896 40.4346 26.5394 39.9853 26.5394Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9853 31.2035H24.2545C23.8052 31.2035 23.4375 30.8537 23.4375 30.4263C23.4375 29.9989 23.8052 29.6484 24.2545 29.6484H39.9853C40.4346 29.6484 40.8023 29.9989 40.8023 30.4263C40.8023 30.8537 40.4346 31.2035 39.9853 31.2035Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9853 35.8676H24.2545C23.8052 35.8676 23.4375 35.5177 23.4375 35.0903C23.4375 34.6623 23.8052 34.3125 24.2545 34.3125H39.9853C40.4346 34.3125 40.8023 34.6623 40.8023 35.0903C40.8023 35.5177 40.4346 35.8676 39.9853 35.8676Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.4657 40.5316H24.258C23.8068 40.5316 23.4375 40.1818 23.4375 39.7544C23.4375 39.3264 23.8068 38.9766 24.258 38.9766H32.4657C32.9169 38.9766 33.2862 39.3264 33.2862 39.7544C33.2862 40.1818 32.9169 40.5316 32.4657 40.5316Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.0261 34.5058C45.8806 37.6508 41.0109 38.0056 37.4755 35.5774C37.0237 35.2615 36.5937 34.9067 36.1926 34.5058C35.9862 34.3 35.7916 34.0877 35.6083 33.8641C35.2415 33.4177 34.9203 32.9415 34.6571 32.4496C34.1935 31.6186 33.8782 30.7369 33.706 29.8255C33.1507 26.9674 33.9811 23.891 36.1926 21.6804C38.4095 19.4633 41.4864 18.6383 44.3451 19.1883C45.256 19.3657 46.1385 19.6809 46.9695 20.1393C47.4623 20.4083 47.9319 20.7295 48.3791 21.0961C48.602 21.2788 48.8144 21.474 49.0202 21.6804C49.4219 22.0814 49.782 22.5107 50.092 22.9631C52.5212 26.4972 52.1657 31.3667 49.0261 34.5058Z"
            fill="white"
            fillOpacity="0.1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.1517 33.8907C44.8792 37.1639 39.5758 37.1633 36.3027 33.8907C33.0338 30.6219 33.0338 25.3179 36.3069 22.0454C39.5758 18.7765 44.8792 18.7765 48.148 22.0454C51.4205 25.3179 51.4205 30.6219 48.1517 33.8907ZM49.8285 20.3614C45.628 16.1608 38.8211 16.1608 34.6205 20.3614C30.4206 24.5613 30.4169 31.3724 34.6169 35.573C38.4399 39.3948 44.432 39.7395 48.6465 36.6015C49.0582 36.2946 49.4546 35.9506 49.8328 35.573C50.2103 35.1948 50.5544 34.7984 50.8607 34.3868C53.9986 30.1716 53.6509 24.1838 49.8285 20.3614Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.3575 51.4077L66.1812 51.5841C65.0145 52.7514 63.104 52.7514 61.9367 51.5841L51.1641 40.8115L55.5849 36.3906L66.3575 47.1632C67.5248 48.3305 67.5248 50.2404 66.3575 51.4077Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.0953 34.0547L54.2786 37.238L52.0187 39.4974L48.8359 36.314C49.256 36.0009 49.6599 35.6498 50.0464 35.2645C50.4317 34.8793 50.7827 34.4747 51.0953 34.0547Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.1991 47.0258L61.8064 51.4179L61.0156 50.6265L65.4077 46.2344L66.1991 47.0258Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.832 36.5376L51.319 41.0507L50.6484 40.3803L55.1614 35.8672L55.832 36.5376Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9846 20.9174L34.9169 31.9846C34.5001 31.2376 34.2167 30.4449 34.0625 29.6256L43.6254 20.0625C44.4442 20.2226 45.2375 20.5054 45.9846 20.9174Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.6113 23.5463L37.6459 34.8338C37.2174 34.5517 36.8096 34.2342 36.4292 33.8754C36.2334 33.6907 36.0483 33.5013 35.875 33.3012L47.9862 21.875C48.1982 22.0391 48.3997 22.2137 48.5948 22.3985C48.9752 22.7573 49.3173 23.1414 49.6113 23.5463Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export const noResultBig = (
    <svg
        width="200"
        height="168"
        viewBox="0 0 200 168"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M103.867 2V3.42853" stroke="#F1F2F7" strokeWidth="2.921" strokeLinecap="round" />
        <path
            d="M99.582 6.28906H101.011"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path
            d="M103.867 10.5692V9.14062"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path
            d="M107.436 6.28906H106.008"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path d="M21.0117 62V64.1428" stroke="#F1F2F7" strokeWidth="2.921" strokeLinecap="round" />
        <path
            d="M14.582 68.4297H16.7248"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path d="M21.0117 74.1428V72" stroke="#F1F2F7" strokeWidth="2.921" strokeLinecap="round" />
        <path
            d="M26.7287 68.4297H24.5859"
            stroke="#F1F2F7"
            strokeWidth="2.921"
            strokeLinecap="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188.906 111.826C190.742 111.254 193.555 109.009 194.207 106.594C194.797 108.729 197.273 111.254 199.507 111.445C196.992 112.335 194.588 114.98 194.207 117.058C193.947 114.94 190.622 112.135 188.906 111.826Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.492188 138.921C1.41025 138.634 2.81644 137.512 3.14207 136.305C3.43737 137.372 4.675 138.634 5.79195 138.73C4.53492 139.175 3.33308 140.498 3.14207 141.537C3.0123 140.478 1.34961 139.075 0.492188 138.921Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5284 39.5617C17.5284 40.8864 16.4551 41.9594 15.1314 41.9594C13.8071 41.9594 12.7344 40.8864 12.7344 39.5617C12.7344 38.2376 13.8071 37.1641 15.1314 37.1641C16.4551 37.1641 17.5284 38.2376 17.5284 39.5617Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M177.591 46.0074C177.591 48.2207 175.797 50.0148 173.585 50.0148C171.372 50.0148 169.578 48.2207 169.578 46.0074C169.578 43.7941 171.372 42 173.585 42C175.797 42 177.591 43.7941 177.591 46.0074Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.8477 164.171C53.8477 165.927 52.4251 167.35 50.6702 167.35C48.9148 167.35 47.4922 165.927 47.4922 164.171C47.4922 162.415 48.9148 160.992 50.6702 160.992C52.4251 160.992 53.8477 162.415 53.8477 164.171Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M126.562 124.568H67.9894C63.8668 124.568 60.4922 121.184 60.4922 117.047V33.5194C60.4922 29.3829 63.8668 26 67.9894 26H126.562C130.687 26 134.061 29.3829 134.061 33.5194V117.047C134.061 121.184 130.687 124.568 126.562 124.568Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.562 128.139H62.9894C58.8668 128.139 55.4922 124.754 55.4922 120.618V37.0897C55.4922 32.9532 58.8668 29.5703 62.9894 29.5703H121.562C125.687 29.5703 129.061 32.9532 129.061 37.0897V120.618C129.061 124.754 125.687 128.139 121.562 128.139Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M116.699 130.957H58.0049C53.8738 130.957 50.4922 127.587 50.4922 123.467V40.2851C50.4922 36.1658 53.8738 32.7969 58.0049 32.7969H116.699C120.832 32.7969 124.213 36.1658 124.213 40.2851V123.467C124.213 127.587 120.832 130.957 116.699 130.957Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M116.699 130.957H58.0049C53.8738 130.957 50.4922 127.587 50.4922 123.467V40.2851C50.4922 36.1658 53.8738 32.7969 58.0049 32.7969H116.699C120.832 32.7969 124.213 36.1658 124.213 40.2851V123.467C124.213 127.587 120.832 130.957 116.699 130.957Z"
            fill="#F1F2F7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.237 58.145H64.8843C63.6462 58.145 62.6328 57.1808 62.6328 56.003C62.6328 54.8235 63.6462 53.8594 64.8843 53.8594H108.237C109.475 53.8594 110.488 54.8235 110.488 56.003C110.488 57.1808 109.475 58.145 108.237 58.145Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.237 70.9965H64.8843C63.6462 70.9965 62.6328 70.0324 62.6328 68.8545C62.6328 67.6751 63.6462 66.7109 64.8843 66.7109H108.237C109.475 66.7109 110.488 67.6751 110.488 68.8545C110.488 70.0324 109.475 70.9965 108.237 70.9965Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.237 83.8559H64.8843C63.6462 83.8559 62.6328 82.8918 62.6328 81.7139C62.6328 80.5361 63.6462 79.5703 64.8843 79.5703H108.237C109.475 79.5703 110.488 80.5361 110.488 81.7139C110.488 82.8918 109.475 83.8559 108.237 83.8559Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.237 96.7153H64.8843C63.6462 96.7153 62.6328 95.7511 62.6328 94.5733C62.6328 93.3938 63.6462 92.4297 64.8843 92.4297H108.237C109.475 92.4297 110.488 93.3938 110.488 94.5733C110.488 95.7511 109.475 96.7153 108.237 96.7153Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M87.5137 109.567H64.8939C63.6506 109.567 62.6328 108.603 62.6328 107.425C62.6328 106.245 63.6506 105.281 64.8939 105.281H87.5137C88.7571 105.281 89.7748 106.245 89.7748 107.425C89.7748 108.603 88.7571 109.567 87.5137 109.567Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M133.173 92.9568C124.504 101.624 111.084 102.602 101.341 95.9102C100.096 95.0396 98.9104 94.0618 97.8052 92.9568C97.2363 92.3898 96.7 91.8046 96.1947 91.1885C95.184 89.9581 94.2988 88.6459 93.5735 87.2901C92.2956 85.0001 91.4268 82.5702 90.9523 80.0585C89.4218 72.1818 91.7103 63.7035 97.8052 57.6115C103.915 51.5013 112.394 49.2277 120.272 50.7434C122.783 51.2323 125.215 52.101 127.505 53.3641C128.863 54.1057 130.157 54.9907 131.39 56.0012C132.004 56.5047 132.589 57.0426 133.157 57.6115C134.264 58.7165 135.256 59.8996 136.11 61.1464C142.805 70.886 141.825 84.3059 133.173 92.9568Z"
            fill="white"
            fillOpacity="0.1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.746 91.2526C121.727 100.273 107.111 100.271 98.091 91.2526C89.0823 82.244 89.0823 67.6268 98.1027 58.608C107.111 49.5994 121.727 49.5994 130.736 58.608C139.754 67.6268 139.754 82.244 130.746 91.2526ZM135.375 53.9713C123.799 42.395 105.04 42.395 93.4634 53.9713C81.8887 65.5459 81.8787 84.3168 93.4533 95.8931C103.989 106.426 120.503 107.375 132.118 98.7276C133.252 97.8817 134.345 96.9336 135.387 95.8931C136.427 94.8509 137.375 93.7584 138.22 92.6239C146.867 81.0073 145.909 64.5054 135.375 53.9713Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M180.934 139.526L180.448 140.012C177.233 143.229 171.968 143.229 168.751 140.012L139.062 110.324L151.246 98.1406L180.934 127.829C184.151 131.046 184.151 136.309 180.934 139.526Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138.859 91.7109L147.632 100.484L141.404 106.71L132.633 97.9375C133.79 97.0744 134.904 96.1069 135.969 95.0451C137.03 93.9834 137.998 92.8686 138.859 91.7109Z"
            fill="#E0E2EE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M180.488 127.462L168.383 139.567L166.203 137.385L178.307 125.281L180.488 127.462Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M151.918 98.5586L139.481 110.996L137.633 109.149L150.07 96.7109L151.918 98.5586Z"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M124.778 55.4967L94.2764 85.9967C93.1278 83.938 92.3467 81.7535 91.9219 79.4955L118.276 53.1406C120.533 53.5818 122.719 54.3611 124.778 55.4967Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M134.778 62.7465L101.802 93.8538C100.621 93.0764 99.4974 92.2014 98.4493 91.2126C97.9097 90.7035 97.3994 90.1815 96.9219 89.6301L130.299 58.1406C130.883 58.5928 131.439 59.0742 131.976 59.5832C133.025 60.572 133.968 61.6308 134.778 62.7465Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export const upload = (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.7891 6.16406H22.2355L21.1507 3.12388C21.0757 2.91585 20.9384 2.73603 20.7574 2.60899C20.5764 2.48194 20.3606 2.41387 20.1395 2.41406H9.86719C9.41518 2.41406 9.01004 2.69866 8.85938 3.12388L7.77121 6.16406H3.21763C1.73772 6.16406 0.539062 7.36272 0.539062 8.84264V24.1105C0.539062 25.5904 1.73772 26.7891 3.21763 26.7891H26.7891C28.269 26.7891 29.4676 25.5904 29.4676 24.1105V8.84264C29.4676 7.36272 28.269 6.16406 26.7891 6.16406ZM27.0569 24.1105C27.0569 24.2578 26.9364 24.3784 26.7891 24.3784H3.21763C3.07031 24.3784 2.94978 24.2578 2.94978 24.1105V8.84264C2.94978 8.69531 3.07031 8.57478 3.21763 8.57478H9.46875L10.0413 6.97433L10.808 4.82478H19.1953L19.9621 6.97433L20.5346 8.57478H26.7891C26.9364 8.57478 27.0569 8.69531 27.0569 8.84264V24.1105Z"
            fill="#157FFB"
        />
        <path
            d="M14.0686 12.6908V15.5335H11.2394C11.1021 15.5335 10.9883 15.6373 10.9883 15.7679V17.1741C10.9883 17.3013 11.1021 17.4085 11.2394 17.4085H14.0686V20.2511C14.0686 20.3817 14.1758 20.4888 14.303 20.4888H15.7093C15.8398 20.4888 15.9436 20.3817 15.9436 20.2511V17.4085H18.7729C18.9102 17.4085 19.024 17.3013 19.024 17.1741V15.7679C19.024 15.6373 18.9102 15.5335 18.7729 15.5335H15.9436V12.6908C15.9436 12.5603 15.8398 12.4531 15.7093 12.4531H14.303C14.1758 12.4531 14.0686 12.5603 14.0686 12.6908Z"
            fill="#157FFB"
        />
        <circle cx="5.41406" cy="11.25" r="1.25" fill="#157FFB" />
    </svg>
);

export const close = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.8095 7L11.9111 2.11094C11.9798 2.02969 11.922 1.90625 11.8158 1.90625H10.5689C10.4954 1.90625 10.4251 1.93906 10.3767 1.99531L6.99388 6.02813L3.61106 1.99531C3.56419 1.93906 3.49388 1.90625 3.41888 1.90625H2.172C2.06575 1.90625 2.00794 2.02969 2.07669 2.11094L6.17825 7L2.07669 11.8891C2.06129 11.9072 2.05141 11.9293 2.04822 11.9529C2.04503 11.9764 2.04867 12.0004 2.05871 12.022C2.06874 12.0435 2.08475 12.0617 2.10483 12.0745C2.12492 12.0872 2.14823 12.0939 2.172 12.0938H3.41888C3.49231 12.0938 3.56263 12.0609 3.61106 12.0047L6.99388 7.97188L10.3767 12.0047C10.4236 12.0609 10.4939 12.0938 10.5689 12.0938H11.8158C11.922 12.0938 11.9798 11.9703 11.9111 11.8891L7.8095 7Z"
            fill="#7A829E"
        />
    </svg>
);

export const user = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.187 12.4906C13.8501 11.6927 13.3613 10.968 12.7477 10.3567C12.136 9.74368 11.4113 9.25492 10.6138 8.91741C10.6066 8.91384 10.5995 8.91206 10.5923 8.90848C11.7048 8.10491 12.4281 6.79598 12.4281 5.3192C12.4281 2.87277 10.4459 0.890625 7.99949 0.890625C5.55306 0.890625 3.57091 2.87277 3.57091 5.3192C3.57091 6.79598 4.29413 8.10491 5.40663 8.91027C5.39949 8.91384 5.39234 8.91563 5.3852 8.9192C4.5852 9.2567 3.86734 9.74063 3.25127 10.3585C2.63825 10.9702 2.14949 11.6949 1.81199 12.4924C1.48042 13.2732 1.3016 14.1104 1.2852 14.9585C1.28472 14.9775 1.28807 14.9965 1.29503 15.0143C1.302 15.032 1.31245 15.0482 1.32576 15.0618C1.33907 15.0755 1.35498 15.0863 1.37255 15.0937C1.39012 15.1011 1.40899 15.1049 1.42806 15.1049H2.49949C2.57806 15.1049 2.64056 15.0424 2.64234 14.9656C2.67806 13.5871 3.23163 12.296 4.2102 11.3174C5.2227 10.3049 6.56734 9.74777 7.99949 9.74777C9.43163 9.74777 10.7763 10.3049 11.7888 11.3174C12.7673 12.296 13.3209 13.5871 13.3566 14.9656C13.3584 15.0442 13.4209 15.1049 13.4995 15.1049H14.5709C14.59 15.1049 14.6089 15.1011 14.6264 15.0937C14.644 15.0863 14.6599 15.0755 14.6732 15.0618C14.6865 15.0482 14.697 15.032 14.7039 15.0143C14.7109 14.9965 14.7142 14.9775 14.7138 14.9585C14.6959 14.1049 14.5191 13.2746 14.187 12.4906ZM7.99949 8.39063C7.17984 8.39063 6.40841 8.07098 5.82806 7.49063C5.2477 6.91027 4.92806 6.13884 4.92806 5.3192C4.92806 4.49955 5.2477 3.72813 5.82806 3.14777C6.40841 2.56741 7.17984 2.24777 7.99949 2.24777C8.81913 2.24777 9.59056 2.56741 10.1709 3.14777C10.7513 3.72813 11.0709 4.49955 11.0709 5.3192C11.0709 6.13884 10.7513 6.91027 10.1709 7.49063C9.59056 8.07098 8.81913 8.39063 7.99949 8.39063Z"
            fill="#4B5167"
        />
    </svg>
);

export const serverError = (
    <svg
        width="596"
        height="220"
        viewBox="0 0 596 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.15"
            d="M86.3926 219.809C138.98 219.809 174.576 189.34 174.576 143.344V143.051C174.576 103.793 145.865 75.5215 106.168 75.5215C83.7559 75.5215 66.6172 85.3359 58.8535 99.5449H57.6816L60.9043 51.498H160.953V4.62305H12.2715L3.77539 132.357H57.3887C59.5859 128.256 63.1016 124.301 67.6426 121.371C72.623 118.295 78.9219 116.244 86.2461 116.244C103.824 116.244 116.422 127.523 116.422 144.223V144.516C116.422 161.215 103.971 172.934 86.3926 172.934C71.0117 172.934 60.1719 164.291 56.5098 153.451L56.2168 152.572H0.40625L0.552734 155.209C2.60352 191.537 34.6836 219.809 86.3926 219.809ZM289.42 219.809C348.014 219.809 383.902 177.768 383.902 110.385V110.092C383.902 42.709 348.014 0.814453 289.42 0.814453C230.826 0.814453 194.645 42.709 194.645 110.092V110.385C194.645 177.768 230.826 219.809 289.42 219.809ZM289.42 172.055C268.912 172.055 257.633 150.229 257.633 110.385V110.092C257.633 70.248 268.912 48.5684 289.42 48.5684C309.928 48.5684 321.061 70.248 321.061 110.092V110.385C321.061 150.229 309.928 172.055 289.42 172.055ZM500.943 219.809C559.537 219.809 595.426 177.768 595.426 110.385V110.092C595.426 42.709 559.537 0.814453 500.943 0.814453C442.35 0.814453 406.168 42.709 406.168 110.092V110.385C406.168 177.768 442.35 219.809 500.943 219.809ZM500.943 172.055C480.436 172.055 469.156 150.229 469.156 110.385V110.092C469.156 70.248 480.436 48.5684 500.943 48.5684C521.451 48.5684 532.584 70.248 532.584 110.092V110.385C532.584 150.229 521.451 172.055 500.943 172.055Z"
            fill="url(#paint0_linear_805_56955)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_805_56955"
                x1="295.5"
                y1="-70"
                x2="295.5"
                y2="288"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7189CE" />
                <stop offset="1" stopColor="#7189CE" stopOpacity="0.1" />
            </linearGradient>
        </defs>
    </svg>
);

export const forbidden = (
    <svg
        width="599"
        height="220"
        viewBox="0 0 599 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.15"
            d="M104.672 216H163.266V182.455H189.193V134.848H163.266V4.62305H77.4258L0.667969 132.504V182.455H104.672V216ZM51.498 137.338V135.58L105.258 46.0781H106.43V137.338H51.498ZM302.572 219.809C361.166 219.809 397.055 177.768 397.055 110.385V110.092C397.055 42.709 361.166 0.814453 302.572 0.814453C243.979 0.814453 207.797 42.709 207.797 110.092V110.385C207.797 177.768 243.979 219.809 302.572 219.809ZM302.572 172.055C282.064 172.055 270.785 150.229 270.785 110.385V110.092C270.785 70.248 282.064 48.5684 302.572 48.5684C323.08 48.5684 334.213 70.248 334.213 110.092V110.385C334.213 150.229 323.08 172.055 302.572 172.055ZM506.918 219.809C563.314 219.809 598.031 194.906 598.031 154.77V154.477C598.031 127.084 577.963 109.945 545.883 106.576V105.404C569.027 100.863 589.242 84.3105 589.242 57.6504V57.3574C589.242 22.9336 557.162 0.814453 506.479 0.814453C457.26 0.814453 425.033 25.8633 422.396 64.6816L422.25 66.8789H478.207L478.354 65.5605C479.672 54.1348 489.926 46.0781 506.479 46.0781C522.299 46.0781 532.113 53.6953 532.113 66V66.293C532.113 79.3301 520.98 87.2402 501.791 87.2402H479.965V128.109H501.791C523.324 128.109 534.896 135.873 534.896 150.229V150.521C534.896 162.826 524.35 171.615 506.771 171.615C489.193 171.615 478.207 163.412 476.596 154.037L476.303 152.279H417.123L417.27 154.184C420.346 193.734 454.916 219.809 506.918 219.809Z"
            fill="url(#paint0_linear_805_56971)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_805_56971"
                x1="300"
                y1="-70"
                x2="300"
                y2="288"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7189CE" />
                <stop offset="1" stopColor="#7189CE" stopOpacity="0.1" />
            </linearGradient>
        </defs>
    </svg>
);

export const notFound = (
    <svg
        width="607"
        height="220"
        viewBox="0 0 607 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.15"
            d="M104.672 216H163.266V182.455H189.193V134.848H163.266V4.62305H77.4258L0.667969 132.504V182.455H104.672V216ZM51.498 137.338V135.58L105.258 46.0781H106.43V137.338H51.498ZM302.572 219.809C361.166 219.809 397.055 177.768 397.055 110.385V110.092C397.055 42.709 361.166 0.814453 302.572 0.814453C243.979 0.814453 207.797 42.709 207.797 110.092V110.385C207.797 177.768 243.979 219.809 302.572 219.809ZM302.572 172.055C282.064 172.055 270.785 150.229 270.785 110.385V110.092C270.785 70.248 282.064 48.5684 302.572 48.5684C323.08 48.5684 334.213 70.248 334.213 110.092V110.385C334.213 150.229 323.08 172.055 302.572 172.055ZM521.859 216H580.453V182.455H606.381V134.848H580.453V4.62305H494.613L417.855 132.504V182.455H521.859V216ZM468.686 137.338V135.58L522.445 46.0781H523.617V137.338H468.686Z"
            fill="url(#paint0_linear_803_68834)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_803_68834"
                x1="303.5"
                y1="-70"
                x2="303.5"
                y2="288"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7189CE" />
                <stop offset="1" stopColor="#7189CE" stopOpacity="0.1" />
            </linearGradient>
        </defs>
    </svg>
);

export const universalError = (
    <svg
        width="547"
        height="643"
        viewBox="0 0 547 643"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="329.859"
            y="61.2031"
            width="6.85698"
            height="61.7128"
            transform="rotate(13 329.859 61.2031)"
            fill="#E0E2EE"
        />
        <ellipse
            cx="316.378"
            cy="142.516"
            rx="80.5695"
            ry="58.2843"
            transform="rotate(13 316.378 142.516)"
            fill="#E0E2EE"
        />
        <ellipse
            cx="303.52"
            cy="190.573"
            rx="215.995"
            ry="77.141"
            transform="rotate(13 303.52 190.573)"
            fill="#E8EBF2"
        />
        <ellipse
            cx="301.965"
            cy="197.244"
            rx="215.995"
            ry="77.141"
            transform="rotate(13 301.965 197.244)"
            fill="#F1F2F7"
        />
        <ellipse
            cx="289.382"
            cy="236.567"
            rx="85.7123"
            ry="29.1422"
            transform="rotate(13 289.382 236.567)"
            fill="white"
        />
        <circle
            cx="333.982"
            cy="58.609"
            r="10.2855"
            transform="rotate(13 333.982 58.609)"
            fill="#E8EBF2"
        />
        <circle
            cx="461.426"
            cy="207.666"
            r="13.714"
            transform="rotate(13 461.426 207.666)"
            fill="#E8EBF2"
        />
        <circle
            cx="262.27"
            cy="140.58"
            r="13.714"
            transform="rotate(13 262.27 140.58)"
            fill="#E8EBF2"
        />
        <circle
            cx="365.824"
            cy="164.494"
            r="13.714"
            transform="rotate(13 365.824 164.494)"
            fill="#E8EBF2"
        />
        <circle
            cx="157.426"
            cy="137.487"
            r="13.714"
            transform="rotate(13 157.426 137.487)"
            fill="#E8EBF2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M372.343 257.472C372.565 256.94 372.742 256.393 372.872 255.831C376.493 240.148 342.037 218.803 295.912 208.154C249.788 197.506 209.462 201.586 205.841 217.269C205.712 217.831 205.631 218.4 205.598 218.976L205.471 218.947L33.7948 552.771L334.451 622.183L372.502 257.509L372.343 257.472Z"
            fill="url(#paint0_linear_4893_177274)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_4893_177274"
                x1="353.5"
                y1="482.5"
                x2="430.808"
                y2="216.941"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E8EBF2" stopOpacity="0" />
                <stop offset="1" stopColor="#E8EBF2" />
            </linearGradient>
        </defs>
    </svg>
);

export const noConnection = (
    <svg
        width="432"
        height="432"
        viewBox="0 0 432 432"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_1951_87476"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="432"
            height="432"
        >
            <rect width="432" height="432" fill="black" />
        </mask>
        <g mask="url(#mask0_1951_87476)">
            <path
                d="M441.843 397.881C452.263 377.262 422.484 274.164 216.009 274.164C66.9761 274.164 -1.42612 358.551 13.6962 401.318"
                fill="url(#paint0_linear_1951_87476)"
            />
            <mask
                id="mask1_1951_87476"
                maskUnits="userSpaceOnUse"
                mask-type="alpha"
                x="11"
                y="274"
                width="433"
                height="128"
            >
                <path
                    d="M441.843 397.881C452.263 377.262 422.484 274.164 216.009 274.164C66.9761 274.164 -1.42612 358.551 13.6962 401.318"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask1_1951_87476)">
                <ellipse
                    opacity="0.4"
                    cx="184.168"
                    cy="331.987"
                    rx="11.3551"
                    ry="6.30722"
                    fill="#E0E2EE"
                />
                <ellipse
                    opacity="0.4"
                    cx="305.369"
                    cy="347.136"
                    rx="17.9159"
                    ry="9.83926"
                    fill="#E0E2EE"
                />
                <ellipse
                    opacity="0.5"
                    cx="96.1737"
                    cy="296.105"
                    rx="17.9159"
                    ry="9.83926"
                    fill="#E8E9F2"
                />
            </g>
            <path
                opacity="0.5"
                d="M185.823 288.675C178.86 296.555 206.415 303.835 257.109 305.545C312.312 320.585 337.399 324.665 347.539 316.891C359.679 285.865 313.198 283.678 280.079 277.926C244.798 273.598 227.267 274.499 206.374 280.007C203.545 280.753 204.948 280.182 198.53 282.337C192.112 284.493 187.516 286.759 185.823 288.675Z"
                fill="url(#paint1_linear_1951_87476)"
            />
            <path
                d="M267.235 241.468C267.235 241.468 245.863 265.277 258.947 279.957C249.125 288.96 231.314 288.96 231.314 288.96L222.24 256.236L267.235 241.468Z"
                fill="#E0E2EE"
            />
            <path
                d="M231.972 287.169C231.972 287.169 215.34 280.069 208.693 280.058C202.045 280.046 222.61 255.179 222.61 255.179L234.627 284.89L231.972 287.169Z"
                fill="#E0E2EE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M102.531 252.68C145.999 295.638 216.15 295.393 259.533 252.01C302.915 208.628 303.161 138.477 260.202 95.0088L102.531 252.68Z"
                fill="#F1F2F7"
            />
            <ellipse
                cx="181.837"
                cy="175.258"
                rx="24.6396"
                ry="111.49"
                transform="rotate(45 181.837 175.258)"
                fill="#E8EBF2"
            />
            <mask
                id="mask2_1951_87476"
                maskUnits="userSpaceOnUse"
                mask-type="alpha"
                x="101"
                y="94"
                width="162"
                height="163"
            >
                <ellipse
                    cx="181.837"
                    cy="175.258"
                    rx="24.6396"
                    ry="111.49"
                    transform="rotate(45 181.837 175.258)"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask2_1951_87476)">
                <path d="M213.939 182.351L168.75 160.581L186.192 205.797" fill="#E0E2EE" />
            </g>
            <ellipse
                cx="164.415"
                cy="156.898"
                rx="15.9272"
                ry="15.9824"
                transform="rotate(-45 164.415 156.898)"
                fill="#F1F2F7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M113.784 144.23C114.564 144.231 115.257 143.886 115.776 143.366C116.382 142.761 116.684 141.937 116.639 141.027C116.148 132.401 119.386 123.954 125.487 117.852C131.502 111.837 139.863 108.599 148.402 109.003C150.093 109.049 151.565 107.751 151.605 106.061C151.646 104.371 150.343 102.895 148.652 102.849C138.422 102.399 128.373 106.284 121.146 113.51C113.832 120.824 109.991 131.004 110.528 141.321C110.661 143.011 112.093 144.357 113.784 144.23ZM132.715 149.911C133.466 149.803 134.121 149.515 134.593 149.043C135.194 148.442 135.562 147.523 135.44 146.542C134.841 141.996 136.289 137.608 139.378 134.519C142.425 131.472 146.724 130.022 151.178 130.529C152.872 130.764 154.383 129.529 154.457 127.801C154.577 126.119 153.236 124.52 151.499 124.327C145.218 123.625 139.121 125.68 134.83 129.971C130.41 134.39 128.359 140.577 129.199 146.995C129.434 148.689 130.987 149.985 132.715 149.911Z"
                fill="#F1F2F7"
            />
            <path
                d="M92.2727 140.513C91.7556 141.03 91.0669 141.376 90.2497 141.42C88.5724 141.466 87.1987 140.179 87.0729 138.502C86.5433 123.236 92.4638 108.302 103.279 97.4867C108.741 92.0247 115.114 87.845 122.06 85.1016C122.605 84.8188 127.257 84.162 127.925 86.1575C128.594 88.153 124.139 90.8812 124.139 90.8812C118.031 93.323 112.423 97.0127 107.614 101.822C98.0054 111.431 92.7761 124.729 93.2229 138.361C93.1785 139.092 92.8328 139.953 92.2727 140.513Z"
                fill="#F1F2F7"
            />
            <mask
                id="mask3_1951_87476"
                maskUnits="userSpaceOnUse"
                mask-type="alpha"
                x="87"
                y="84"
                width="41"
                height="58"
            >
                <path
                    d="M92.2727 140.513C91.7556 141.03 91.0669 141.376 90.2497 141.42C88.5724 141.466 87.1987 140.179 87.0729 138.502C86.5433 123.236 92.4638 108.302 103.279 97.4867C108.741 92.0247 115.114 87.845 122.06 85.1016C122.605 84.8188 127.257 84.162 127.925 86.1575C128.594 88.153 124.139 90.8812 124.139 90.8812C118.031 93.323 112.423 97.0127 107.614 101.822C98.0054 111.431 92.7761 124.729 93.2229 138.361C93.1785 139.092 92.8328 139.953 92.2727 140.513Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask3_1951_87476)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M152.266 77.524C151.35 76.6079 149.865 76.6079 148.949 77.524L142.869 83.6041L136.787 77.5224C135.871 76.6064 134.386 76.6064 133.47 77.5224C132.554 78.4385 132.554 79.9237 133.47 80.8397L139.551 86.9214L133.468 93.0048C132.552 93.9209 132.552 95.4061 133.468 96.3221C134.384 97.2382 135.869 97.2382 136.785 96.3221L142.869 90.2387L148.951 96.3206C149.867 97.2366 151.352 97.2366 152.268 96.3206C153.184 95.4045 153.184 93.9193 152.268 93.0032L146.186 86.9214L152.266 80.8413C153.182 79.9253 153.182 78.44 152.266 77.524Z"
                fill="#E0E2EE"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_1951_87476"
                x1="199"
                y1="392.191"
                x2="199"
                y2="244.331"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F1F2F7" stopOpacity="0" />
                <stop offset="0.567708" stopColor="#E8EBF2" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1951_87476"
                x1="211.5"
                y1="282.5"
                x2="178"
                y2="280"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E0E2EE" />
                <stop offset="1" stopColor="#E0E2EE" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const ratingStar = (color?: string) => (
    <svg width="14" height="14" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.7145 10.1361L33.9158 11.6187C34.6189 11.7231 35.1091 12.374 35.0006 13.0771C34.9605 13.3584 34.8279 13.6155 34.627 13.8124L27.2462 21.0084L28.9899 31.1695C29.1105 31.8687 28.6404 32.5316 27.9413 32.6521C27.664 32.7003 27.3747 32.6562 27.1256 32.5236L18.0011 27.7262L8.87653 32.5236C8.6644 32.6356 8.42514 32.6859 8.18586 32.6688C7.94659 32.6518 7.71688 32.568 7.52279 32.427C7.3287 32.2861 7.17799 32.0935 7.08775 31.8713C6.99751 31.649 6.97136 31.4059 7.01225 31.1695L8.756 21.0084L1.37519 13.8124C1.13079 13.5742 0.990962 13.2486 0.986441 12.9073C0.981921 12.5661 1.11308 12.2369 1.35109 11.9923C1.54796 11.7914 1.8051 11.6588 2.08635 11.6187L12.2877 10.1361L16.848 0.890975C17.1654 0.256153 17.9368 -0.00500745 18.5716 0.308385C18.8247 0.432939 19.0297 0.63785 19.1542 0.890975L23.7145 10.1361Z"
            fill={color || '#FFF'}
        />
    </svg>
);

export const arrowLeft = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.6244 6.40625H3.48225L8.95412 1.65625C9.04162 1.57969 8.9885 1.4375 8.87287 1.4375H7.49006C7.42912 1.4375 7.37131 1.45937 7.326 1.49844L1.42131 6.62188C1.36723 6.66875 1.32386 6.72671 1.29413 6.79181C1.26441 6.85692 1.24902 6.92765 1.24902 6.99922C1.24902 7.07079 1.26441 7.14152 1.29413 7.20663C1.32386 7.27173 1.36723 7.32969 1.42131 7.37656L7.36037 12.5313C7.38381 12.5516 7.41193 12.5625 7.44162 12.5625H8.87131C8.98694 12.5625 9.04006 12.4188 8.95256 12.3438L3.48225 7.59375H12.6244C12.6932 7.59375 12.7494 7.5375 12.7494 7.46875V6.53125C12.7494 6.4625 12.6932 6.40625 12.6244 6.40625Z"
            fill="#4B5167"
        />
    </svg>
);

export const arrowLeftBig = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.6413 8.9788H3.96901L13.3494 0.835938C13.4994 0.704688 13.4083 0.460938 13.2101 0.460938H10.8395C10.7351 0.460938 10.636 0.498437 10.5583 0.565402L0.435973 9.34844C0.343264 9.4288 0.268912 9.52815 0.217955 9.63976C0.166997 9.75137 0.140625 9.87262 0.140625 9.99531C0.140625 10.118 0.166997 10.2393 0.217955 10.3509C0.268912 10.4625 0.343264 10.5618 0.435973 10.6422L10.6172 19.4788C10.6574 19.5136 10.7056 19.5324 10.7565 19.5324H13.2074C13.4056 19.5324 13.4967 19.2859 13.3467 19.1574L3.96901 11.0145H19.6413C19.7592 11.0145 19.8556 10.9181 19.8556 10.8002V9.19308C19.8556 9.07522 19.7592 8.9788 19.6413 8.9788Z"
            fill="#C4C8D4"
        />
    </svg>
);

export const arrowRight = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.5781 6.62188L6.675 1.49844C6.62969 1.45937 6.57188 1.4375 6.51094 1.4375H5.12813C5.0125 1.4375 4.95938 1.58125 5.04688 1.65625L10.5188 6.40625H1.375C1.30625 6.40625 1.25 6.4625 1.25 6.53125V7.46875C1.25 7.5375 1.30625 7.59375 1.375 7.59375H10.5172L5.04531 12.3438C4.95781 12.4203 5.01094 12.5625 5.12656 12.5625H6.55625C6.58594 12.5625 6.61563 12.5516 6.6375 12.5313L12.5781 7.37813C12.6322 7.33109 12.6756 7.27299 12.7053 7.20776C12.735 7.14253 12.7504 7.07168 12.7504 7C12.7504 6.92832 12.735 6.85747 12.7053 6.79224C12.6756 6.72701 12.6322 6.66891 12.5781 6.62188Z"
            fill="#4B5167"
        />
    </svg>
);

export const arrowRightBig = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.5642 9.34844L9.44453 0.565402C9.36685 0.498437 9.26775 0.460938 9.16328 0.460938H6.79275C6.59453 0.460938 6.50346 0.707366 6.65346 0.835938L16.0338 8.9788H0.358817C0.24096 8.9788 0.144531 9.07522 0.144531 9.19308V10.8002C0.144531 10.9181 0.24096 11.0145 0.358817 11.0145H16.0311L6.65078 19.1574C6.50078 19.2886 6.59185 19.5324 6.79007 19.5324H9.24096C9.29185 19.5324 9.34275 19.5136 9.38025 19.4788L19.5642 10.6449C19.6569 10.5642 19.7313 10.4646 19.7822 10.3528C19.8332 10.241 19.8595 10.1195 19.8595 9.99665C19.8595 9.87377 19.8332 9.75231 19.7822 9.64049C19.7313 9.52866 19.6569 9.42907 19.5642 9.34844Z"
            fill="#C4C8D4"
        />
    </svg>
);

export const calendar = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8775_16284)">
            <path
                d="M12.75 1.875H10.125V0.875C10.125 0.80625 10.0688 0.75 10 0.75H9.125C9.05625 0.75 9 0.80625 9 0.875V1.875H5V0.875C5 0.80625 4.94375 0.75 4.875 0.75H4C3.93125 0.75 3.875 0.80625 3.875 0.875V1.875H1.25C0.973438 1.875 0.75 2.09844 0.75 2.375V12.75C0.75 13.0266 0.973438 13.25 1.25 13.25H12.75C13.0266 13.25 13.25 13.0266 13.25 12.75V2.375C13.25 2.09844 13.0266 1.875 12.75 1.875ZM12.125 12.125H1.875V6.1875H12.125V12.125ZM1.875 5.125V3H3.875V3.75C3.875 3.81875 3.93125 3.875 4 3.875H4.875C4.94375 3.875 5 3.81875 5 3.75V3H9V3.75C9 3.81875 9.05625 3.875 9.125 3.875H10C10.0688 3.875 10.125 3.81875 10.125 3.75V3H12.125V5.125H1.875Z"
                fill="#4B5167"
            />
        </g>
        <defs>
            <clipPath id="clip0_8775_16284">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const edit = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8775_16409)">
            <path
                d="M3.02656 10.75C3.05781 10.75 3.08906 10.7469 3.12031 10.7422L5.74844 10.2813C5.77969 10.275 5.80938 10.2609 5.83125 10.2375L12.4547 3.61406C12.4692 3.59961 12.4807 3.58244 12.4885 3.56354C12.4963 3.54463 12.5004 3.52437 12.5004 3.50391C12.5004 3.48344 12.4963 3.46318 12.4885 3.44428C12.4807 3.42538 12.4692 3.40821 12.4547 3.39375L9.85781 0.795313C9.82813 0.765625 9.78906 0.75 9.74688 0.75C9.70469 0.75 9.66563 0.765625 9.63594 0.795313L3.0125 7.41875C2.98906 7.44219 2.975 7.47031 2.96875 7.50156L2.50781 10.1297C2.49261 10.2134 2.49804 10.2995 2.52364 10.3807C2.54923 10.4618 2.59421 10.5355 2.65469 10.5953C2.75781 10.6953 2.8875 10.75 3.02656 10.75ZM4.07969 8.025L9.74688 2.35938L10.8922 3.50469L5.225 9.17031L3.83594 9.41563L4.07969 8.025ZM12.75 12.0625H1.25C0.973438 12.0625 0.75 12.2859 0.75 12.5625V13.125C0.75 13.1938 0.80625 13.25 0.875 13.25H13.125C13.1938 13.25 13.25 13.1938 13.25 13.125V12.5625C13.25 12.2859 13.0266 12.0625 12.75 12.0625Z"
                fill="#4B5167"
            />
        </g>
        <defs>
            <clipPath id="clip0_8775_16409">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
