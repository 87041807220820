/* eslint-disable no-nested-ternary */

import React, { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Menu, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DownOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Table, Tooltip } from 'components/layout';
import IPromocode from 'models/promocodes/IPromocode';

import './promocodes-table.scss';

const { Item } = Menu;

interface Props {
    data?: IPromocode[] | null;
    checkedList: string[];
    pageSize?: number;
    page: number;
    onChangePage: Function;
    total?: number;
    loading: boolean;
    onDeletePromocode: (id: string) => void;
    onTurnOffPromocode: (id: string) => void;
    onTurnOnPromocode: (id: string) => void;
    onOpenModal: (promoId: string, comment: string) => void;
}

const handleCell = () => {
    return {
        onDoubleClick: (e: MouseEvent<HTMLTableCellElement>) => {
            const target = e.target as HTMLElement;

            navigator.clipboard.writeText(target.innerText);
        },
    };
};

const PromocodesTable: FC<Props> = (props: Props) => {
    const {
        data,
        checkedList,
        pageSize,
        page,
        total,
        loading,
        onChangePage,
        onDeletePromocode,
        onTurnOffPromocode,
        onTurnOnPromocode,
        onOpenModal,
    } = props;

    const navigate = useNavigate();

    const more = (id: string) => {
        navigate(`/user/${id}`);
    };

    const items = [
        {
            key: 'delete',
            needConfirm: false,
            label: 'Удалить',
            action: onDeletePromocode,
        },
    ];

    const columns: ColumnsType<IPromocode> = [
        {
            title: 'Промокод',
            className: 'event-table-name',
            dataIndex: 'promocodeText',
            key: 'promocodeText',
            onCell: handleCell,
            width: '13%',
        },
        {
            title: 'Активен',
            dataIndex: 'available',
            key: 'available',
            render: (_: string, row: IPromocode) => {
                return (
                    <div key={`${row.id}_available`}>
                        {row.available ? (
                            <span className="available">Активен</span>
                        ) : (
                            <span className="nonAvailable">Неактивен</span>
                        )}
                    </div>
                );
            },
            onCell: handleCell,
            width: '8%',
        },
        {
            title: 'Применен',
            className: 'event-table-name',
            dataIndex: 'applyDate',
            key: 'applyDate',
            render: (applyDate: string) => {
                return applyDate ? dayjs(applyDate).format('DD.MM.YYYY') : null;
            },
            onCell: handleCell,
            width: '8%',
        },
        {
            title: 'Пользователь',
            dataIndex: 'applyUser',
            key: 'applyUser',
            render: (_: string, row: IPromocode) => {
                return (
                    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
                    <div key={`${row.id}_applyUserId`} onClick={() => more(row.userId)}>
                        {`${row.firstName || ''} ${row.lastName || ''}`}
                    </div>
                );
            },
            onCell: handleCell,
            width: '11%',
        },
        {
            title: 'Тариф',
            className: 'event-table-name',
            dataIndex: 'offerName',
            key: 'offerName',
            onCell: handleCell,
            width: '8%',
        },
        {
            title: 'Срок действия',
            className: 'event-table-name',
            dataIndex: 'duration',
            key: 'duration',
            onCell: handleCell,
            width: '5%',
        },
        {
            title: 'Скидка',
            className: 'event-table-name',
            dataIndex: 'discount',
            key: 'discount',
            onCell: handleCell,
            width: '5%',
        },
        {
            title: 'Дата начала',
            className: 'event-table-name',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (startDate: string) => {
                return startDate ? dayjs(startDate).format('DD.MM.YYYY') : null;
            },
            onCell: handleCell,
            width: '8%',
        },
        {
            title: 'Дата окончания',
            className: 'event-table-name',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (endDate: string) => {
                return endDate ? dayjs(endDate).format('DD.MM.YYYY') : null;
            },
            onCell: handleCell,
            width: '8%',
        },
        {
            title: 'Email',
            className: 'event-table-name',
            dataIndex: 'email',
            key: 'email',
            onCell: handleCell,
            width: '8%',
        },
        {
            title: 'Добавил',
            className: 'event-table-name',
            dataIndex: 'addedBy',
            key: 'addedBy',
            onCell: handleCell,
            width: '8%',
        },
        {
            title: 'Дата добавления',
            className: 'event-table-name',
            dataIndex: 'addedDate',
            key: 'addedDate',
            render: (addedDate: string) => {
                return addedDate ? dayjs(addedDate).format('DD.MM.YYYY') : null;
            },
            onCell: handleCell,
            width: '8%',
        },
        {
            title: 'Комментарий',
            className: 'event-table-name',
            dataIndex: 'comment',
            key: 'comment',
            render: (comment, { id }) => {
                const isLong = comment?.length > 10;
                const commentPreview = isLong ? `${String(comment)?.slice(0, 10)}…` : comment;

                const handleEditClick = () => {
                    onOpenModal(id, comment?.slice(0, 1000));
                };

                return (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div className="cell cell-interactive" onClick={handleEditClick}>
                        {isLong ? (
                            <Tooltip title={comment}>{commentPreview}</Tooltip>
                        ) : (
                            commentPreview
                        )}
                        <EditOutlined className="icon" />
                    </div>
                );
            },
            // найти ширину на которой будет нормально смотреться
            width: '140px',
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: string, row: IPromocode) => (
                <Dropdown
                    className="dropdown"
                    trigger={['click']}
                    // TODO: выпилить overlay, т к он устарел
                    overlay={
                        <Menu>
                            {row.available && (
                                <Item onClick={() => onTurnOffPromocode(row.id)}>Выключить</Item>
                            )}
                            {!row.available && (
                                <Item onClick={() => onTurnOnPromocode(row.id)}>Включить</Item>
                            )}
                            {items.map((item) => {
                                return (
                                    <Item onClick={() => item.action(row.id)} key={item.key}>
                                        {item.label}
                                    </Item>
                                );
                            })}
                        </Menu>
                    }
                >
                    <Space>
                        Действия
                        <DownOutlined />
                    </Space>
                </Dropdown>
            ),
            width: '8%',
        },
    ];

    const filteredColumns = columns.filter((column) => checkedList.includes(column.key as string));

    return (
        <Table
            className="orders-table"
            data={data}
            page={page}
            pageSize={pageSize}
            total={total}
            columns={filteredColumns}
            onChangePage={onChangePage}
            loading={loading}
        />
    );
};

export default PromocodesTable;
