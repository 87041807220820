import { makeAutoObservable } from 'mobx';
import { IError, IPageable, IRequests, IReviewReq } from 'models';
import RequestService from 'services/RequestService';

class RequestStore {
    isLoading = false;
    requests: IRequests | null = null;
    errorRequest: IError | null = null;
    errorReview: IError | null = null;

    setIsLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setRequests(data: IRequests) {
        this.requests = data;
    }

    setError(data: IError | null) {
        this.errorRequest = data;
    }

    setErrorReview(data: IError | null) {
        this.errorReview = data;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getRequests(pageable: IPageable, filter?: { name: string }) {
        this.setIsLoading(true);

        try {
            const response = await RequestService.getRequest(pageable, filter);
            this.setRequests(response.data);
            this.setError(null);
            this.setIsLoading(false);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
            this.setIsLoading(false);
        }
    }

    async reviewRequest(
        businessRequestId: string,
        data: IReviewReq,
        pageable: IPageable,
        filter?: { name: string }
    ) {
        this.setIsLoading(true);
        try {
            this.setError(null);
            await RequestService.reviewRequest(businessRequestId, data);
            this.getRequests(pageable, filter);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setErrorReview(error.response.data);
            }
        }
        this.setIsLoading(false);
    }
}

const requestStore = new RequestStore();

export default requestStore;
