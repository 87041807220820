import React, { FC } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { observer } from 'mobx-react';
import config from 'config';
import { IRoute } from 'models';
import { Header, Layout } from 'components/layout';
import { OffersAddForm } from 'components/offer';
import { offerStore } from 'store';

import './offers-add.scss';

interface Props {
    navigation: IRoute[];
}

const OffersAdd: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const { errorAddOffer } = offerStore;

    return (
        <Layout page="offers" navigation={navigation}>
            <div className="page-offers">
                <Scrollbars {...config.scrolls}>
                    <Header title="Добавление тарифа" />
                    <OffersAddForm errorAddOffer={errorAddOffer} />
                </Scrollbars>
            </div>
        </Layout>
    );
});

export default OffersAdd;
