import React, { FC, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import config from 'config';
import { IRoute } from 'models';
import { ErrorRequest, Layout } from 'components/layout';

import './event.scss';
import EventInfo from 'components/event/EventInfo/EventInfo';
import eventStore from 'store/EventStore';
import { userStore } from 'store';

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = 'Europe/Moscow';
dayjs.tz.setDefault(tz);

interface Props {
    navigation: IRoute[];
}

const Event: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const { id } = useParams();
    const { event, isLoadingEvent, errorEvent, successAdd } = eventStore;
    const [isNewEvent, setIsNewEvent] = useState(false);

    useEffect(() => {
        userStore.getRoles();
    }, []);

    useEffect(() => {
        if (successAdd && event) {
            eventStore.setSuccessAdd(false);
            eventStore.getEvent(event?.id || '');
        }
    }, [successAdd]);

    useEffect(() => {
        if (id && id !== 'new') {
            eventStore.getEvent(id);
        }
        if (id && id === 'new') {
            setIsNewEvent(true);
        }
    }, []);

    useEffect(() => () => eventStore.setEvent(null), []);

    return (
        <Layout page="events" navigation={navigation}>
            {/* <Header
                title={id === 'new' ? 'Новое мероприятие' : 'Карточка мероприятия'}
                loading={false}
                routes={[{ name: 'Мероприятия', path: '/events' }]}
            /> */}
            <div className="page-event">
                <Scrollbars {...config.scrolls}>
                    {!errorEvent && (
                        <EventInfo
                            title="Подробности мероприятия"
                            data={event}
                            loading={isLoadingEvent}
                            isNew={isNewEvent}
                        />
                    )}
                    {!event && errorEvent && id && (
                        <ErrorRequest onClick={() => eventStore.getEvent(id)} />
                    )}
                </Scrollbars>
            </div>
        </Layout>
    );
});

export default Event;
