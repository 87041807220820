import React, { FC, useRef } from 'react';
import { Skeleton, Table as AntTable } from 'antd';
import config from 'config';
import Pagination from '../Pagination/Pagination';

import './table.scss';

interface Props {
    rowKey?: string;
    onRow?: Function;
    columns?: any[];
    data?: any[] | null;
    page: number;
    pageSize?: number;
    className?: string;
    loading?: boolean;
    total?: number;
    onChangePage: Function;
    scroll?: { y?: string; x?: string };
}

const Table: FC<Props> = (props: Props) => {
    const {
        rowKey,
        onRow,
        columns,
        data,
        pageSize,
        className,
        loading,
        page,
        scroll,
        total,
        onChangePage,
    } = props;
    const tableRef = useRef<HTMLDivElement>(null);

    const loadColumns = () => {
        const loadCol = [];
        for (let i = 0; i < 5; i += 1) {
            loadCol.push({
                id: i,
                title: '',
                render: () => (
                    <Skeleton
                        className="table-skeleton"
                        active
                        paragraph={{ rows: 0, width: '100%' }}
                    />
                ),
            });
        }
        return loadCol;
    };

    return (
        <div className="app-table">
            <AntTable
                ref={tableRef}
                pagination={false}
                className={className || ''}
                dataSource={data || config.ui.stub(pageSize || config.table.pageSize)}
                columns={data && !loading ? columns : loadColumns()}
                rowKey={rowKey || 'id'}
                size="large"
                scroll={scroll || { y: 'calc(100vh - 290px)' }}
                onRow={(record, index) => ({
                    onClick: onRow ? onRow(record, index) : null,
                })}
            />
            <Pagination
                total={total || 0}
                pageSize={pageSize || config.table.pageSize}
                items={data?.length || 0}
                current={page}
                onChange={(pageNum: number) => onChangePage(pageNum)}
            />
        </div>
    );
};

export default Table;
