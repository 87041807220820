import { FormInstance } from 'antd';

export const changeFormFieldValue = (
    form: FormInstance,
    fieldName: string,
    callback: (value: string) => string
) => {
    const fieldValue: string = form.getFieldValue(fieldName);

    form.setFieldValue(fieldName, callback(fieldValue));
};

export default changeFormFieldValue;
