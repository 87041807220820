import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Dropdown, Menu, Space, Popconfirm } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { IUser, IUserPassInfo } from 'models';
import { Table, Tag } from 'components/layout';
import './users-table.scss';

const { Item } = Menu;

interface Props {
    data?: IUser[] | null;
    pageSize?: number;
    page: number;
    onChangePage: Function;
    total?: number;
    onDeleteUser: (id: string) => void;
    onBlockUser: (id: string) => void;
    onUnblockUser: (id: string) => void;
}

const UsersTable: FC<Props> = (props: Props) => {
    const { data, pageSize, page, onDeleteUser, onBlockUser, onUnblockUser, onChangePage, total } =
        props;

    const navigate = useNavigate();

    const more = (id: string) => {
        navigate(`/user/${id}`);
    };

    const items = [
        { key: 'more', needConfirm: false, label: 'Подробнее', action: more },
        {
            key: 'delete',
            needConfirm: true,
            titleConfirm: 'Вы уверены что хотите удалить пользователя?',
            label: 'Удалить',
            action: onDeleteUser,
        },
        {
            key: 'block',
            needConfirm: true,
            titleConfirm: 'Вы уверены что хотите заблокировать пользователя?',
            label: 'Заблокировать',
            action: onBlockUser,
        },
        {
            key: 'unblock',
            needConfirm: true,
            titleConfirm: 'Вы уверены что хотите разблокировать пользователя?',
            label: 'Разблокировать',
            action: onUnblockUser,
        },
    ];

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            width: '15%',
            render: (_: string, row: IUser) => {
                return `${row.lastName} ${row.firstName} ${row.middleName}`;
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: '15%',
        },
        {
            title: '№ телефона',
            dataIndex: 'phone',
            width: '15%',
        },
        {
            title: 'Компания',
            dataIndex: 'organization',
            width: '10%',
        },
        {
            title: 'Должность',
            dataIndex: 'position',
            width: '10%',
        },
        {
            title: 'Пропуска до:',
            dataIndex: 'passInfo',
            render: (passInfo: IUserPassInfo) => {
                return passInfo?.passExpirationDateTime
                    ? dayjs(passInfo?.passExpirationDateTime).utc().format('DD.MM.YYYY HH:mm')
                    : null;
            },
            width: '15%',
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            render: (_: string, user: IUser) => {
                return (
                    <div>
                        {user?.role?.name === 'CD_RESIDENT' && (
                            <div>
                                <div>{user?.role?.name ? user?.role?.name : null}</div>
                                <div>
                                    До {dayjs(user?.offerExpirationDate).format('DD.MM.YYYY')}
                                </div>
                            </div>
                        )}
                        {user?.role?.name !== 'CD_RESIDENT' && (
                            <div>
                                <div>{user?.role?.name ? user?.role?.name : null}</div>
                            </div>
                        )}
                    </div>
                );
            },
            width: '10%',
        },
        {
            title: 'Статус',
            dataIndex: 'userStatus',
            render: (_: string, row: IUser) => {
                return (
                    <Tag
                        type={row.userStatus === 'ACTIVE' ? 'success' : 'error'}
                        key={`${row.id}_userStatus`}
                    >
                        {row.userStatus}
                    </Tag>
                );
            },
            width: '10%',
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            render: (_: string, row: IUser) => (
                <Dropdown
                    className="dropdown"
                    trigger={['click']}
                    overlay={
                        <Menu>
                            {items.map((item) => {
                                if (item.needConfirm) {
                                    if (row.userStatus === 'ACTIVE' && item.key === 'block') {
                                        return (
                                            <Item key={item.key}>
                                                <Popconfirm
                                                    title={item.titleConfirm}
                                                    onConfirm={() => item.action(row.id)}
                                                    placement="topRight"
                                                    okText="Да"
                                                    cancelText="Нет"
                                                >
                                                    {item.label}
                                                </Popconfirm>
                                            </Item>
                                        );
                                        // eslint-disable-next-line no-else-return
                                    } else if (
                                        row.userStatus === 'BLOCKED' &&
                                        item.key === 'unblock'
                                    ) {
                                        return (
                                            <Item key={item.key}>
                                                <Popconfirm
                                                    title={item.titleConfirm}
                                                    onConfirm={() => item.action(row.id)}
                                                    placement="topRight"
                                                    okText="Да"
                                                    cancelText="Нет"
                                                >
                                                    {item.label}
                                                </Popconfirm>
                                            </Item>
                                        );
                                        // eslint-disable-next-line no-else-return
                                    } else if (
                                        (row.userStatus === 'ACTIVE' ||
                                            row.userStatus === 'BLOCKED') &&
                                        item.key === 'delete'
                                    ) {
                                        return (
                                            <Item key={item.key}>
                                                <Popconfirm
                                                    title={item.titleConfirm}
                                                    onConfirm={() => item.action(row.id)}
                                                    placement="topRight"
                                                    okText="Да"
                                                    cancelText="Нет"
                                                >
                                                    {item.label}
                                                </Popconfirm>
                                            </Item>
                                        );
                                    }
                                } else {
                                    return (
                                        <Item onClick={() => item.action(row.id)} key={item.key}>
                                            {item.label}
                                        </Item>
                                    );
                                }

                                return null;
                            })}
                        </Menu>
                    }
                >
                    <Space>
                        Действия...
                        <DownOutlined />
                    </Space>
                </Dropdown>
            ),
            width: '10%',
        },
    ];
    return (
        <Table
            className="users-table"
            data={data}
            page={page}
            pageSize={pageSize}
            total={total}
            columns={columns}
            onChangePage={onChangePage}
        />
    );
};

export default UsersTable;
