import { AxiosResponse } from 'axios';
import { IEvent, IPageable, IEventFilters, IEvents } from 'models'; // IEventFilters,IPageable,IEventPost
import qs from 'qs';
import api from '../http';

export default class EventService {
    static async getEvent(id: string): Promise<AxiosResponse<IEvent>> {
        return api.get(`/event/${id}`);
    }

    static async getEvents(
        pageable: IPageable,
        filter?: IEventFilters
    ): Promise<AxiosResponse<IEvents>> {
        return api.get('/event/', {
            params: {
                ...pageable,
                ...filter,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });
    }

    // TODO CREATE MODEL POST
    static async postEvent(data: any): Promise<AxiosResponse> {
        return api.post('/event/', data);
    }

    static async putEvent(id: string, data: any): Promise<AxiosResponse> {
        return api.put(`/event/${id}`, data);
    }

    static async deleteEvent(id: string): Promise<AxiosResponse> {
        return api.delete(`/event/${id}`);
    }

    static async activateEvent(id: string): Promise<AxiosResponse> {
        return api.post(`/event/${id}/draft?isDraft=false`);
    }

    static async cancelEvent(id: string): Promise<AxiosResponse> {
        return api.post(`/event/${id}/cancel`);
    }

    static async getEventStatuses(): Promise<AxiosResponse> {
        return api.get(`/event/misc/status`);
    }
}
