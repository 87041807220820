import React from 'react';
import { Form, Input, Switch } from 'antd';
import { InnerBlock } from '../../../layout';
import { expressEventLinkRegex } from '../../const/const';

type TEventExpressLink = {
    loading: boolean;
    enabled: boolean;
    setEnabled: (value: boolean) => void;
};

const EventExpressLink = ({ loading, enabled, setEnabled }: TEventExpressLink) => {
    return (
        <InnerBlock loading={loading} title="Внешнее мероприятие">
            <Form.Item name="expressLinkToggle">
                <Switch checked={enabled} onChange={setEnabled} />
            </Form.Item>
            <Form.Item
                label="Ссылка на регистрацию"
                name="expressLink"
                rules={[
                    {
                        required: enabled,
                        message: 'Некорректный формат',
                    },
                    {
                        pattern: expressEventLinkRegex,

                        message: 'Некорректный формат',
                    },
                ]}
            >
                <Input disabled={!enabled} />
            </Form.Item>
        </InnerBlock>
    );
};

export default EventExpressLink;
