import React, { FC, useEffect, useRef, useState, ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import strings from 'tools';
import { IRoute, IPageable, IPromocodeFilter } from 'models';
import { EmptySearch, ErrorRequest, Header, Icons, Input, Button, Layout } from 'components/layout';
import { PromocodesTable } from 'components/promocodes';
import PromocodesService from 'services/PromocodesService';
import promocodesStore from 'store/PromocodesStore';
import config from 'config';

import { Checkbox, Dropdown, Typography } from 'antd';
import type { CheckboxProps, MenuProps, TableColumnsType } from 'antd';
import IPromocode from 'models/promocodes/IPromocode';
import { DownOutlined } from '@ant-design/icons';
import PromocodeModal, { FieldType } from 'components/promocodes/PromocodeModal/PromocodeModal';
import './promocodes.scss';
// TODO: вынести в константы
const columns: TableColumnsType<IPromocode> = [
    {
        title: 'Промокод',
        dataIndex: 'promocodeText',
        key: 'promocodeText',
    },
    {
        title: 'Активен',
        dataIndex: 'available',
        key: 'available',
    },
    {
        title: 'Применен',
        dataIndex: 'applyDate',
        key: 'applyDate',
    },
    {
        title: 'Пользователь',
        dataIndex: 'applyUser',
        key: 'applyUser',
    },
    {
        title: 'Тариф',
        dataIndex: 'offerName',
        key: 'offerName',
    },
    {
        title: 'Срок действия',
        dataIndex: 'duration',
        key: 'duration',
    },
    {
        title: 'Скидка',
        dataIndex: 'discount',
        key: 'discount',
    },
    {
        title: 'Дата начала',
        dataIndex: 'startDate',
        key: 'startDate',
    },
    {
        title: 'Дата окончания',
        dataIndex: 'endDate',
        key: 'endDate',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Добавил',
        dataIndex: 'addedBy',
        key: 'addedBy',
    },
    {
        title: 'Дата добавления',
        dataIndex: 'addedDate',
        key: 'addedDate',
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
    },
    {
        title: 'Действия',
        dataIndex: 'actions',
        key: 'actions',
    },
];

const defaultCheckedList = columns.map((column) => column.key as string);

interface Props {
    navigation: IRoute[];
}

const Promocodes: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const [pageable, setPageable] = useState<IPageable | undefined>();
    const [isAddPromocode, setIsAddPromocode] = useState<boolean>(false);
    const [filter, setFilter] = useState<IPromocodeFilter>({});
    const [search, setSearch] = React.useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);
    const { promocodes, isLoading, isCommentLoading } = promocodesStore;
    const [currentPromoId, setCurrentPromoId] = useState<string>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [comment, setComment] = useState<string>();
    const [checkedList, setCheckedList] = useState<string[]>(defaultCheckedList);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const filteredColumns = columns.map((column) => ({
        ...column,
        hidden: !checkedList.includes(column.key as string),
    }));

    const items: MenuProps['items'] = filteredColumns
        .map(({ key, hidden, title }) => {
            const onChange: CheckboxProps['onChange'] = (e) => {
                const { checked } = e.target;
                const isInCheckedList = checkedList.includes(key as string);

                if (checked && !isInCheckedList) {
                    setCheckedList([...checkedList, key as string]);
                }

                if (!checked && isInCheckedList) {
                    const uncheckedIndex = checkedList.findIndex(
                        (columnKey) => columnKey === (key as string)
                    );

                    setCheckedList([
                        ...checkedList.slice(0, uncheckedIndex),
                        ...checkedList.slice(uncheckedIndex + 1),
                    ]);
                }
            };

            return {
                key,
                label: (
                    <Checkbox checked={!hidden} onChange={onChange}>
                        {title as string}
                    </Checkbox>
                ),
            };
        })
        .filter((item) => item.key !== 'actions' && item.key !== 'promocodeText');

    const onOpenModal = (promoId: string, currentComment: string) => {
        setCurrentPromoId(promoId);
        setComment(currentComment);
        setIsModalOpen(true);
    };

    const onCloseModal = () => {
        if (!isCommentLoading) {
            setComment('');
            setIsModalOpen(false);
        }
    };

    const onChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
    };

    const navigate = useNavigate();

    useEffect(() => {
        promocodesStore.getPromocodes(pageable, filter);
    }, [filter, pageable]);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const changeSearch = (usefilter: string, text: string) => {
        if (text) {
            setFilter({ [usefilter]: text });
            setSearch(text);
        } else {
            setFilter({});
            setSearch('');
            setPageable({ page: 0, size: 10 });
        }
    };

    const changePage = (pageNum: number) => {
        const newPageable = { ...pageable };
        newPageable.page = pageNum;
        setPageable(newPageable);
    };

    useEffect(() => {
        if (!isCommentLoading && isModalOpen) {
            onCloseModal();
        }
    }, [isCommentLoading]);

    useEffect(() => {
        if (isAddPromocode) {
            navigate('/promocodes/new');
        }
    }, [isAddPromocode]);

    const onSearch = () => {
        setPageable({ page: 0, size: 10 });
    };

    const onDeletePromocode = (id: string) => {
        promocodesStore.deletePromocode(id, pageable, filter);
    };

    const onTurnOffPromocode = (id: string) => {
        promocodesStore.turnOffPromocode(id, pageable, filter);
    };

    const onTurnOnPromocode = (id: string) => {
        promocodesStore.turnOnPromocode(id, pageable, filter);
    };

    const onFinish = (values: FieldType) => {
        if (values.comment) {
            promocodesStore.editPromocode(currentPromoId, comment, pageable, filter);
        } else {
            promocodesStore.editPromocode(currentPromoId, '', pageable, filter);
        }
    };

    return (
        <Layout page="promocodes" navigation={navigation} errorScreen>
            <Header
                title="Промокоды"
                description={
                    promocodes
                        ? `Всего ${promocodes.totalElements} ${strings.ending(
                              promocodes.totalElements,
                              ['промокод', 'промокода', 'промокодов']
                          )}`
                        : ''
                }
            />
            <div className="page-promocodes">
                <div className="promocodes-top">
                    <div className="promocodes-filters">
                        <Input
                            ref={inputRef}
                            className="promocodes-search"
                            onChange={changeSearch}
                            value={search || ''}
                            type="searchWithSelect"
                            selectOptions={config.search.promocodes}
                            onSearch={onSearch}
                            changeDateSearch={() => {}}
                        />
                        <Dropdown
                            menu={{ items }}
                            onOpenChange={setIsDropdownOpen}
                            open={isDropdownOpen}
                        >
                            <Typography.Text className="promocodes-dropdown-shield">
                                Отображаемые столбцы <DownOutlined />
                            </Typography.Text>
                        </Dropdown>
                    </div>
                    <Button onClick={() => setIsAddPromocode(true)} type="primary">
                        {Icons.plus()} Создать
                    </Button>
                </div>
                {(promocodes?.content || isLoading) && (
                    <PromocodesTable
                        checkedList={checkedList}
                        onDeletePromocode={onDeletePromocode}
                        onTurnOffPromocode={onTurnOffPromocode}
                        onTurnOnPromocode={onTurnOnPromocode}
                        data={promocodes?.content}
                        page={pageable?.page || 0}
                        onChangePage={changePage}
                        total={promocodes?.totalElements}
                        loading={isLoading}
                        onOpenModal={onOpenModal}
                    />
                )}
                {!promocodes?.totalElements && !isLoading && <EmptySearch />}
                {!promocodes && (
                    <ErrorRequest onClick={() => PromocodesService.getPromocodes(pageable)} />
                )}
            </div>
            {isModalOpen && (
                <PromocodeModal
                    isModalOpen={isModalOpen}
                    isCommentLoading={isCommentLoading}
                    comment={comment}
                    onFinish={onFinish}
                    onCloseModal={onCloseModal}
                    onChangeComment={onChangeComment}
                />
            )}
        </Layout>
    );
});

export default Promocodes;
