import { AxiosResponse } from 'axios';
import { IPageable, IUser, IUserFiltres, IUserParams, IUsers } from 'models'; // IUserPost
import api from '../http';

export default class UserService {
    static async getUser(id: string): Promise<AxiosResponse<IUser>> {
        return api.get(`/user/${id}`);
    }

    static async deleteUser(id: string): Promise<AxiosResponse<void>> {
        return api.delete(`/user/${id}`);
    }

    static async blockUser(id: string): Promise<AxiosResponse<void>> {
        return api.post(`/user/${id}/block`);
    }

    static async unblockUser(id: string): Promise<AxiosResponse<void>> {
        return api.post(`/user/${id}/unblock`);
    }

    static async getUsers(
        pageable: IPageable,
        filter?: IUserFiltres
    ): Promise<AxiosResponse<IUsers>> {
        return api.get('/user/', { params: { ...pageable, ...filter } });
    }

    static async getUsersDetailed(
        pageable: IPageable,
        filter?: IUserFiltres
    ): Promise<AxiosResponse<IUsers>> {
        return api.get('/user/detailed/', { params: { ...pageable, ...filter } });
    }

    static async getUserByParams(params: IUserParams): Promise<AxiosResponse<IUser>> {
        return api.get(`/user/`, { params: { ...params } });
    }

    static async postUser(data: any): Promise<AxiosResponse> {
        return api.post('/user/', data);
    }

    static async putUser(id: string, data: any): Promise<AxiosResponse> {
        return api.put(`/user/${id}`, data);
    }

    static async updateUser(id: string, subdivisionId: string): Promise<AxiosResponse> {
        return api.put(`/user/${id}`, subdivisionId);
    }

    static async deactivateUser(id: string, reason: string): Promise<AxiosResponse> {
        return api.post(`/user/${id}/deactivate`, { reason });
    }

    static async activateUser(id: string, reason: string): Promise<AxiosResponse> {
        return api.post(`/user/${id}/activate`, { reason });
    }

    static async getRoles(): Promise<AxiosResponse> {
        return api.get(`/user/misc/role`);
    }
}
