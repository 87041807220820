import React, { FC } from 'react';
import { Button as AntButton } from 'antd';

import './button.scss';

interface Props {
    children: string | React.ReactNode;
    onClick?: Function;
    loading?: boolean;
    disabled?: boolean;
    className?: string;
    type?: 'primary' | 'default';
    danger?: boolean;
    htmlType?: 'button' | 'reset' | 'submit';
}

const Button: FC<Props> = (props: Props) => {
    const { children, disabled, loading, className, onClick, type, danger, htmlType } = props;
    const handler = () => onClick && onClick();
    const cls = `app-btn ${type || ''} ${className || ''}`;

    return (
        <AntButton
            className={cls}
            onClick={handler}
            loading={loading}
            disabled={disabled}
            type={type}
            danger={danger}
            htmlType={htmlType}
        >
            {children}
        </AntButton>
    );
};

export default Button;
