import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import config from 'config';
import { IRoute, IPageable, IUserFiltres } from 'models';
import { userStore } from 'store';
import strings from 'tools';
import { Button, EmptySearch, ErrorRequest, Header, Icons, Input, Layout } from 'components/layout';
import { UsersTable } from 'components/user';

import './users.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
    navigation: IRoute[];
}

const Users: FC<Props> = observer((props: Props) => {
    const { users, isLoadingUser, errorUser, successAdd } = userStore;
    const { navigation } = props;
    const [isAddUser, setIsAddUser] = useState<boolean>(false);
    const [search, setSearch] = React.useState<string>('');
    const [pageable, setPageable] = useState<IPageable>({ page: 0, size: 10 });
    const [filter, setFilter] = useState<IUserFiltres>({});
    const inputRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const changePage = (pageNum: number) => {
        const newPageable = { ...pageable };
        newPageable.page = pageNum;
        setPageable(newPageable);
    };

    useEffect(() => {
        userStore.getUsers(pageable, filter);
    }, [pageable]);

    useEffect(() => {
        if (isAddUser) {
            navigate('/user/new/');
        }
    }, [isAddUser]);

    const changeSearch = (usefilter: string, text: string) => {
        if (text) {
            setFilter({ [usefilter]: text });
            setSearch(text);
        } else {
            setFilter({});
            setSearch('');
            setPageable({ page: 0, size: 10 });
        }
    };

    const onDeleteUser = (id: string) => {
        userStore.deleteUser(id);
    };

    const onBlockUser = (id: string) => {
        userStore.blockUser(id);
    };

    const onUnblockUser = (id: string) => {
        userStore.unblockUser(id);
    };

    // const onAddUser = (data: IUserPost) => {
    //     userStore.addUser(data);
    // };

    useEffect(() => {
        if (successAdd) {
            setIsAddUser(false);
            setPageable({ page: 0, size: 10 });
            userStore.setSuccessAdd(false);
        }
    }, [successAdd]);

    const onSearch = () => {
        setPageable({ page: 0, size: 10 });
    };

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(
        () => () => {
            userStore.setUsers(null);
        },
        []
    );
    return (
        <Layout page="users" navigation={navigation} error={errorUser} errorScreen>
            <Header
                title="Пользователи"
                description={
                    users
                        ? `Всего ${users.totalElements} ${strings.ending(users.totalElements, [
                              'пользователь',
                              'пользователя',
                              'пользователей',
                          ])}`
                        : ''
                }
            />
            <div className="page-users">
                <div className="users-top">
                    <Input
                        ref={inputRef}
                        className="users-search"
                        onChange={changeSearch}
                        value={search || ''}
                        type="searchWithSelect"
                        selectOptions={config.search.users}
                        onSearch={onSearch}
                        changeDateSearch={() => {}}
                    />
                    <Button onClick={() => setIsAddUser(true)} type="primary">
                        {Icons.plus()}Создать пользователя
                    </Button>
                </div>
                {users?.totalElements && (users?.totalElements > 0 || isLoadingUser) && (
                    <UsersTable
                        onDeleteUser={onDeleteUser}
                        onBlockUser={onBlockUser}
                        onUnblockUser={onUnblockUser}
                        data={users?.content}
                        page={pageable.page}
                        total={users?.totalElements}
                        onChangePage={changePage}
                    />
                )}
                {!users?.totalElements && search && !isLoadingUser && <EmptySearch />}
                {!users && errorUser && (
                    <ErrorRequest onClick={() => userStore.getUsers(pageable)} />
                )}
                {isAddUser && null}
            </div>
        </Layout>
    );
});

export default Users;
