export const ending = (num: number, variants: string[]) => {
    if (!variants) return '';

    if (typeof variants === 'string') return variants;

    const rnum = num % 100 < 20 ? num % 100 : num % 10;

    if (rnum === 0) return variants[3] ? variants[3] : variants[2];
    if (rnum > 4) return variants[2];
    if (rnum > 1) return variants[1];

    return variants[0];
};

export const rounder = (num: number) => {
    if (num < 1000) return num;
    if (num < 10000) return `${Math.floor(num / 100) / 10} тыс.`;
    if (num < 100000) return `${Math.floor(num / 1000)} тыс.`;
    if (num < 1000000) return `${Math.floor(num / 1000)} тыс.`;
    if (num < 10000000) return `${Math.floor(num / 100000) / 10} млн.`;
    return `${Math.floor(num / 1000000)} млн.`;
};

export const onMaskTel = (num: any): string => {
    if (!num) {
        return '';
    }
    let inputNumValue = num.replace(/\D/g, '');
    let formattedInput: string = '';
    if (!inputNumValue) {
        return '';
    }

    if (['7', '8', '9'].indexOf(inputNumValue[0]) > -1) {
        if (inputNumValue[0] === '9') {
            inputNumValue = `7${inputNumValue}`;
        }
        formattedInput = '+7';
        if (inputNumValue.length > 1) {
            formattedInput += `(${inputNumValue.substring(1, 4)}`;
        }
        if (inputNumValue.length > 4) {
            formattedInput += `)${inputNumValue.substring(4, 7)}`;
        }
        if (inputNumValue.length > 7) {
            formattedInput += `-${inputNumValue.substring(7, 9)}`;
        }
        if (inputNumValue.length > 9) {
            formattedInput += `-${inputNumValue.substring(9, 11)}`;
        }
    } else {
        formattedInput = `+${inputNumValue.substring(0, 11)}`;
    }
    return formattedInput;
};

export const onlynumbers = (num: any): string => {
    return num.replace(/\D/g, '');
};
