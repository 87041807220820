import React, { FC } from 'react';
import Button from '../Button/Button';
import * as Icons from '../Icons/Icons';

import './error-request.scss';

interface Props {
    message?: string;
    className?: string;
    onClick?: Function;
}

const ErrorRequest: FC<Props> = (props: Props) => {
    const { message, className, onClick } = props;
    const cls = `app-errorRequest ${className || ''}`;
    return (
        <div className={cls}>
            {Icons.noResultBig}
            {message ? <p>{message}</p> : <p>Произошла ошибка в запросе</p>}
            <Button className="errorRequest-button" onClick={onClick} type="primary">
                Повторить запрос
            </Button>
        </div>
    );
};

export default ErrorRequest;
