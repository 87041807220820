import React, { FC } from 'react';
import { Tag as AntTag } from 'antd';

import './tag.scss';

interface Props {
    children: string | number;
    type: string;
    className?: string;
}

const Tag: FC<Props> = (props: Props) => {
    const { children, type, className } = props;
    return (
        <div className={`app-tag ${className || ''}`}>
            <AntTag className={`tag-${type}`} color={type}>
                {children}
            </AntTag>
        </div>
    );
};

export default Tag;
