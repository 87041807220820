import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import strings from 'tools';
import { IRoute, IPageable, IReviewReq, IRequest } from 'models';
import { Button, EmptySearch, ErrorRequest, Header, Input, Layout, Modal } from 'components/layout';
import RequestsTable from 'components/requests';
import { requestStore } from 'store';
import config from 'config';

import './requests.scss';

interface Props {
    navigation: IRoute[];
}

const Requests: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const [filter, setFilter] = useState<any>({});
    const [search, setSearch] = React.useState<string>('');
    const [pageable, setPageable] = useState<IPageable>({ page: 0, size: 8 });
    const [openModalComment, setOpenModalComment] = useState<boolean>(false);
    const [showReviewedRequests, setShowReviewedRequests] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const [clickedRow, setClickedRow] = useState<IRequest>();
    const inputRef = useRef<HTMLInputElement>(null);
    const { requests, isLoading } = requestStore;

    useEffect(() => {
        if (pageable.page || pageable.page === 0) {
            requestStore.getRequests(pageable, filter);
        }
    }, [filter, pageable]);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        if (showReviewedRequests) {
            requestStore.setRequests({
                ...requests,
                content: requests?.content.filter((req) => !req.review),
            });
        } else {
            requestStore.getRequests(pageable, filter);
        }
    }, [showReviewedRequests]);

    const changeSearch = (usefilter: string, text: string) => {
        if (text) {
            setFilter({ [usefilter]: text });
            setSearch(text);
        } else {
            setFilter({});
            setSearch('');
        }
    };

    const reviewRequest = (businessRequestId: string, data: IReviewReq) => {
        requestStore.reviewRequest(businessRequestId, data, pageable, filter);
    };

    const onReviewRequest = (row: IRequest) => {
        reviewRequest(row.id, {
            status: 'DONE',
            review: {
                date: dayjs().format(),
                comment,
            },
        });
        setOpenModalComment(false);
        setComment('');
    };

    const changePage = (pageNum: number) => {
        const newPageable = { ...pageable };
        newPageable.page = pageNum;
        setPageable(newPageable);
    };

    return (
        <Layout page="requests" navigation={navigation} errorScreen>
            <Header
                title="Заявки"
                description={
                    requests
                        ? `Всего ${requests.totalElements} ${strings.ending(
                              requests.totalElements,
                              ['заявка', 'заявки', 'заявок']
                          )}`
                        : ''
                }
            />
            <div className="page-requests">
                <div className="requests-top">
                    <Input
                        ref={inputRef}
                        className="requests-search"
                        onChange={changeSearch}
                        value={search || ''}
                        type="searchWithSelect"
                        selectOptions={config.search.requests}
                        changeDateSearch={() => {}}
                    />
                    <Button
                        disabled={requests?.empty}
                        onClick={() => {
                            setShowReviewedRequests(!showReviewedRequests);
                        }}
                    >
                        {showReviewedRequests ? 'Показать обработанные' : 'Скрыть обработанные'}
                    </Button>
                </div>
                {(requests?.content || isLoading) && (
                    <RequestsTable
                        setClickedRow={setClickedRow}
                        setOpenModalComment={setOpenModalComment}
                        data={requests?.content}
                        loading={isLoading}
                        page={pageable.page}
                        total={requests?.totalElements}
                        onChangePage={changePage}
                    />
                )}
                {!requests?.empty && !isLoading && <EmptySearch />}
                {!requests && (
                    <ErrorRequest onClick={() => requestStore.getRequests(pageable, filter)} />
                )}
            </div>
            {openModalComment && (
                <Modal
                    title=""
                    visible={openModalComment}
                    footer={[
                        <Button
                            key="review"
                            onClick={() => onReviewRequest(clickedRow)}
                            className="reqAction"
                        >
                            Обработать
                        </Button>,
                    ]}
                    onCancel={() => setOpenModalComment(false)}
                >
                    <Input value={comment} onChange={setComment} label="Комментарий" />
                </Modal>
            )}
        </Layout>
    );
});

export default Requests;
