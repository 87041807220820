/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { IPhoto, IUser } from 'models';
import { Block, InnerBlock } from 'components/layout';
import { Form, Input, Col, Row, message, Button, DatePicker, Select, Image, Switch } from 'antd';
import { FileService, UserService } from 'services';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import config from 'config';
import './user-info.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { userStore } from 'store';

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = 'Europe/Moscow';
dayjs.tz.setDefault(tz);

interface Props {
    title?: string;
    data?: IUser | null;
    loading?: boolean;
    isNew?: boolean;
}

const UserInfo = (props: Props) => {
    const { title, loading, data, isNew } = props;
    const [editUser, setEditUser] = useState<boolean>(false);
    const [userImagesList, setUserImagesList] = useState<IPhoto[]>([]);
    const [passIsActive, setPassIsActive] = useState<boolean>(false);
    const [avatarUrl, setAvatarUrl] = useState<string>('');
    const [requiredPhone, setRequiredPhone] = useState<boolean>(true);
    const [requiredEmail, setRequiredEmail] = useState<boolean>(true);

    const [form] = useForm(null);
    const { roles } = userStore;

    const navigate = useNavigate();

    useEffect(() => {
        if (data?.passInfo?.passIsActive) {
            setPassIsActive(data?.passInfo?.passIsActive);
        }

        if (data?.avatar && data?.avatar !== 'null') {
            FileService.getFileData(data?.avatar).then((resp) => {
                setAvatarUrl(resp.data.link);
            });
        }
    }, [data]);

    const onValuesChange = (e, v) => {
        console.log(v);
        console.log(e);

        setEditUser(true);
    };

    const onFinishFailed = (error: any) => {
        console.log(error);
        // message.error(JSON.stringify(error));
    };

    const createUser = (formData: any) => {
        const newData = {
            ...formData,
            birthDate: formData.birthDate ? dayjs(formData.birthDate).utc().format() : null,
            passInfo: {
                passIsActive: formData.passInfo.passIsActive || false,
                passExpirationDateTime: formData.passInfo.passExpirationDateTime
                    ? dayjs(formData.passInfo.passExpirationDateTime).utc().format(config.dates.z)
                    : null,
                passStartDateTime: formData.passInfo.passStartDateTime
                    ? dayjs(formData.passInfo.passStartDateTime).utc().format(config.dates.z)
                    : null,
            },
        };

        UserService.postUser(newData).then(() => {
            message.success('Пользователь успешно добавлен!');
            navigate('/users');
        });
    };

    const updateUser = (formData: any) => {
        const newData = {
            ...formData,
            birthDate: formData.birthDate
                ? dayjs(formData.birthDate).format('YYYY-MM-DDTHH:mm:ss[Z]')
                : null,
        };

        if (newData?.passInfo?.passIsActive) {
            newData.passInfo.passExpirationDateTime = newData?.passInfo.passExpirationDateTime
                ? dayjs(newData?.passInfo.passExpirationDateTime).utc().format(config.dates.z)
                : null;
            newData.passInfo.passStartDateTime = newData?.passInfo.passStartDateTime
                ? dayjs(newData?.passInfo.passStartDateTime).utc().format(config.dates.z)
                : null;
            newData.passInfo.passIsActive = true;
        }

        if (!newData.passInfo.passIsActive) {
            newData.passInfo.passExpirationDateTime = newData?.passInfo.passExpirationDateTime
                ? dayjs(newData?.passInfo.passExpirationDateTime).utc().format(config.dates.z)
                : null;
            newData.passInfo.passStartDateTime = newData?.passInfo.passStartDateTime
                ? dayjs(newData?.passInfo.passStartDateTime).utc().format(config.dates.z)
                : null;
            newData.passInfo.passIsActive = false;
        }

        if (data?.id) {
            UserService.putUser(data?.id, newData).then(() => {
                message.success('Пользователь успешно изменен!');
                navigate('/users');
            });
        }
    };

    const onReset = () => {
        navigate('/users');
        if (data.passInfo === null) {
            setPassIsActive(false);
        } else {
            setPassIsActive(data.passInfo.passIsActive);
        }

        form.resetFields();
    };

    const setInitValues = () => {
        if (data?.photos) {
            const photosIds = data?.photos?.map((photo) => {
                return photo.photo;
            });
            if (photosIds.length > 0) {
                FileService.getFilesData({ filesIds: photosIds }).then((response) => {
                    if (response.data.length > 0) setUserImagesList(response.data);
                });
            }
        }
    };

    useEffect(() => {
        setInitValues();
    }, [data]);

    const changePhone = (value: string) => {
        if (value) {
            setRequiredEmail(false);
        } else {
            setRequiredEmail(true);
        }
    };

    const changeEmail = (value: string) => {
        if (value) {
            setRequiredPhone(false);
        } else {
            setRequiredPhone(true);
        }
    };

    return (
        <Block
            className="app-user-info"
            loading={loading}
            title={title}
            description={`id: ${data?.id || ''}`}
            buttons={
                editUser ? (
                    <Row style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                        <Button onClick={onReset}>Отмена</Button>
                        <Button type="primary" onClick={() => form.submit()}>
                            {isNew ? 'Создать' : 'Сохранить'}
                        </Button>
                    </Row>
                ) : null
            }
        >
            {(data || isNew) && (
                <Form
                    form={form}
                    size="small"
                    name="main"
                    layout="vertical"
                    wrapperCol={{ span: 20 }}
                    initialValues={{
                        avatar: data?.avatar,
                        username: data?.username,
                        lastName: data?.lastName,
                        firstName: data?.firstName,
                        middleName: data?.middleName,
                        birthDate: data?.birthDate ? dayjs(data?.birthDate).tz(tz) : '',
                        userStatus: data?.userStatus,
                        email: data?.email,
                        emailIsVerified: data?.emailIsVerified,
                        phone: data?.phone,
                        phoneIsVerified: data?.phoneIsVerified,
                        organization: data?.organization,
                        position: data?.position,
                        messengers: data?.messengers,
                        role: roles?.find((role) => role.value === data?.role?.name)?.value,
                        photos: data?.photos,
                        // passIsActive: data?.passInfo.passIsActive || false,
                        // passInfo: data?.passInfo,
                    }}
                    onFinishFailed={onFinishFailed}
                    onFinish={isNew ? createUser : updateUser}
                    onValuesChange={onValuesChange}
                    autoComplete="off"
                >
                    <Row gutter={16} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Col
                            className="gutter-row"
                            span={12}
                            style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                        >
                            <InnerBlock loading={loading} title="Аватар">
                                {avatarUrl ? (
                                    <Image width={200} src={avatarUrl} />
                                ) : (
                                    <Image width={200} src="/images/anonymous.svg" />
                                )}
                            </InnerBlock>
                            <InnerBlock loading={loading} title="Основная информация">
                                <Form.Item
                                    label="Псевдоним"
                                    name="username"
                                    rules={[
                                        { max: 50, message: 'Максимальная длинна 50 символов' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Фамилия"
                                    name="lastName"
                                    rules={[
                                        { required: true, message: 'Введите фамилию пользователя' },
                                        { max: 50, message: 'Максимальная длинна 50 символов' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Имя"
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите имя пользователя',
                                        },
                                        { max: 50, message: 'Максимальная длинна 50 символов' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Отчество"
                                    name="middleName"
                                    rules={[
                                        {
                                            message: 'Введите отчество пользователя',
                                        },
                                        { max: 50, message: 'Максимальная длинна 50 символов' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Почта"
                                    name="email"
                                    hasFeedback
                                    validateStatus={data?.emailIsVerified ? 'success' : 'warning'}
                                    rules={[
                                        {
                                            required: isNew && requiredEmail,
                                            message: 'Введите почту пользователя',
                                        },
                                        { max: 50, message: 'Максимальная длинна 50 символов' },
                                    ]}
                                >
                                    <Input
                                        disabled={!isNew}
                                        onChange={(e) => changeEmail(e.currentTarget.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Телефон"
                                    name="phone"
                                    hasFeedback
                                    validateStatus={data?.phoneIsVerified ? 'success' : 'warning'}
                                    rules={[
                                        {
                                            required: isNew && requiredPhone,
                                            message: 'Введите телефон пользователя',
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={18}
                                        disabled={!isNew}
                                        onChange={(e) => changePhone(e.currentTarget.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Дата рождения"
                                    name="birthDate"
                                    // rules={[{ required: true, message: 'Введите дату рождения' }]}
                                >
                                    <DatePicker locale={config.ru} format={config.dates.classic} />
                                </Form.Item>
                            </InnerBlock>
                        </Col>
                        <Col
                            className="gutter-row"
                            span={12}
                            style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                        >
                            <InnerBlock loading={loading} title="Дополнительная информация">
                                <Form.Item
                                    label="Компания"
                                    name="organization"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите название компании',
                                        },
                                        {
                                            max: 100,
                                            message: 'Максимальная длинна 100 символов',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Должность"
                                    name="position"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите должность',
                                        },
                                        {
                                            max: 50,
                                            message: 'Максимальная длинна 50 символов',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Мессенджеры"
                                    name="messengers"
                                    rules={[
                                        { max: 100, message: 'Максимальная длинна 100 символов' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Роль"
                                    name="role"
                                    rules={[
                                        { required: true, message: 'Выберите роль пользователя' },
                                    ]}
                                >
                                    <Select
                                        options={roles?.map((role) => {
                                            return { value: role.value, label: role.value };
                                        })}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Дата окончания действия резиденства"
                                    name={['offerExpirationDate']}
                                    initialValue={
                                        data?.offerExpirationDate
                                            ? dayjs(data?.offerExpirationDate)
                                            : ''
                                    }
                                >
                                    <DatePicker
                                        locale={config.ru}
                                        format={config.dates.classic}
                                        disabled
                                    />
                                </Form.Item>
                                {!isNew && (
                                    <Form.Item label="Статус пользователя" name="userStatus">
                                        <Input disabled />
                                    </Form.Item>
                                )}
                            </InnerBlock>
                            <InnerBlock loading={loading} title="Пропуск">
                                <Form.Item
                                    label="Действителен"
                                    name={['passInfo', 'passIsActive']}
                                    initialValue={data?.passInfo?.passIsActive}
                                >
                                    <Switch
                                        checked={passIsActive}
                                        onChange={() => {
                                            setPassIsActive(!passIsActive);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Дата начала действия"
                                    name={['passInfo', 'passStartDateTime']}
                                    initialValue={
                                        data?.passInfo?.passStartDateTime
                                            ? dayjs(data?.passInfo?.passStartDateTime)
                                            : ''
                                    }
                                >
                                    <DatePicker locale={config.ru} format={config.dates.classic} />
                                </Form.Item>
                                <Form.Item
                                    label="Дата окончания действия"
                                    name={['passInfo', 'passExpirationDateTime']}
                                    initialValue={
                                        data?.passInfo?.passExpirationDateTime
                                            ? dayjs(data?.passInfo?.passExpirationDateTime).tz(tz)
                                            : ''
                                    }
                                >
                                    <DatePicker locale={config.ru} format={config.dates.classic} />
                                </Form.Item>
                            </InnerBlock>
                            {!isNew && (
                                <InnerBlock loading={loading} title="Фотографии пользователя">
                                    <div className="user-images-wrapper">
                                        {userImagesList.length > 0 &&
                                            userImagesList?.map((image) => (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    <Form.Item
                                                        key={image.id}
                                                        style={{ marginBottom: 5 }}
                                                    >
                                                        <Image width={200} src={image.link} />
                                                    </Form.Item>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {`Статус: 
                                                        ${
                                                            data.photos.filter(
                                                                (photo) => photo.photo === image.id
                                                            )[0]?.status.description
                                                        }`}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </InnerBlock>
                            )}
                        </Col>
                    </Row>
                </Form>
            )}
        </Block>
    );
};

export default UserInfo;
