import React, { FC } from 'react';
import { Pagination as AntPagination } from 'antd';
import * as Icons from '../Icons/Icons';

import './pagination.scss';

interface Props {
    total: number;
    pageSize: number;
    current: number;
    items: number;
    onChange?: (page: number) => void;
}

const Pagination: FC<Props> = (props: Props) => {
    const { total, pageSize, current, items, onChange } = props;
    const lastPage = Math.ceil(total / pageSize);
    const currentPage = current + 1;
    const prevCls = `pagination-prev ${
        currentPage === 1 ? 'ant-pagination-disabled disabled' : ''
    }`;
    const nextCls = `pagination-next ${
        currentPage === lastPage ? 'ant-pagination-disabled disabled' : ''
    }`;
    const totalItems =
        items === total
            ? `${items} из ${items}`
            : `${pageSize * currentPage - pageSize + 1}-${
                  pageSize * currentPage > total ? total : pageSize * currentPage
              } из ${total}`;

    const itemRender = (_: number, type: string, originalElement: React.ReactNode) => {
        if (type === 'prev') {
            return (
                <div className={prevCls}>
                    <div className="icon-prev">{Icons.prev}</div>
                    Назад
                </div>
            );
        }
        if (type === 'next') {
            return (
                <div className={nextCls}>
                    Вперед
                    <div className="icon-next">{Icons.next}</div>
                </div>
            );
        }
        return originalElement;
    };

    const change = (page: number) => {
        if (onChange) {
            onChange(page - 1);
        }
    };
    return (
        <div className="app-pagination">
            {items > 0 && <div className="pagination-total">{totalItems}</div>}
            {pageSize < total && (
                <AntPagination
                    size="small"
                    total={total}
                    pageSize={pageSize}
                    current={currentPage}
                    onChange={change}
                    locale={{
                        prev_page: 'Предыдущая страница',
                        next_page: 'Следующая страница',
                    }}
                    itemRender={itemRender}
                />
            )}
        </div>
    );
};

export default Pagination;
