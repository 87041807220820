/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Col, Row, Button, Select, message, InputNumber, Checkbox } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Block, InnerBlock } from 'components/layout';
import { IError } from 'models';
import OfferService from 'services/OffersServise';
import { offerStore } from 'store';

import './offers-add-form.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = 'Europe/Moscow';
dayjs.tz.setDefault(tz);

interface Props {
    title?: string;
    errorAddOffer?: IError;
}

const OffersAddForm = (props: Props) => {
    const { title, errorAddOffer } = props;

    const navigate = useNavigate();

    const [form] = useForm(null);
    const onValuesChange = (e, v) => {
        console.log(v);
        console.log(e);
    };

    const onFinishFailed = (error: any) => {
        console.log(error);
        // message.error(JSON.stringify(error));
    };

    const createOffer = (formData: any) => {
        const newData = {
            ...formData,
            price: +formData.price,
            mainConditions: [
                {
                    name: 'Часы в переговорках',
                    value: formData.mainConditionsMeetings,
                },
                {
                    name: 'Количество приглашений',
                    value: formData.mainConditionsInvite,
                },
            ],
            conditions: [
                ...formData.conditions,
                {
                    name: 'Скидка в кафе и магазине',
                    value: formData.conditionsCafe,
                },
                {
                    name: 'Скидка на бронирование',
                    value: formData.conditionsReserv,
                },
            ],
            displayedRole: formData.displayedRole,
        };

        delete newData.mainConditionsMeetings;
        delete newData.mainConditionsInvite;
        delete newData.conditionsCafe;
        delete newData.conditionsReserv;
        OfferService.offerPost(newData)
            .then(() => {
                message.success('Тариф успешно добавлен!');
                navigate('/offers');
            })
            .catch((error) => {
                offerStore.setAddError(error);
            });
    };

    useEffect(() => {
        if (errorAddOffer && errorAddOffer.detail) {
            message.error(errorAddOffer.detail);
        }
    }, [errorAddOffer]);

    return (
        <Block
            className="app-user-info"
            title={title}
            buttons={
                <Row style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                    <Button
                        onClick={() => {
                            form.resetFields();
                            navigate('/offers');
                        }}
                    >
                        Отмена
                    </Button>
                    <Button type="primary" onClick={() => form.submit()}>
                        Создать
                    </Button>
                </Row>
            }
        >
            <Form
                form={form}
                size="small"
                name="main"
                layout="vertical"
                wrapperCol={{ span: 20 }}
                initialValues={{
                    name: '',
                    duration: '',
                    price: '',
                    mainConditions: [],
                    corpOffer: false,
                    conditions: [{}],
                }}
                onFinishFailed={onFinishFailed}
                onFinish={createOffer}
                onValuesChange={onValuesChange}
                autoComplete="off"
            >
                <Row gutter={16} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Col
                        title="Основное"
                        className="gutter-row"
                        span={12}
                        style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                    >
                        <InnerBlock title="Основное">
                            <Form.Item
                                label="Название тарифа"
                                name="name"
                                rules={[
                                    {
                                        max: 30,
                                        message: 'Максимальная длинна 30 символов',
                                    },
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: /^[A-Za-zА-Яа-я0-9]*$/,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Период действия"
                                name="duration"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    options={[
                                        {
                                            value: 'year',
                                            label: '365',
                                        },
                                        {
                                            value: 'month',
                                            label: '31',
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Стоимость за период"
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber min={1} />
                            </Form.Item>
                            <Form.Item
                                label="Отображаемая роль"
                                name="displayedRole"
                                rules={[
                                    {
                                        max: 30,
                                        message: 'Максимальная длинна 30 символов',
                                    },
                                    {
                                        pattern: /^[a-zA-Zа-яА-Я0-9\s\W]+$/,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Корпоративный тариф"
                                name="corpOffer"
                                valuePropName="checked"
                            >
                                <Checkbox className="corpOfferContainer" />
                            </Form.Item>
                        </InnerBlock>
                        <InnerBlock title="Основные свойства">
                            <Form.Item
                                label="Часы в переговорках"
                                name={['mainConditionsMeetings']}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber min={0} max={9999} />
                            </Form.Item>
                            <Form.Item
                                label="Количество приглашений"
                                name={['mainConditionsInvite']}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber min={0} max={9999} />
                            </Form.Item>
                        </InnerBlock>
                        <InnerBlock title="Дополнительные свойства">
                            <Form.Item
                                label="Скидка в кафе и магазине"
                                name={['conditionsCafe']}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber min={0} max={999} />
                            </Form.Item>
                            <Form.Item
                                label="Скидка на бронирование"
                                name={['conditionsReserv']}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber min={0} max={999} />
                            </Form.Item>
                            <Form.List name="conditions">
                                {(fields, { add, remove }) => {
                                    const removeFormListGroup = () => {
                                        remove(fields.length - 1);
                                    };

                                    return (
                                        <>
                                            {fields.map(({ name, key }) => (
                                                <Fragment key={key}>
                                                    <Form.Item
                                                        label="Название"
                                                        name={[name, 'name']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Значение"
                                                        name={[name, 'value']}
                                                        rules={[
                                                            {
                                                                pattern: /^[a-zA-Zа-яА-ЯёЁ0-9]*$/,
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Fragment>
                                            ))}
                                            <Row
                                                style={{
                                                    display: 'flex',
                                                    gap: 10,
                                                }}
                                            >
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={add}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button
                                                        disabled={fields.length <= 1}
                                                        type="dashed"
                                                        onClick={removeFormListGroup}
                                                        block
                                                        icon={<MinusOutlined />}
                                                    />
                                                </Form.Item>
                                            </Row>
                                        </>
                                    );
                                }}
                            </Form.List>
                        </InnerBlock>
                    </Col>
                </Row>
            </Form>
        </Block>
    );
};

export default OffersAddForm;
