import React, { FC, ReactNode } from 'react';
import { Modal as AntModal } from 'antd';

import './modal.scss';

interface Props {
    className?: string;
    visible?: boolean;
    onCancel?: Function;
    title?: string;
    footer?: ReactNode[];
    children?: string | ReactNode;
}

const Modal: FC<Props> = (props: Props) => {
    const { visible, className, onCancel, title, footer, children } = props;
    const cls = `app-modal ${className || ''}`;
    const handlerCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };
    return (
        <AntModal
            centered
            title={title || ''}
            className={cls}
            open={visible}
            onCancel={handlerCancel}
            footer={footer || null}
            maskClosable={false}
        >
            {children}
        </AntModal>
    );
};

export default Modal;
