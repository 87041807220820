import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import { Button, Layout as AntLayout } from 'antd';
import config from 'config';
import { IRoute } from 'models';
import { authStore } from 'store';
import * as Icons from '../Icons/Icons';
import './sidebar.scss';

interface Props {
    navigation: IRoute[];
    page: string;
}

const SideBar: FC<Props> = (props: Props) => {
    const { navigation, page } = props;
    type IChapters = { [key: string]: React.ReactNode[] };

    const button = (item: IRoute) => {
        return (
            <Link
                className={`button ${item.key === page ? 'active' : ''}`}
                to={item.path}
                key={item.key}
            >
                <div className="button-icon">{item.icon}</div>
                {item.name}
            </Link>
        );
    };

    const chapter = (chapters: IChapters) => {
        const chapterNode: React.ReactNode[] = [];
        const keys = Object.keys(chapters);
        keys.forEach((key) => {
            chapterNode.push(
                <div key={key} className="chapter">
                    <div className="chapter-name">{/* key */}</div>
                    {chapters[key]}
                </div>
            );
        });

        return chapterNode;
    };

    const logout = () => {
        authStore.logout();
    };

    const buttons = () => {
        const chapters: IChapters = {};
        navigation.forEach((item: IRoute) => {
            if (!item.chapter) {
                return;
            }
            if (!chapters[item.chapter]) {
                chapters[item.chapter] = [button(item)];
            } else {
                chapters[item.chapter].push(button(item));
            }
        });
        return chapter(chapters);
    };
    return (
        <AntLayout.Sider className="app-sidebar" width={208}>
            <div className="sidebar-wrapper">
                <div className="sidebar-top">
                    <div className="sidebar-logo">{Icons.logo}</div>
                    <div className="sidebar-cube">{Icons.cube}</div>
                    <div className="sidebar-buttons">
                        <Scrollbars {...config.scrolls}>{buttons()}</Scrollbars>
                    </div>
                </div>
                <Button onClick={logout} type="ghost" className="profile-exit" icon={Icons.logout}>
                    Выйти
                </Button>
            </div>
        </AntLayout.Sider>
    );
};

export default SideBar;
