import { AxiosResponse } from 'axios';
import { IPageable, IPromocodes, IPromocodeFilter } from 'models';
import IPromocode from 'models/promocodes/IPromocode';
import api from '../http';

export default class PromocodesService {
    static async getPromocodes(
        pageable: IPageable,
        filter?: IPromocodeFilter
    ): Promise<AxiosResponse<IPromocodes>> {
        return api.get(`/promocode`, { params: { ...pageable, ...filter } });
    }

    static async deletePromocode(id: string): Promise<AxiosResponse<void>> {
        return api.delete(`/promocode/${id}`);
    }

    static async turnOffPromocode(id: string): Promise<AxiosResponse<void>> {
        return api.post(`/promocode/${id}/deactivate`);
    }

    static async turnOnPromocode(id: string): Promise<AxiosResponse<void>> {
        return api.post(`/promocode/${id}/activate`);
    }

    static async promocodesPost(data: any): Promise<AxiosResponse<void>> {
        return api.post(`/promocode`, data);
    }

    static async editComment(promoId: string, comment: string): Promise<AxiosResponse<IPromocode>> {
        return api.patch<IPromocode>(`/promocode?promoId=${promoId}&comment=${comment}`);
    }
}
