import React, { FC, useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import config from 'config';
import { IRoute, IPageable } from 'models';
import { userStore } from 'store';
import strings from 'tools';
import { EmptySearch, ErrorRequest, Header, Input, Layout } from 'components/layout';
import OrderService, { IOrderFilters } from 'services/OrderService';
import orderStore from 'store/OrderStore';
import { message } from 'antd';
import { OrdersTable } from 'components/order';
import './orders.scss';

interface Props {
    navigation: IRoute[];
}

const Orders: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const [search, setSearch] = React.useState<string | undefined>(undefined);
    const [pageable, setPageable] = useState<IPageable | undefined>();
    const [filter, setFilter] = useState<IOrderFilters>({});
    const inputRef = useRef<HTMLInputElement>(null);
    const { orders, isLoading } = orderStore;

    const fetchOrders = (page: number) => {
        OrderService.getOrders({ page, size: 10 }, filter)
            .then((resp) => {
                orderStore.setOrders(resp.data);
            })
            .catch((error) => {
                console.log(error);
                message.error('Ошибка получени списка заказов');
            });
    };

    useEffect(() => {
        fetchOrders(0);
    }, []);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const changePage = (pageNum: number) => {
        fetchOrders(pageNum);
        const newPageable = { ...pageable };
        newPageable.page = pageNum;
        setPageable(newPageable);
    };

    const changeSearch = (usefilter: string, text: string) => {
        setSearch(text);
        if (text === '') {
            setFilter({});
        } else {
            setFilter({ [usefilter]: text });
        }
    };

    const onSearch = () => {
        setPageable(undefined);
        fetchOrders(0);
    };

    return (
        <Layout page="orders" navigation={navigation} errorScreen>
            <Header
                title="Заказы"
                description={
                    orders
                        ? `Всего ${orders.totalElements} ${strings.ending(orders.totalElements, [
                              'заказ',
                              'заказа',
                              'заказов',
                          ])}`
                        : ''
                }
            />
            <div className="page-orders">
                <div className="orders-top">
                    <Input
                        ref={inputRef}
                        className="orders-search"
                        onChange={changeSearch}
                        value={search}
                        type="searchWithSelect"
                        selectOptions={config.search.orders}
                        onSearch={onSearch}
                    />
                </div>
                {orders?.totalElements && (orders?.totalElements > 0 || isLoading) && (
                    <OrdersTable
                        data={orders?.content}
                        page={pageable?.page || 0}
                        total={orders?.totalElements}
                        onChangePage={changePage}
                        onChangeFilter={undefined}
                        loading={false}
                        statusFilter={[]}
                    />
                )}
                {!orders?.totalElements && search && !isLoading && <EmptySearch />}
                {!orders && <ErrorRequest onClick={() => userStore.getUsers(pageable)} />}
            </div>
        </Layout>
    );
});

export default Orders;
