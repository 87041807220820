import React from 'react';

const scrolls = {
    renderTrackVertical: (props: any) => <div {...props} className="v-scroll-track" />,
    renderThumbVertical: (props: any) => <div {...props} className="v-scroll-thumb" />,
    renderTrackHorizontal: (props: any) => <div {...props} className="h-scroll-track" />,
    renderThumbHorizontal: (props: any) => <div {...props} className="h-scroll-thumb" />,
    hideTracksWhenNotNeeded: true,
};

const apiKeyFC = process.env.REACT_APP_API_KEY_FC || 'CC-Attribution-NonCommercial-NoDerivatives';

const config = {
    title: 'cyberdom',
    prefix: '_cbd',
    shortTitle: 'CBD',

    colors: {
        platinate_purple: '#65274e',
        cambridge_blue: '#8eb9a0',
        bone: '#e9e8dd',
        green_yellow: '#beff3d',
        vivit_violet: '#9900f6',
    },

    table: {
        pageSize: 10,
    },

    ru: {
        lang: {
            locale: 'ru_RU',
            placeholder: '',
            today: 'Сегодня',
            now: 'Сейчас',
            shortWeekDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            backToToday: 'Back to today',
            ok: 'OK',
            clear: 'Clear',
            month: 'Month',
            year: 'Year',
            timeSelect: 'Select time',
            dateSelect: 'Select date',
            monthSelect: 'Choose a month',
            yearSelect: 'Choose a year',
            decadeSelect: 'Choose a decade',
            yearFormat: 'YYYY',
            dateFormat: 'M/D/YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'M/D/YYYY HH:mm:ss',
            monthFormat: 'MMMM',
            monthBeforeYear: true,
            previousMonth: 'Previous month (PageUp)',
            nextMonth: 'Next month (PageDown)',
            previousYear: 'Last year (Control + left)',
            nextYear: 'Next year (Control + right)',
            previousDecade: 'Last decade',
            nextDecade: 'Next decade',
            previousCentury: 'Last century',
            nextCentury: 'Next century',
        },
        timePickerLocale: {
            placeholder: '',
        },
    },

    scrolls,
    apiKeyFC,

    dates: {
        full: 'YYYY.MM.DDTHH:mm:ss.000Z',
        z: 'YYYY-MM-DDTHH:mm:ss[Z]',
        def: 'DD.MM.YYYY HH:mm',
        defWithDayOfWeek: 'DD.MM.YYYY (dddd) HH:mm',
        classic: 'DD.MM.YYYY',
        calendar: 'YYYY-MM-DD',
        calendarUs: 'YYYY/MM/DD',
        nice: 'D MMMM YYYY',
        withoutPoint: 'D MMMM YYYY',
        time: 'HH:mm',
        shortNice: 'D MMMM',
    },

    roles: {
        getName: (role: string): string => {
            switch (role) {
                case 'PLATFORM_ADMIN':
                    return 'Администратор платформы';
                case 'PLATFORM_MANAGER':
                    return 'Менеджер платформы';
                default:
                    return '';
            }
        },
    },
    days: {
        getShortName: (day: string): string => {
            switch (day) {
                case 'MONDAY':
                    return 'Пн';
                case 'TUESDAY':
                    return 'Вт';
                case 'WEDNESDAY':
                    return 'Ср';
                case 'THURSDAY':
                    return 'Чт';
                case 'FRIDAY':
                    return 'Пт';
                case 'SATURDAY':
                    return 'Сб';
                case 'SUNDAY':
                    return 'Вс';
                default:
                    return '';
            }
        },
        getDayOfWeek: (day: number): { en: string; ru: string } => {
            switch (day) {
                case 1:
                    return { en: 'MONDAY', ru: 'Понедельник' };
                case 2:
                    return { en: 'TUESDAY', ru: 'Вторник' };
                case 3:
                    return { en: 'WEDNESDAY', ru: 'Среда' };
                case 4:
                    return { en: 'THURSDAY', ru: 'Четверг' };
                case 5:
                    return { en: 'FRIDAY', ru: 'Пятница' };
                case 6:
                    return { en: 'SATURDAY', ru: 'Суббота' };
                case 0:
                    return { en: 'SUNDAY', ru: 'Воскресенье' };
                default:
                    return { en: '', ru: '' };
            }
        },
        daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
    },
    ui: {
        stub: (count: number) =>
            new Array(count || 4).fill(0).map((z, i) => ({ id: i, title: '' })),
    },
    checkbox: {
        pointOptions: [],
    },
    select: {
        addUser: [
            { label: 'Администратор платформы', value: 'PLATFORM_ADMIN' },
            { label: 'Менеджер платформы', value: 'PLATFORM_MANAGER' },
        ],
        dayOfWeek: [
            { label: 'Понедельник', value: 'MONDAY' },
            { label: 'Вторник', value: 'TUESDAY' },
            { label: 'Среда', value: 'WEDNESDAY' },
            { label: 'Четверг', value: 'THURSDAY' },
            { label: 'Пятница', value: 'FRIDAY' },
            { label: 'Суббота', value: 'SATURDAY' },
            { label: 'Воскресенье', value: 'SUNDAY' },
        ],
    },
    radio: {
        deactivated: [
            { label: 'Пользователь больше не работает в компании', value: 'not_Work' },
            { label: 'Этот пользователь зарегистрирован под другой почтой', value: 'email' },
            { label: 'Другая причина', value: 'other' },
        ],
        activated: [
            { label: 'Пользователь вернулся в компанию', value: 'work' },
            { label: 'Деактивация пользователя была выполнена по ошибке', value: 'error' },
            { label: 'Другая причина', value: 'other' },
        ],
    },
    search: {
        users: [
            { label: 'Фамилия', value: 'lastName' },
            { label: 'Имя', value: 'firstName' },
            { label: 'Отчество', value: 'middleName' },
            { label: 'Email', value: 'email' },
            { label: '№ телефона', value: 'phone' },
        ],
        events: [
            { label: 'Название', value: 'name' },
            { label: 'Организатор', value: 'organizer' },
            { label: 'Начало', value: 'datetimeStartRange' },
            { label: 'Окончание', value: 'datetimeEndRange' },
        ],
        orders: [
            { label: 'Номер заказа', value: 'orderNumber' },
            { label: 'Номер билета', value: 'ticketNumber' },
            { label: 'Пользователь', value: 'userInformation' },
            { label: 'Мероприятие', value: 'eventName' },
        ],
        promocodes: [
            { label: 'Название', value: 'promocodeText' },
            { label: 'Пользователь', value: 'applyUser' },
        ],
        requests: [{ label: 'Название', value: 'companyName' }],
    },
};

export default Object.freeze({ ...config });
