import { message } from 'antd';
import { makeAutoObservable } from 'mobx';
import { IChangePassPost, IError, IProfileDetails, IRoles } from 'models';
import { ProfileService } from 'services';

interface Profile {
    isLoadingProfile: boolean;
    successProfile: boolean;
    errorProfile: IError | null;
    profile: IProfileDetails | null;
    getProfileDetails: Function;
    roles: IRoles[] | null;
}

class ProfileStore implements Profile {
    profile: IProfileDetails | null = null;

    successProfile = true;

    isLoadingProfile = true;

    errorProfile: IError | null = null;

    roles: IRoles[] | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setIsLoading(bool: boolean) {
        this.isLoadingProfile = bool;
    }

    setError(data: IError | null) {
        this.errorProfile = data;
    }

    setProfile(data: IProfileDetails | null) {
        this.profile = data;
    }

    setSuccess(bool: boolean) {
        this.successProfile = bool;
    }

    async getProfileDetails(id: string) {
        this.setIsLoading(true);
        try {
            this.setSuccess(false);
            const response = await ProfileService.getProfileDetails(id);
            this.setProfile(response.data);
            this.setSuccess(true);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
            this.setSuccess(false);
        }
        this.setIsLoading(false);
    }

    async changePassword(body: IChangePassPost) {
        this.setIsLoading(true);
        try {
            this.setSuccess(false);
            await ProfileService.postPassword(body);
            this.setSuccess(true);
            message.success('Пароль успешно изменен');
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
            this.setSuccess(false);
        }
        this.setIsLoading(false);
    }
}

const profileStore = new ProfileStore();

export default profileStore;
