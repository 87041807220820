/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { Table } from 'components/layout';
import { ColumnsType } from 'antd/lib/table';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { IOffer } from 'models';

import './offers-table.scss';

const { Item } = Menu;

interface Props {
    data?: IOffer[] | null;
    loading: boolean;
    onTurnOffOffer: (id: string) => void;
    onTurnOnOffer: (id: string) => void;
}

const OffersTable: FC<Props> = (props: Props) => {
    const { data, loading, onTurnOffOffer, onTurnOnOffer } = props;
    const columns: ColumnsType<IOffer> = [
        {
            title: 'Идентификатор тарифа',
            className: 'event-table-name',
            dataIndex: 'id',
            width: '15%',
        },
        {
            title: 'Название тарифа',
            dataIndex: 'name',
            width: '18%',
        },
        {
            title: 'Период действия',
            className: 'event-table-name',
            dataIndex: 'duration',
            width: '10%',
        },
        {
            title: 'Стоимость за период',
            className: 'event-table-name',
            dataIndex: 'price',
            width: '10%',
        },
        {
            title: 'Свойства тарифа',
            dataIndex: 'mainConditions',
            render: (_: string, row: IOffer) => {
                return (
                    <div className="mainConditionsWrapper">
                        {row.mainConditions.map((mainCondition) => (
                            <div className="mainCondition">
                                <div>{mainCondition.name}:</div>
                                <div>{mainCondition.value}</div>
                            </div>
                        ))}
                    </div>
                );
            },
            width: '15%',
        },
        {
            title: 'Статус',
            className: 'event-table-name',
            dataIndex: 'available',
            render: (_: string, row: IOffer) => {
                return (
                    <div key={`${row.id}_available`}>
                        {row.available ? (
                            <span className="available">Активен</span>
                        ) : (
                            <span className="nonAvailable">Неактивен</span>
                        )}
                    </div>
                );
            },
            width: '10%',
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            render: (_: string, row: IOffer) => (
                <Dropdown
                    className="dropdown"
                    trigger={['click']}
                    overlay={
                        <Menu>
                            {row.available && (
                                <Item onClick={() => onTurnOffOffer(row.id)}>Выключить</Item>
                            )}
                            {!row.available && (
                                <Item onClick={() => onTurnOnOffer(row.id)}>Включить</Item>
                            )}
                        </Menu>
                    }
                >
                    <Space>
                        Действия
                        <DownOutlined />
                    </Space>
                </Dropdown>
            ),
            width: '8%',
        },
    ];
    return (
        <Table
            className="orders-table"
            data={data}
            columns={columns}
            loading={loading}
            page={0}
            onChangePage={() => console.log()}
        />
    );
};

export default OffersTable;
