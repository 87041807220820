import { AxiosResponse } from 'axios';
import { IPhoto } from 'models';
import qs from 'qs';
import api from '../http';

export default class FileService {
    static async uploadPhoto(file: FormData, config): Promise<AxiosResponse<IPhoto>> {
        return api.post('../s3/files/', file, config);
    }

    static async getFileData(id: string): Promise<AxiosResponse<IPhoto>> {
        return api.get(`../s3/files/${id}/`);
    }

    static async getFilesData(filesIds: { filesIds: string[] }): Promise<AxiosResponse<IPhoto[]>> {
        return api.get('../s3/files/', {
            params: filesIds,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });
    }

    static async getFileInfo(url: string): Promise<Response> {
        return fetch(`${url}`, { mode: 'no-cors' });
    }
}
