import { makeAutoObservable } from 'mobx';
import { IOrders } from '../models/IOrders';

class OrderStore {
    isLoading = false;
    orders: IOrders | null = null;

    setIsLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setOrders(data: IOrders) {
        this.orders = data;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const orderStore = new OrderStore();

export default orderStore;
