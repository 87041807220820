import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { IRoute } from 'models';
import strings from 'tools';
import { Button, EmptySearch, ErrorRequest, Header, Icons, Layout } from 'components/layout';
import offerStore from 'store/OffersStore';
import { OffersTable } from 'components/offer';

import './offers.scss';

interface Props {
    navigation: IRoute[];
}

const Offers: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const [isAddOffer, setIsAddOffer] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const { offers, isLoading } = offerStore;

    const navigate = useNavigate();

    useEffect(() => {
        offerStore.getOffers();
    }, []);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        if (isAddOffer) {
            navigate('/offers/new');
        }
    }, [isAddOffer]);

    const onTurnOffOffer = (id: string) => {
        offerStore.turnOffOffer(id);
    };

    const onTurnOnOffer = (id: string) => {
        offerStore.turnOnOffer(id);
    };

    return (
        <Layout page="offers" navigation={navigation} errorScreen>
            <Header
                title="Тарифы"
                description={
                    offers
                        ? `Всего ${offers.length} ${strings.ending(offers.length, [
                              'тариф',
                              'тарифа',
                              'тарифов',
                          ])}`
                        : ''
                }
            />
            <div className="page-offers">
                <div className="offers-top">
                    <Button onClick={() => setIsAddOffer(true)} type="primary">
                        {Icons.plus()} Создать
                    </Button>
                </div>
                {offers?.length && (offers?.length > 0 || isLoading) && (
                    <OffersTable
                        data={offers}
                        loading={isLoading}
                        onTurnOffOffer={onTurnOffOffer}
                        onTurnOnOffer={onTurnOnOffer}
                    />
                )}
                {!offers?.length && !isLoading && <EmptySearch />}
                {!offers && <ErrorRequest onClick={() => offerStore.getOffers()} />}
            </div>
        </Layout>
    );
});

export default Offers;
