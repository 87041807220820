import { AxiosResponse } from 'axios';
import { ILogin, ILoginRequest, IRefreshRequest } from 'models';
import ILogout from 'models/login/ILogout';
import IOTP from 'models/login/IOTP';
import IOTPRequest from 'models/login/IOTPRequest';
import api from '../http';

export default class AuthService {
    static async login(body: ILoginRequest): Promise<AxiosResponse<ILogin>> {
        return api.post('/auth/login/', body);
    }

    static async otp(body: IOTPRequest): Promise<AxiosResponse<IOTP>> {
        return api.post('/auth/otp/', body);
    }

    static async refresh(body: IRefreshRequest): Promise<AxiosResponse<ILogin>> {
        return api.post('/auth/refresh/', body);
    }

    static async logout(body: ILogout): Promise<AxiosResponse<void>> {
        return api.post('/auth/logout/', body);
    }
}
