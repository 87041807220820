import React, { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout as AntLayout } from 'antd';
import TextButton from '../TextButton/TextButton';
import Skeleton from '../Skeleton/Skeleton';

import './header.scss';

interface Props {
    title?: string | ReactNode;
    description?: string | ReactNode;
    routes?: { path: string; name: string }[];
    loading?: boolean;
    button?: ReactNode;
    className?: string;
}

const Header: FC<Props> = (props: Props) => {
    const { title, description, button, routes, loading, className } = props;
    const navigate = useNavigate();

    const renderRoutes = () => {
        const nodeRoutes: ReactNode[] = [];
        routes?.forEach((item) => {
            nodeRoutes.push(
                <TextButton
                    key={item.path}
                    className="header-back"
                    onClick={() => navigate(item.path)}
                >
                    {`${item.name} /`}
                </TextButton>
            );
        });
        return nodeRoutes;
    };

    return (
        <AntLayout.Header className={className ? `page-header ${className}` : 'page-header'}>
            <div className="header-text-part">
                {routes && !loading && <div className="header-routes">{renderRoutes()}</div>}
                <div className="header-text">
                    {loading ? (
                        <Skeleton active />
                    ) : (
                        <>
                            <div className="header-title">{title || ''}</div>
                            <div className="header-total">{description || ''}</div>
                        </>
                    )}
                </div>
            </div>
            {button && button}
        </AntLayout.Header>
    );
};

export default Header;
