import { AxiosResponse } from 'axios';
import { IPageable, IRequests, IReviewReq } from 'models';
import api from '../http';

export default class RequestService {
    static async getRequest(
        pageable: IPageable,
        filter?: { name: string }
    ): Promise<AxiosResponse<IRequests>> {
        return api.get(`/business-request`, { params: { ...pageable, ...filter } });
    }

    static async reviewRequest(
        businessRequestId: string,
        data: IReviewReq
    ): Promise<AxiosResponse<void>> {
        return api.put(`/business-request/${businessRequestId}`, data);
    }
}
