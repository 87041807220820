import React, { ChangeEvent } from 'react';
import { Button, Form, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import './promocode-modal.scss';

export type FieldType = {
    comment?: string;
};

interface Props {
    isModalOpen: boolean;
    isCommentLoading: boolean;
    comment: string;
    onFinish: (values: FieldType) => void;
    onCloseModal: () => void;
    onChangeComment: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const PromocodeModal = (props: Props) => {
    const { isModalOpen, isCommentLoading, comment, onFinish, onCloseModal, onChangeComment } =
        props;

    return (
        <Modal
            centered
            title="Редактировать"
            open={isModalOpen}
            onCancel={onCloseModal}
            footer={null}
        >
            <Form
                name="edit-comment"
                initialValues={{ comment }}
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item<FieldType> label="Комментарий:" name="comment" initialValue={comment}>
                    <TextArea
                        maxLength={1000}
                        placeholder="Комментарий"
                        value={comment}
                        onChange={onChangeComment}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" loading={isCommentLoading} htmlType="submit">
                        Сохранить
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PromocodeModal;
